import { PartnerPlayersTable } from '@/modules/partner-program';
import Mobile from '@/shared/components/responsive/mobile';
import Desktop from '@/shared/components/responsive/desktop';
import PartnerPlayersList from '@/modules/partner-program/ui/player/partner-players-list/partner-players-list';
import { useGetPartnerPlayersQuery } from '@/modules/partner-program/api/partnerApi';
import { useState } from 'react';
import { GetPartnerPlayers } from '@/modules/partner-program/model/types';
import LoaderCenter from '@/shared/components/loader-center/loader-center';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './partner-users-page.scss';
import Pagination from '@/shared/components/pagination/pagination';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/shared/hooks';
import { selectPPSelectedCurrency } from '@/modules/partner-program/model/selectors';
import { usePPFilter } from '@/modules/partner-program/hooks/usePPFilter';
import { PPFilter } from '@/modules/partner-program';

const b = bemCn('partner-users-page');

const initParams: Partial<GetPartnerPlayers> = {
  page: 1,
  pageSize: 20,
};

const PartnerUsersPage = () => {
  const { t } = useTranslation();
  const currency = useAppSelector(selectPPSelectedCurrency);
  const [params, setParams] = useState<Partial<GetPartnerPlayers>>(initParams);

  const {
    filterParams,
    handleFilterChange,
  } = usePPFilter({});

  const {
    data,
    isLoading: isPlayersLoading,
  } = useGetPartnerPlayersQuery({ ...params, ...filterParams, currency }, { skip: !currency });

  const handleChangeParams = (newParams: Partial<GetPartnerPlayers>) => {
    setParams({ ...params, ...newParams });
  };

  const players = data?.items ?? [];
  const itemsCount = data?.count ?? 0;
  const isEmpty = !isPlayersLoading && itemsCount === 0;

  return (
    <div className={b()}>
      <div className={b('head')}>
        <h1 className={b('title')}>
          {/* Мои пользователи */}
          {t('partner-program.users', 'Пользователи')}
        </h1>

        <PPFilter
          className={b()}
          handleFilterChange={handleFilterChange}
        />
      </div>
      {isEmpty && <p>{t('empty', 'Список пуст')}</p>}
      <Mobile>
        {!isEmpty && <PartnerPlayersList players={players} />}
      </Mobile>
      <Desktop>
        {!isEmpty && <PartnerPlayersTable players={players} />}
      </Desktop>
      {isPlayersLoading && <LoaderCenter />}
      <Pagination
        className={b('pagination')}
        page={params.page}
        pageSize={params.pageSize}
        totalItems={itemsCount}
        onChange={(page) => handleChangeParams({ page })}
      />
    </div>
  );
};

export { PartnerUsersPage };
