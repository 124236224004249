import { baseApi } from '@/shared/store/api';
import { adapteCoupon, adapteCoupons } from '../model/adapter';
import type { Coupon, Coupons, GetCouponsParams } from '../model/types';


const couponsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCoupons: build.query<Coupons, GetCouponsParams | void>({
      query: (params) => ({
        url: 'v1/user/coupons',
        params: {
          type: params?.type,
          page: `${params?.page}`,
          'page_size': `${params?.pageSize}`,
          ascending: params?.ascending,
        }
      }),
      transformResponse: adapteCoupons,
    }),

    getCoupon: build.query<Coupon, string>({
      query: (couponId) => `v1/user/coupons/${couponId}`,
      transformResponse: adapteCoupon
    }),
  })
});

export const {
  useGetCouponsQuery,
  useGetCouponQuery,
} = couponsApi;
