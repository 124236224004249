import { bemCn } from '@shared/utils/helpers/bem-cn';
import { getAmountString } from '@shared/utils/helpers/common';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import Button from '@shared/components/button/button';
import { CheckboxControlled } from '@shared/components/checkbox/checkbox';
import IconSvg from '@shared/components/icon-svg/icon-svg';
import Loader from '@shared/components/loader/loader';
import { TextFieldControlled } from '@shared/components/text-field/text-field';
import { useBasket } from './use-basket';
import BetSuccessModal from '../bet-success-modal/bet-success-modal';
import React, { useEffect, useRef } from 'react';
import './basket.scss';
import { Trans, useTranslation } from 'react-i18next';

type BasketProps = {
  className?: string;
  onClose?: () => void;
  theme?: 'light' | 'dark';
}

const b = bemCn('basket');

const Basket = ({ className, onClose, theme }: BasketProps) => {
  const { t } = useTranslation();
  const {
    isBasketLoading,
    isDeleting,
    handleDeleteClickBet,
    prizeAmount,
    items,
    currency,
    // minBet,
    maxBet,
    totalRate,
    isEmpty,
    control,
    isPlaceBetLoading,
    handleSubmit,
    placeError,
    betPlaceSuccess,
    handleBetSuccessModalClose,
  } = useBasket();

  // Закрывает корзину, после удаления последнего события
  const prevIsEmpty = useRef(isEmpty);

  useEffect(() => {
    if (!prevIsEmpty.current && isEmpty) {
      onClose?.();
    }

    prevIsEmpty.current = isEmpty;
  }, [isEmpty]);

  return (
    <>
      <form className={b({ theme }, className,)} onSubmit={handleSubmit}>
        <div className={b('head')}>
          <h2 className={b('title')}>{t('cart.title', 'Корзина')}</h2>
          <Button className={b('history-btn')}
            variant='text'
            rightIcon='time-machine'
            to='/bets-history'
            onClick={onClose}
          >
            {t('cart.bet-history', 'История ставок')}
          </Button>
        </div>
        {/* <div className={b('controlls')}>
        <Button className={b('controlls-btn')} variant='primary'>
          Ординар
        </Button>
        <Button className={b('controlls-btn')} variant='secondary'>
          Экспресс
        </Button>
      </div> */}

        <div className={b('list', { empty: isEmpty, loading: isDeleting })}>
          {isBasketLoading || isDeleting && <Loader className={b('loader')} />}
          {isEmpty && !isBasketLoading && (
            <p className={b('empty')}>
              <Trans i18nKey="basket.empty">Корзина пуста</Trans>
            </p>
          )}
          {!isEmpty && !isBasketLoading && (
            <>
              {items.map((item) => (
                <div className={b('item', { blocked: item.isBlocked })} key={item.id}>
                  <div className={b('item-wrapper')}>
                    <ButtonIcon className={b('item-delete-btn')}
                      variant='text'
                      icon='trash'
                      onClick={() => handleDeleteClickBet(item.id, item.dataType)}
                      disabled={isDeleting || isPlaceBetLoading}
                    />
                    <div className={b('item-top')}>
                      <span className={b('rate', b('item-rate'))}>{item.rate}</span>
                      <p className={b('item-outcome')}>
                        {item.groupName} • {item.outcomeName}
                      </p>
                    </div>
                    <div className={b('item-teams')}>
                      <p className={b('item-team')}>
                        {item.team1Name}
                      </p>
                      <p className={b('item-team')}>
                        {item.team2Name}
                      </p>
                    </div>
                    <p className={b('item-meta')}>
                      {item.sportName} • {item.tournamentName}
                    </p>
                  </div>
                  {item.isBlocked && (
                    <IconSvg className={b('item-lock-icon')} name='lock' />
                  )}
                </div>
              ))}
              <p className={b('total-rate')}>
                <span>{t('cart.total-coef', 'Итоговый коэффициент')}.</span>
                <span className={b('rate')}>{totalRate.toFixed(2)}</span>
              </p>
            </>
          )}

        </div>
        <CheckboxControlled className={b('agree')}
          control={control}
          name='bonusBalance'
          label={t('cart.bonus-balance')}
          inverted={theme === 'dark'}
          disabled={isEmpty || isPlaceBetLoading}
        />
        <div className={b('amoun-info')}>
          <p className={b('max-amount')}>
            <span>{t('cart.max-bet')}</span>
            <span className={b('max-amount-value')}>
              {getAmountString(maxBet, currency)}
            </span>
          </p>
          <p className={b('prize-amount')}>
            <span>{t('cart.possible-prize')}</span>
            <span className={b('prize-amount-value')}>
              +{getAmountString(prizeAmount.toFixed(2), currency)}
            </span>
          </p>
        </div>
        <TextFieldControlled className={b('amount-field')}
          control={control}
          id="amount"
          type='number'
          name='amount'
          placeholder={t('cart.amount')}
          inverted={theme === 'dark'}
          disabled={isEmpty || isPlaceBetLoading}
          defaultValue={0}
        />
        <CheckboxControlled className={b('agree')}
          control={control}
          name='agree'
          label={t('cart.coef-change-agree')}
          inverted={theme === 'dark'}
          disabled={isEmpty || isPlaceBetLoading}
        />
        {placeError?.root && (
          <div className={b('error-block')}>
            <IconSvg className={b('error-block-icon')} name='warning-flat' />
            {placeError?.root}
          </div>
        )}
        <Button
          className={b('submit')}
          type="submit"
          disabled={isEmpty || isPlaceBetLoading}
          loading={isPlaceBetLoading}
          loadingType='dots'
        >
          {t('cart.make-bet', 'Сделать ставку')}
        </Button>
      </form>

      <BetSuccessModal
        isOpen={betPlaceSuccess}
        onClose={() => handleBetSuccessModalClose(onClose)}
      />
    </>

  );
};

export default React.memo(Basket);
