import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatchedActions } from '@/shared/hooks';
import AppModal from '@shared/components/app-modal/app-modal';
import { useInitPasswordResetMutation } from '../../model/auth-api';
import PasswordResetForm from '../password-reset-form/password-reset-form';

type PasswordResetModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

const PasswordResetModal = ({ isOpen, onClose }: PasswordResetModalProps) => {
  const { t } = useTranslation();

  const [resetPassword, { isLoading, isSuccess, isError }] = useInitPasswordResetMutation();

  const { changeAuthModal } = useDispatchedActions();

  useEffect(() => {
    if (isSuccess) {
      changeAuthModal('email-confirm');
    } else if (isError) {
      changeAuthModal('password-reset-error');
    }
  }, [isSuccess, isError]);

  const handleFormSubmit = async (data: { email: string }) => {
    await resetPassword(data.email);
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('reset-pass.title', 'Восстановление пароля')}
      description={t('reset-pass.email-description', 'Введите адрес электронной почты  вашего аккаунта, мы отправим вам ссылку для восстановления пароля')}
    >

      <PasswordResetForm
        onFormSubmit={handleFormSubmit}
        loading={isLoading}
      />
    </AppModal>
  );
};

export default PasswordResetModal;
