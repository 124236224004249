import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useOnClickOutside, useToggle } from 'usehooks-ts';
import IconSvg from '../icon-svg/icon-svg';
import withController from '../../hocs/with-controller';
import './select.scss';

export type Option = {
  label?: string;
  value: string;
}

const b = bemCn('select');

const ToggleIcon = () => (
  <IconSvg className={b('toggle-icon')}
    name="chevron-down"
    width={10}
    height={10}
  />
);

type ClearBtnProps = {
  onClick: () => void;
}

const ClearBtn = ({ onClick }: ClearBtnProps) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <button className={b('clear-btn')} type='button' onClick={handleClick}>
      <IconSvg className={b('clear-icon')} name="close" width={10} height={10} />
    </button>
  );
};

export type SelectProps = {
  className?: string;
  classNameField?: string;
  options: Option[];
  placeholder?: string;
  renderOption?: (option: Option) => JSX.Element;
  status?: 'error' | 'success';
  error?: string;
  name?: string;
  value?: string | null;
  onChange?: (value: string | null) => void;
  onBlur?: () => void;
  disabled?: boolean;
  clearable?: boolean;
  inverted?: boolean;
  position?: 'top' | 'bottom';
}


const Select = ({
  className,
  classNameField,
  options,
  placeholder,
  renderOption,
  status,
  error,
  onChange,
  onBlur,
  value,
  clearable,
  inverted,
  position = 'bottom',
}: SelectProps) => {
  const [opened, toggleOpened, setOpened] = useToggle(false);
  const [selectedOption, setSelectedOprion] = useState<Option | null>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleOptionSelect = (option: Option) => {
    setSelectedOprion(option);
    setOpened(false);
    onBlur?.();
  };

  const handleClearClick = () => {
    setSelectedOprion(null);
    onBlur?.();
  };

  const isEmptyList = options.length === 0;
  const isError = status === 'error' && error;

  useEffect(() => {
    onChange?.(selectedOption?.value ?? null);
  }, [onChange, selectedOption]);

  useEffect(() => {
    if (value) {
      const newOpt = options.find((option) => option.value === value);
      if (newOpt) {
        setSelectedOprion(newOpt);
      }
    }
  }, [value]);

  useOnClickOutside(selectRef, () => setOpened(false));

  return (
    <div className={b({ opened, status, inverted }, className)} ref={selectRef}>
      <div className={b('selected', { placeholder: !selectedOption?.value }, classNameField)} onClick={toggleOpened}>
        {selectedOption
          ? renderOption?.(selectedOption) ?? selectedOption.label ?? selectedOption.value
          : placeholder ?? 'Select option'}
        <ToggleIcon />
        {clearable && selectedOption && <ClearBtn onClick={handleClearClick} />}
      </div>
      {isError && <span className={b('error')}>{error}</span>}
      {!isEmptyList && (
        <ul className={b('options', { position })}>
          {options.map((option) => (
            <li className={b('option')}
              key={option.value}
              onClick={() => handleOptionSelect(option)}
            >
              {renderOption?.(option) ?? option.label ?? option.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const SelectControlled = withController(Select);

export default Select;
