import { bemCn } from '@shared/utils/helpers/bem-cn';
import { IEventItem } from '../../model/line';
import EventCard from '../event-card/event-card';
import { memo, useMemo } from 'react';
import './events-list.scss';

const MemoizedEvent = memo(EventCard);

const b = bemCn('events-list');

type EventsListProps = {
  className?: string;
  events: IEventItem[];
}

const EventsList = ({ className, events }: EventsListProps) => {
  const memoizedEvents = useMemo(() => events.map((event) => (
    <MemoizedEvent className={b('item')}
      key={event.id}
      event={event}
    />
  )), [events]);

  return (
    <div className={b(null, className)}>
      {memoizedEvents}
    </div>
  );
};

export default EventsList;
