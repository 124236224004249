import { bemCn } from '@shared/utils/helpers/bem-cn';
import preview from './stream-preview.png';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './event-stream.scss';


type EventStreamProps = {
  className?: string;
  date: string;
  sportName: string;
  torunamentName: string;
}

const b = bemCn('event-stream');

const EventStream = ({
  className,
  date,
  sportName,
  torunamentName,
}: EventStreamProps) => (
  <div className={b(null, className)}>
    <div className={b('wrapper')}>
      <div className={b('info')}>
        <p className={b('tornmanet')}>
          {sportName} {torunamentName}
        </p>
        <p className={b('date')}>
          {dayjsCustom(date).format(('DD MMMM, HH:mm'))}
        </p>
      </div>
      <img className={b('preview')} src={preview} />
    </div>
  </div>
);

export default EventStream;
