import { useInitWithdrawalMutation } from '@/modules/user/store/user-api';
import { handleWithdrawalError } from '../utils/halpers';

export const useWithdrawal = () => {
  const [initWithdrawal, { isLoading, error }] = useInitWithdrawalMutation();
  const withdrawalError = handleWithdrawalError(error);

  return {
    initWithdrawal,
    isLoading,
    error: withdrawalError,
  };
};
