import { useAppSelector } from '@shared/hooks';
import { useGetBetapiSportsQuery, useGetBetapiCountriesQuery, useGetBetapiTournamentsQuery } from './betapi';
import { getEventsFilterList } from './selectors';
import { DataType } from './types';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

type HOCProps = {
  dataType: DataType;
}

const withEventsFilterItems = <P extends object>(Component: ComponentType<P>) => {
  type ComponentProps = HOCProps & Omit<P, 'items' | 'loading'>;
  const WithEventsFilterImtes = (props: ComponentProps) => {
    const { dataType } = props;
    const { i18n: { resolvedLanguage: dataLang } } = useTranslation();
    const { isLoading: isSportsLoading } = useGetBetapiSportsQuery({ dataType, dataLang });
    const { isLoading: isCountryLoading } = useGetBetapiCountriesQuery({ dataType, dataLang });
    const { isLoading: isTournamentFetching } = useGetBetapiTournamentsQuery({ dataType, dataLang });
    const items = useAppSelector(getEventsFilterList({ dataType, dataLang }));
    const isLoading = isSportsLoading || isCountryLoading || isTournamentFetching;

    return (
      <Component
        {...props as P}
        items={items}
        loading={isLoading}
      />
    );
  };

  return WithEventsFilterImtes;
};

export { withEventsFilterItems };
