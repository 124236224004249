import {
  TopupMethodsModal,
  WithdrawalMethodsModal,
  WalletManageModal,
} from '@/modules/user-wallet';
import { PromocodesModal, PromocodeActivateModal } from '@/modules/promocodes';
import { BasketModal, BetSuccessModal, BetErrorModal } from '@/modules/basket';
import { useAppSelector, useDispatchedActions } from '@shared/hooks';
import { getAppModal } from '@shared/store/app/selectors';
import {
  getAuthModal,
  LoginModal,
  RegisterModal,
  EmailConfirmModal,
  RegisterSuccessModal,
  VerifyErrorModal,
  PasswordChangeModal,
  PasswordChangeSuccessModal,
  PasswordResetModal,
  PasswordResetSuccessModal,
  PasswordResetErrorModal,
} from '@/modules/auth';
import UserSettingsModal from '@/widgets/settings/components/user-settings-modal/user-settings-modal';
import { TransactionsModal } from '@/modules/user';
import WithdrawalsModal from '@/pages/withdrawals-page/withdrawals-modal/withdrawals-modal';

const AppModals = () => {
  const authModal = useAppSelector(getAuthModal);
  const appModal = useAppSelector(getAppModal);
  const { closeAuthModal, closeAppModal } = useDispatchedActions();

  return (
    <>
      <LoginModal
        isOpen={authModal === 'login'}
        onClose={() => closeAuthModal()}
      />
      <RegisterModal
        isOpen={authModal === 'registration'}
        onClose={() => closeAuthModal()}
      />
      <TopupMethodsModal
        isOpen={appModal === 'topup'}
        onClose={() => closeAppModal()}
      />
      <WithdrawalMethodsModal
        isOpen={appModal === 'withdrawal'}
        onClose={() => closeAppModal()}
      />
      <BasketModal
        isOpen={appModal === 'basket'}
        onClose={() => closeAppModal()}
      />
      <PromocodesModal
        isOpen={appModal === 'promocodes'}
        onClose={() => closeAppModal()}
      />
      <PromocodeActivateModal
        isOpen={appModal === 'promocode-activate'}
        onClose={() => closeAppModal()}
      />

      <BetSuccessModal
        isOpen={false}
        onClose={() => { }}
      />
      <BetErrorModal
        isOpen={false}
        onClose={() => { }}
      />

      <EmailConfirmModal
        isOpen={authModal === 'email-confirm'}
        onClose={() => closeAuthModal()}
      />
      <RegisterSuccessModal
        isOpen={authModal === 'registration-success'}
        onClose={() => closeAuthModal()}
      />
      <VerifyErrorModal
        isOpen={authModal === 'verify-error'}
        onClose={() => closeAuthModal()}
      />
      <PasswordChangeModal
        isOpen={authModal === 'password-change'}
        onClose={() => closeAuthModal()}
      />
      <PasswordChangeSuccessModal
        isOpen={authModal === 'password-change-success'}
        onClose={() => closeAuthModal()}
      />
      <PasswordResetModal
        isOpen={authModal === 'password-reset'}
        onClose={() => closeAuthModal()}
      />
      <PasswordResetSuccessModal
        isOpen={authModal === 'password-reset-success'}
        onClose={() => closeAuthModal()}
      />
      <PasswordResetErrorModal
        isOpen={authModal === 'password-reset-error'}
        onClose={() => closeAuthModal()}
      />
      <WalletManageModal
        isOpen={appModal === 'wallet-manage'}
        onClose={() => closeAppModal()}
      />

      <UserSettingsModal
        isOpen={appModal === 'user-settings'}
        onClose={() => closeAppModal()}
      />
      <TransactionsModal
        isOpen={appModal === 'transactions'}
        onClose={() => closeAppModal()}
      />
      <WithdrawalsModal
        isOpen={appModal === 'withdrawals-list'}
        onClose={() => closeAppModal()}
      />
    </>
  );
};

export default AppModals;
