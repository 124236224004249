import { bemCn } from '@shared/utils/helpers/bem-cn';
import { NavLink } from 'react-router-dom';

import { getBasketCount, useGetBasketQuery } from '@/modules/basket';
import { useDispatchedActions, useAppSelector } from '@shared/hooks';
import IconSvg, { AvailableIcon } from '@/shared/components/icon-svg/icon-svg';
import { getIsAuth } from '@/modules/auth';
import { useEffect, useRef } from 'react';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { Trans, useTranslation } from 'react-i18next';
import { scrollToTop } from '@/shared/utils/helpers/common';
import './nav-bar.scss';

type NavBarItem = {
  id: string;
  title: string;
  link: string;
  counter?: number;
  icon: AvailableIcon;
  onClick?: (evt: React.MouseEvent) => void;
}

const items: NavBarItem[] = [
  {
    id: 'home',
    title: 'Главная',
    link: '/',
    icon: 'home',
  },
  {
    id: 'live',
    title: 'Live',
    link: '/live',
    icon: 'live',
  },
  {
    id: 'cart',
    title: 'Корзина',
    link: '',
    icon: 'cart',
  },
  {
    id: 'line',
    title: 'Линия',
    link: '/line',
    icon: 'football',
  },
  {
    id: 'casino',
    title: 'Казино',
    link: '/games',
    icon: 'coin',
  },
];

type NavBarProps = {
  className?: string;
}

const b = bemCn('nav-bar');

const NavBar = ({ className }: NavBarProps) => {
  const { isMobile } = useResponsive();
  const navRef = useRef<HTMLDivElement>(null);
  const { changeAppModal, changeAuthModal } = useDispatchedActions();
  const isAuth = useAppSelector(getIsAuth);
  useGetBasketQuery(undefined, { skip: !isAuth });
  const basketCount = useAppSelector(getBasketCount);
  const { t } = useTranslation('', { keyPrefix: 'main-nav' });

  const handleItemClick = (itemId: string, e: React.MouseEvent) => {
    if (itemId === 'cart') {
      e.preventDefault();
      if (isAuth) {
        changeAppModal('basket');
      } else {
        changeAuthModal('login');
      }
    } else {
      scrollToTop(true);
    }
  };

  useEffect(() => {
    const footerElement = document.getElementById('page-footer');
    if (footerElement && navRef.current && isMobile) {
      footerElement.style.paddingBottom = `${navRef.current.offsetHeight}px`;
    }

    return () => {
      if (footerElement) {
        footerElement.style.paddingBottom = '';
      }
    };
  }, [navRef, isMobile]);

  return (
    <div className={b(null, className)} ref={navRef}>
      <ul className={b('list')}>
        {items.map(({ id, title, link, icon }) => (
          <li className={b('item')} key={id}>
            <NavLink
              className={({ isActive }) => b('link', { active: isActive && id !== 'cart' })}
              to={link}
              onClick={(evt) => handleItemClick(id, evt)}
            >
              <span className={b('icon-wrapper')}>
                <IconSvg className={b('icon')} name={icon} />
              </span>
              <span className={b('label')}>
                <Trans t={t} i18nKey={id}>{title}</Trans>
              </span>
              {id === 'cart' && !!basketCount ? (
                <span className={b('counter')}>{basketCount}</span>
              ) : null}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavBar;
