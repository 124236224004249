import { useAppSelector, useDispatchedActions } from '@/shared/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIsAuth } from '../model/selectors';
import { saveRefCode } from '../utils/ref-code';
import { useEffect } from 'react';
import { useClickRefCodeQuery } from '../model/auth-api';

export const useRefCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = useAppSelector(getIsAuth);
  const { changeAuthModal } = useDispatchedActions();

  // "/ref_code=:refCode"
  const refCodeMatch = location.pathname.match(/ref_code=(.*$)/);
  // "/r_:shortCode"
  const shortCodeMatch = location.pathname.match(/r_(.*$)/);

  const refCode = refCodeMatch ? refCodeMatch[1] : null;
  const shortCode = shortCodeMatch ? shortCodeMatch[1] : null;
  const code = refCode ?? shortCode ?? '';

  useClickRefCodeQuery(code, { skip: isAuth || !code });

  useEffect(() => {
    if (code) {
      saveRefCode(code);
      navigate('/', { replace: true });
      if (!isAuth) {
        changeAuthModal('registration');
      }
    }
  }, [code, isAuth, changeAuthModal, navigate]);

  return code;
};
