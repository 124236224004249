import RegisterForm, { baseSchema, RegisterFormData, RegisterFormError, RegisterFormProps } from './register-form';
import * as yup from 'yup';

type RegisterFormDataEmail = RegisterFormData<{
  email: string;
  name: string;
}>

export type RegisterErrorEmail = RegisterFormError<RegisterFormDataEmail>

export const schema: yup.ObjectSchema<RegisterFormDataEmail> = baseSchema.shape({
  email: yup.string().required('Введите E-mail').email('Неверный формат'),
  name: yup.string()
    .required('Введите имя')
    .test(
      'is-two-words',
      'Имя должно состоять как минимум из двух слов',
      (value) => value.trim().split(/\s+/).length >= 2
    ),
});

const defaultValues: RegisterFormDataEmail = {
  email: '',
  name: '',
  password: '',
  confirmPassword: '',
  currency: '',
  agree: false,
};

type RegisterFormByEmailProps = Omit<RegisterFormProps<RegisterFormDataEmail>, 'form'>

const RegisterFormByEmail = (props: RegisterFormByEmailProps) => (
  <RegisterForm
    {...props}
    form={{ defaultValues, schema }}
  >
    <RegisterForm.Email />
    <RegisterForm.Name />
  </RegisterForm>
);

export default RegisterFormByEmail;
