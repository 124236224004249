import { bemCn } from '@shared/utils/helpers/bem-cn';
import GradientIcon, { ColorName } from '../gradient-icon/gradient-icon';
import { AvailableIcon } from '../icon-svg/icon-svg';
import './balance-info.scss';

const b = bemCn('balance-info');

type BalanceInfoProps = {
  className?: string;
  title: string;
  value: string | number;
  icon?: AvailableIcon;
  iconColor?: ColorName;
  style?: 'dark' | 'light';
}

const BalanceInfo = ({
  className,
  title,
  value,
  icon,
  iconColor,
  style = 'dark'
}: BalanceInfoProps) => (
  <div className={b({ style }, className)}>
    <GradientIcon className={b('icon')}
      iconName={icon}
      color={iconColor}
    />
    <div className={b('wrapper')}>
      <p className={b('title')}>{title}</p>
      <p className={b('value')}>
        {value}
      </p>
    </div>
  </div>
);

export default BalanceInfo;
