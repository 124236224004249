import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useGetSubPartnerLinksQuery } from '@/modules/partner-program/api/managerApi';
import Desktop from '@/shared/components/responsive/desktop';
import Mobile from '@/shared/components/responsive/mobile';
import PartnerLinksList from '@/modules/partner-program/ui/ref-link/partner-links-list/partner-links-list';
import ParnterLinksTable from '@/modules/partner-program/ui/ref-link/partner-links-table/partner-links-table';
import Pagination from '@/shared/components/pagination/pagination';
import LoaderCenter from '@/shared/components/loader-center/loader-center';
import Breadcrumbs from '@/shared/components/breadcrumbs/breadcrumbs';

import type { GetSubPartnerLinks } from '@/modules/partner-program/model/types';

const initParams: Partial<GetSubPartnerLinks> = {
  page: 1,
  pageSize: 20,
};

const b = bemCn('partner-links-page');

const ManagerUserLinksPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const { partnerId } = useParams();
  const [params, setParams] = useState({ ...initParams, partnerId: Number(partnerId) });

  const { data, isLoading: isLinksLoading } = useGetSubPartnerLinksQuery(params);

  const handleChangeParams = (newParams: Partial<GetSubPartnerLinks>) => {
    setParams({ ...params, ...newParams });
  };

  const links = data?.items ?? [];
  const itemsCount = data?.count ?? 0;
  const isEmpty = !isLinksLoading && itemsCount === 0;

  return (
    <div className={b()}>
      <Breadcrumbs isGoBack />

      <div className={b('head')}>
        <h1 className={b('title')}>{`${t('user-links-page', 'Страница ссылок пользователя')} ID ${partnerId}`}</h1>
      </div>

      <Mobile>
        <PartnerLinksList links={links} role='manager' />
      </Mobile>
      <Desktop>
        <ParnterLinksTable links={links} role='manager' />
      </Desktop>

      {isEmpty && <p>{t('empty', 'Список пуст')}</p>}

      {isLinksLoading && <LoaderCenter />}
      <Pagination
        className={b('pagination')}
        page={params.page}
        pageSize={params.pageSize}
        totalItems={itemsCount}
        onChange={(page) => handleChangeParams({ page })}
      />
    </div>
  );
};

export { ManagerUserLinksPage };
