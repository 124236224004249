import { useTranslation } from 'react-i18next';
import { useCreatePartnerLinkMutation } from '@/modules/partner-program/api/partnerApi';
import { PartnerProject } from '@/modules/partner-program/model/types';
import ButtonIcon, { IButtonIconProps } from '@/shared/components/button-icon/button-icon';
import ResultModal from '@/shared/components/result-modal/result-modal';
import { useEffect, useState } from 'react';


type ProjectCreateLinkBtnProps = IButtonIconProps & {
  project: PartnerProject;
}

const ProjectCreateLinkBtn = ({
  className,
  project,
}: ProjectCreateLinkBtnProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [opened, setOpened] = useState(false);
  const [createLink, { isSuccess }] = useCreatePartnerLinkMutation();
  const handleClick = () => {
    createLink({
      projectId: project.id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpened(true);
    }
  }, [isSuccess]);

  return (
    <>
      <ButtonIcon
        className={className}
        icon='plus'
        variant='bordered'
        onClick={handleClick}
      />

      <ResultModal
        isOpen={opened}
        onClose={() => setOpened(false)}
        title={t('link-create-success', 'Ссылка успешно создана')}
        status='success'
      />
    </>
  );
};

export default ProjectCreateLinkBtn;
