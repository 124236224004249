import ResultModal from '@/shared/components/result-modal/result-modal';
import { useTranslation } from 'react-i18next';


type VerifyErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const VerifyErrorModal = ({ isOpen, onClose }: VerifyErrorModalProps) => {
  const { t } = useTranslation();
  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.confirmation.error.title', 'Ошибка верификации')}
      description={t('result-modal.confirmation.error.description', 'Обратитесь в службу поддержки')}
      status='error'
    />
  );
};

export default VerifyErrorModal;
