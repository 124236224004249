import { useTranslation } from 'react-i18next';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { parseTotalStats } from '../../lib/parseTotalStats';
import { PartnerStats } from '../../model/types';
import './pp-total-stat-desktop.scss';
import PPTable from '../core/pp-table/pp-table';
import PartnerStatsCards from '../core/partner-stats-cards/partner-stats-cards';

const b = bemCn('pp-total-stat-desktop');

type PPTotalStatDesktopProps = {
  stats: PartnerStats;
}

const PPTotalStatDesktop = ({ stats }: PPTotalStatDesktopProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const { mainStats, commonStats, providersStats } = parseTotalStats(stats);
  const baseStats = [...mainStats, ...commonStats];

  return (
    <div className={b()}>
      <div className={b('main')}>
        <PartnerStatsCards items={baseStats} />
      </div>
      <div className={b('providers')}>
        <PPTable
          dataSource={providersStats}
        >
          <PPTable.Column
            dataIndex='name'
            title={t('provider', 'Провайдер')}
          />
          <PPTable.Column
            dataIndex='placed'
            title={t('placed', 'Ставки')}
          />
          <PPTable.Column
            dataIndex='winnings'
            title={t('winnings', 'Выигрыш')}
          />
          <PPTable.Column
            dataIndex='income'
            title={t('provider-income', 'Прибыль')}
          />
        </PPTable>
      </div>
    </div>
  );
};

export default PPTotalStatDesktop;
