import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay, Controller } from 'swiper/modules';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { PropsWithCN } from '@shared/types/common';
import { currenciesTitles } from '@/shared/utils/constants/currency';
import { useTranslation } from 'react-i18next';
import { useGetLastwinsQuery } from '../../api/casino-api';
import { useAppSelector } from '@/shared/hooks';
import { getLastWins } from '../../model/selectors';
import Loader from '@/shared/components/loader/loader';
import casinoCardsImg from '@/assets/icons/icon-casino-cards.svg?url';

import { useResponsive } from '@/shared/hooks/use-responsive';
import './casino-winners.scss';

const b = bemCn('casino-winners');

const CasinoWinners: FC<PropsWithCN> = ({ className }) => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const { isLoading } = useGetLastwinsQuery();
  const lastwins = useAppSelector(getLastWins);

  return (
    <section className={b(null, className)}>
      <h2 className={b('title')}>
        {t('casino-widget.recent-winnings', 'Последние выигрыши')}
      </h2>
      {isLoading && <Loader className={b('loader')} />}

      {!isLoading && (
        <Swiper className={b('list')}
          direction="vertical"
          spaceBetween={isMobile ? 15 : 0}
          slidesPerView={isMobile ? 1 : 6}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          speed={2000}
          modules={[Autoplay, Controller]}
          allowTouchMove={false}
          loop={isMobile ? Boolean(lastwins.length) : lastwins.length > 6}
          wrapperClass={b('list-wrapper')}
        >
          {lastwins.map((item, i) => {
            const keyVal = `${item.gameId}-${i}`;
            return (
              <SwiperSlide className={b('swiper-slide')} key={keyVal}>
                <Link className={b('item')} to={item.link}>
                  <img className={b('item-icon')}
                    src={item.preview}
                    alt={item.gameName}
                    onError={(e) => (e.currentTarget.src = casinoCardsImg)}
                    loading='lazy'
                  />
                  <div className={b('item-info')}>
                    <p className={b('item-name')}>{item.gameName}</p>
                    <p className={b('item-bet')}>
                      {t('common.bet', 'Cтавка')}: {item.betAmount.toLocaleString()}{currenciesTitles[item.currency].symbol}
                    </p>
                  </div>
                  <p className={b('item-prize')}>
                    <span className={b('item-prize-value')}>
                      + {item.winAmount.toLocaleString()}{currenciesTitles[item.currency].symbol}
                    </span>
                    <span className={b('item-prize-label')}>
                      {t('casino-widget.last-win', 'Последний выигрыш')}
                    </span>
                  </p>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </section>
  );

  // return (
  //   <section className={b(null, className)}>
  //     <h2 className={b('title')}>
  //       {t('casino-widget.recent-winnings', 'Последние выигрыши')}
  //     </h2>
  //     {isLoading && <Loader className={b('loader')} />}
  //     <div className={b('list')}>
  //       {!isLoading && lastwins?.map((item) => (
  //         <a href={`/games/${item.provider}/${item.gameId}`} key={item.gameId} className={b('item')}>
  //           <img className={b('item-icon')}
  //             src={getImgLinkByProvider(item.provider, item.provider === 'smartsoft' ? item.gameName : item.gameId)}
  //             alt=""
  //           />
  //           <div className={b('item-info')}>
  //             <p className={b('item-name')}>{item.gameName}</p>
  //             <p className={b('item-bet')}>
  //               {t('common.bet', 'Cтавка')}: {item.betAmount.toLocaleString()}{currenciesTitles[item.currency].symbol}
  //             </p>
  //           </div>
  //           <p className={b('itme-prize')}>
  //             <span className={b('item-prize-value')}>
  //               + {item.winAmount.toLocaleString()}{currenciesTitles[item.currency].symbol}
  //             </span>
  //           </p>
  //         </a>
  //       ))}

  //     </div>
  //   </section>
  // );
};

export default CasinoWinners;
