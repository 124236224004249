import { useTranslation } from 'react-i18next';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useGetSubPartnersQuery } from '@/modules/partner-program/api/managerApi';
import { GetSubPartners } from '@/modules/partner-program/model/types';
import { useState } from 'react';
import Mobile from '@/shared/components/responsive/mobile';
import Pagination from '@/shared/components/pagination/pagination';
import Desktop from '@/shared/components/responsive/desktop';
import SubpartnersTable from '@/modules/partner-program/ui/subpartner/subpartner-table/subpartners-table';
import SubpartnerList from '@/modules/partner-program/ui/subpartner/subpartners-list/subpartners-list';
import './manager-users-page.scss';

const b = bemCn('manager-users-page');

const initParams: GetSubPartners = {
  page: 1,
  pageSize: 20,
};

const ManagerUsersPage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);
  const { data } = useGetSubPartnersQuery(params);

  const handleChangeParams = (newParams: Partial<GetSubPartners>) => {
    setParams((prev) => ({ ...prev, ...newParams }));
  };

  const subpartners = data?.items ?? [];
  const itemsCount = data?.count ?? 0;
  const isEmpty = itemsCount === 0;

  return (
    <div className={b()}>
      <div className={b('head')}>
        <h1 className={b('title')}>{t('partner-program.my-users', 'Мои пользователи')}</h1>
      </div>
      {isEmpty && <p>{t('empty', 'Список пуст')}</p>}
      <Mobile>
        {!isEmpty && <SubpartnerList subpartners={subpartners} />}
      </Mobile>
      <Desktop>
        {!isEmpty && <SubpartnersTable subpartners={subpartners} />}
      </Desktop>
      <Pagination
        className={b('pagination')}
        page={params.page}
        pageSize={params.pageSize}
        totalItems={itemsCount}
        onChange={(page) => handleChangeParams({ page })}
      />
    </div>
  );
};

export { ManagerUsersPage };
