import { useEffect } from 'react';
import { scrollToTop } from '@shared/utils/helpers/common';
import { useScrollToView } from './use-scroll-to-view';


export const useScrollToTop = (toggler: boolean | undefined = true) => {
  const scrollToView = useScrollToView();

  useEffect(() => {
    if (toggler && !scrollToView) {
      scrollToTop(true);
    }
  }, []);
};
