import { bemCn } from '@shared/utils/helpers/bem-cn';
import './promocode-activate-modal.scss';
import { FC } from 'react';
import AppModal from '@shared/components/app-modal/app-modal';
import PromocodeActivateFormConnected from '../promocode-activate-form/promocode-activate-form-wrapped';
import Button from '@shared/components/button/button';
import { useDispatchedActions } from '@shared/hooks';
import { useTranslation } from 'react-i18next';

const b = bemCn('promocode-activate-modal');

type PromocodeActivateModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const PromocodeActivateModal: FC<PromocodeActivateModalProps> = ({
  isOpen,
  onClose
}) => {
  const { t } = useTranslation();
  const { changeAppModal } = useDispatchedActions();
  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('promocodes.activate.title', 'Активация промокода')}
      className={b()}
    >
      <PromocodeActivateFormConnected className={b('form')} />
      <Button className={b('history-btn')}
        variant='text'
        onClick={() => changeAppModal('promocodes')}
      >
        {t('promocodes.promocodes-history')}
      </Button>
    </AppModal>
  );
};

export default PromocodeActivateModal;
