import { IPromocodeError } from '@/modules/user/store/types';
import i18n from '@/shared/i18n/i18n';
import { type HandledError, handleQueryError } from '@/shared/utils/helpers/handle-query-error';


export const handlePromocodeError = (error?: HandledError): IPromocodeError | undefined => {
  const queryError = handleQueryError(error);
  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;


    if (typeof detail === 'string') {
      const errors: IPromocodeError = {};
      switch (detail) {
        case 'Promocode not found':
          errors.promocode = i18n.t('result-modal.promocode.error.not-found', 'Неверный промокод');
          break;
        default:
          errors.root = detail;
      }

      return errors;
    }

    if (Array.isArray(detail)) {
      const errors: IPromocodeError = {};
      detail.forEach((err) => {
        const location = err.loc?.[1];
        const message = err.msg;
        switch (location) {
          case 'login':
            errors.promocode = message;
            break;
          default:
            errors.root = message;
        }
      });

      return errors;
    }

    return {
      root: 'unknown error'
    };
  }
};
