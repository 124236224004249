import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { bemCn } from '@shared/utils/helpers/bem-cn';
import Loader from '@/shared/components/loader/loader';
import { useAppSelector, useDispatchedActions } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';

import './reset-password-page.scss';

const b = bemCn('reset-password-page');

const ResetPasswordPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const isAuthenticated = useAppSelector(getIsAuth);
  const { openPasswordChangeModal } = useDispatchedActions();

  useEffect(() => {
    if (!id || isAuthenticated) {
      navigate('/');
    }
    openPasswordChangeModal(id as string);
    navigate('/');
  }, []);

  return (
    <div className={b()}>
      <Loader />
    </div>
  );
};

export default ResetPasswordPage;
