
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '@shared/components/icon-svg/icon-svg';
// import Button from '../ui/button/button';
// import mockBanner from './mock-banner.png';
// import mockBanner1 from './mock-banner-1.png';
import { SwiperSlide, Swiper, SwiperClass } from 'swiper/react';
import { Pagination, Autoplay, Controller } from 'swiper/modules';

import { useAppSelector } from '@shared/hooks';
import { getBannersByPageName } from '@shared/store/app/selectors';
import Loader from '@shared/components/loader/loader';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import type { TBannerPage } from '@/shared/store/app/types';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import './promo-banners.scss';

type PromoBannersProps = {
  className?: string;
  pageName?: TBannerPage;
}

const b = bemCn('promo-banners');
const PromoBanners = ({ className, pageName }: PromoBannersProps) => {
  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass>();
  const { isMobile } = useResponsive();
  const {
    mobile,
    desktop,
    isLoading,
  } = useAppSelector((state) => getBannersByPageName(state, pageName));

  const slides = isMobile ? mobile : desktop;

  return (
    <div className={b(null, className)}>
      <div className={b('wrapper')}>
        {/* <div className={b('list')}>
          <a href='#' className={b('item')} style={{ backgroundImage: `url(${mockBanner})` }}>
            <div className={b('item-info')}>
              <h2 className={b('title')}>{title}</h2>
              <p className={b('description')}>{description}</p>
              <Button className={b('button')} to='/'>Зарегистрироваться</Button>
            </div>
          </a>
        </div> */}

        {isLoading && <Loader className={b('loader')} />}
        {!isLoading && (
          <Swiper className={b('swiper')}
            spaceBetween={50}
            slidesPerView={1}
            loop={slides.length > 1}
            speed={2000}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              enabled: true,
              modifierClass: b('pagination-'),
              bulletClass: b('pagination-bullet'),
              bulletActiveClass: b('pagination-bullet--active'),
            }}
            onSwiper={setControlledSwiper}
            modules={[Pagination, Autoplay, Controller]}
          >
            {slides.map((banner) => (
              <SwiperSlide key={banner.id} className={b('swiper-slider')}>
                <Link to={banner.link ?? ''} className={b('item')}>
                  <img className={b('image')} src={banner.url} alt="" width={343} height={135} />
                  {(banner.title || banner.subtitle || banner.buttonText) && (
                    <div className={b('item-info')}>
                      <h3 className={b('title')}>{banner.title}</h3>
                      {banner.subtitle && (
                        <p className={b('desc')}>{banner.subtitle}</p>
                      )}
                      <p className={b('btn')}>{banner.buttonText}</p>
                    </div>
                  )}
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <div className={b('controlls')}>
          <button className={b('controll-btn', { prev: true })}
            onClick={() => controlledSwiper?.slidePrev(1000)}
          >
            <IconSvg className={b('controll-icon')}
              name="arrow-right"
              width={11}
              height={11}
            />
          </button>
          <button className={b('controll-btn', { next: true })}
            onClick={() => controlledSwiper?.slideNext(1000)}
          >
            <IconSvg className={b('controll-icon')}
              name="arrow-right"
              width={11}
              height={11}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromoBanners;
