export const preloadImage = (src: string) => new Promise((resolve, reject) => {
  const img = new Image();
  img.src = src;
  img.onload = resolve;
  img.onerror = reject;
});

export const preloadImages = (imagesArray: string[]) => {
  const promises = imagesArray.map((src) => preloadImage(src));
  return Promise.all(promises);
};
