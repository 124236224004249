import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import CasinoGameCard from '../casino-game-card/casino-game-card';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { CasinoGame } from '../../model/types';
import { useResponsive } from '@shared/hooks/use-responsive';
import './casino-games-section.scss';
import { useTranslation } from 'react-i18next';

const b = bemCn('casino-games-section');

export type CasinoGamesSectionProps = {
  className?: string;
  title?: string;
  linkTitle?: string;
  link?: string;
  bg?: boolean;
  items: CasinoGame[];
}

const CasinoGamesSection = (props: CasinoGamesSectionProps) => {
  const { t } = useTranslation();
  const {
    className,
    title = 'Казино',
    linkTitle = t('common.show-all', 'Показать все'),
    link = '#',
    bg,
    items,
  } = props;
  const { isMobile } = useResponsive();

  return (
    <section className={b({ bg }, className)}>
      <div className={b('wrapper')}>
        <div className={b('head')}>
          <h2 className={b('title')}>{title}</h2>
          <Link className={b('link-more')} to={link} state={{ scrollToView: true }}>
            {linkTitle}
            <IconSvg className={b('link-more-icon')} name="arrow-right" />
          </Link>
        </div>
        <div className={b('list')}>

          {items.slice(0, isMobile ? 6 : 14).map((game) => (
            <CasinoGameCard
              className={b('item')}
              key={game.id + game.link}
              name={game.name}
              link={game.link}
              preview={game.preview}
              gameProvider={game.provaider}
              isFavorite={game.isFavorite}
              game={game}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CasinoGamesSection;
