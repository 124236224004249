import { SubmitHandler } from 'react-hook-form';
import i18n from '@/shared/i18n/i18n';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Button from '@shared/components/button/button';
import * as yup from 'yup';
import Form from '@shared/components/form/form';
import { useTranslation } from 'react-i18next';

import './password-change-form.scss';

const b = bemCn('password-change-form');

export type PasswordChangeFormData = {
  password: string;
  confirmPassword: string;
};

const schema = yup
  .object({
    password: yup.string()
      .min(8, i18n.t('reset-pass.errors.short-pass', 'Слишком короткий пароль'))
      .required(i18n.t('reset-pass.errors.empty-pass', 'Введите пароль')),
    confirmPassword: yup.string()
      .min(8, i18n.t('reset-pass.errors.short-pass', 'Слишком короткий пароль'))
      .required(i18n.t('reset-pass.errors.empty-pass', 'Введите пароль'))
      .oneOf([yup.ref('password')], i18n.t('reset-pass.errors.must-match', 'Пароли должны совпадать')),
  })
  .required();

const defaultValues: PasswordChangeFormData = {
  password: '',
  confirmPassword: '',
};

type PasswordChangeFormProps = {
  className?: string;
  onFormSubmit?: SubmitHandler<PasswordChangeFormData>;
  loading?: boolean;
};

const PasswordChangeForm = (props: PasswordChangeFormProps) => {
  const {
    className,
    onFormSubmit,
    loading,
  } = props;

  const { t } = useTranslation();

  return (
    <Form<PasswordChangeFormData>
      className={b(null, className)}
      onSubmit={onFormSubmit}
      form={{
        disabled: loading,
        defaultValues,
        schema,
      }}
    >
      <Form.TextField<PasswordChangeFormData>
        className={b('field')}
        name="password"
        type="password"
        placeholder={t('reset-pass.password', 'Пароль')}
        disabled={loading}
      />
      <Form.TextField
        className={b('field')}
        type='password'
        name="confirmPassword"
        placeholder={t('reg.modal.repeat-password', 'Повторите пароль')}
        disabled={loading}
      />

      <Button className={b('button')}
        type="submit"
        disabled={loading}
      >
        {t('reset-pass.submit-btn', 'Изменить пароль')}
      </Button>
    </Form>
  );
};

export default PasswordChangeForm;
