import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Loader from '@/shared/components/loader/loader';
import Button from '@shared/components/button/button';
import { NotificationCardDesktop, useNotifications } from '@/modules/notifications';

import './notifications-dropdown.scss';

const MemoizedNotificationCard = memo(NotificationCardDesktop);

const b = bemCn('notifications-dropdown');

const NotificationsDropdownContent = () => {
  const { t } = useTranslation();
  const {
    notifications,
    isNotificationLoading,
    isNotificationsFetching,
    isShowMoreBtn,
    handleShowMoreClick,
  } = useNotifications();

  const memoizedNotifications = useMemo(() => notifications.map((item) => (
    <MemoizedNotificationCard
      className={b('item')}
      key={item.id}
      item={item}
    />
  )), [notifications]);

  return (
    <div className={b('list')}>
      {isNotificationLoading && <Loader className={b('loading')} />}
      {notifications.length === 0 && (
        <p className={b('empty')}>{t('empty', 'Список пуст')}</p>
      )}
      {memoizedNotifications}
      {isShowMoreBtn && (
        <Button
          className={b('show-more')}
          variant="secondary"
          onClick={handleShowMoreClick}
          loading={isNotificationsFetching}
          loadingType='dots'
        >
          {t('common.load-more', 'Загрузить ещё')}
        </Button>
      )}
    </div>
  );
};

export default NotificationsDropdownContent;
