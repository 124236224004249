import { createSelector } from '@reduxjs/toolkit';
import { betapi } from './betapi';
import { GetBetapiCountriesParams, GetBetapiEventParams, GetBetapiEventsListParams, GetBetapiSportsParams, GetBetapiTournamensParams, IEventsFilterCountry, IEventsFilterSport } from './types';
import { sortByTopEntries } from '@shared/utils/helpers/common';
import { topCountriesId } from '@shared/utils/constants/common';
import { TEventDetailed, TTournamentEvents } from './line';
import { getBasketItemsIds } from '@/modules/basket';
import i18n from '@/shared/i18n/i18n';
import { getAppSports } from '@/shared/store/app/selectors';
import { getFavoritesMathes } from '@/modules/user/store/selectors';

export const getSports = (params: GetBetapiSportsParams) => createSelector(
  betapi.endpoints.getBetapiSports.select(params),
  getAppSports,
  ({ data = [] }, appSports) => {
    const sportsBlacklist = appSports?.sportsBlacklist ?? [];

    const filteredSports = data.filter((item) =>
      ![item.name, item.nameRu, item.nameEn]
        .some((name) => sportsBlacklist.includes(name.toLowerCase())));

    return filteredSports;
  }
);

export const getCountries = (params: GetBetapiCountriesParams) =>
  createSelector(
    betapi.endpoints.getBetapiCountries.select(params),
    ({ data = [] }) => sortByTopEntries(data, topCountriesId, (item) => item.id),
  );

export const getTournaments = (params: GetBetapiTournamensParams) => createSelector(
  betapi.endpoints.getBetapiTournaments.select(params),
  getAppSports,
  ({ data = [] }, appSports) => {

    const tournamentsBlackList = appSports?.tournamentsBlackList ?? [];

    const validTournaments = data?.filter((item) => ![item.name, item.nameRu, item.nameEn]
      .some((name) => tournamentsBlackList.includes(name.toLowerCase())));

    // Старая сортировка по статичному набору id
    // return sortByTopEntries(
    //   validTournaments,
    //   topCountriesId,
    //   (item) => item.countryId
    // );

    return sortByTopEntries(
      validTournaments,
      appSports?.tournamentsPriority ?? [],
      (item) => item['nameRu'].toLowerCase()
    );
  }
);

export const getEventsFilterList = (params: GetBetapiSportsParams & GetBetapiCountriesParams & GetBetapiTournamensParams) => createSelector(
  getSports({ ...params }),
  getCountries({ ...params }),
  getTournaments({ ...params }),
  (sports, countries, tournaments): IEventsFilterSport[] => {
    const groupedSports = sports.map((sport) => ({
      ...sport,
      countries: countries.reduce((acc, country) => {
        if (country.sportId === sport.id) {
          acc.push({
            ...country,
            tournaments: tournaments.filter((tournament) => tournament.countryId === country.id && tournament.sportId === sport.id)
          });
        }

        return acc;
      }, [] as IEventsFilterCountry[])
    }));

    return groupedSports;
  }
);

const getBetapiEventsByTourney = (params: GetBetapiEventsListParams) => createSelector(
  betapi.endpoints.getBetapiEventsByTourney.select(params),
  getFavoritesMathes,
  ({ data = [] }, favoritesMatches) =>
    data.map((tournament) => ({
      ...tournament,
      events: tournament.events.map((event) => {
        const isFavorite = favoritesMatches.findIndex((item) => item.matchId === Number(event.mainId)) >= 0;
        return {
          ...event,
          isFavorite
        };
      })
    }))
);

export const getTournamentsEvents = (params: GetBetapiEventsListParams) => createSelector(
  getBetapiEventsByTourney(params),
  getBasketItemsIds,
  getAppSports,
  (data = [], basketItemsIds, appSports) => {
    const tournamentsBlacklist = appSports?.tournamentsBlackList ?? [];
    // const sportsBlacklist = appSports?.sportsBlacklist ?? [];

    const filteredList = data.filter((tournament) => tournament.events.length > 0
      && ![tournament.name].some((item) => tournamentsBlacklist.includes(item.toLowerCase())));

    const sortedList = sortByTopEntries(
      filteredList,
      appSports?.tournamentsPriority ?? [],
      (item) => item['name'].toLowerCase()
    );

    return sortedList.map((tournament) => ({
      ...tournament,
      events: tournament.events.map((event) => ({
        ...event,
        bets: event.bets.map((bet) => ({
          ...bet,
          isActive: basketItemsIds.includes(bet.id)
        })),
        mainBets: event.mainBets.map((bet) => ({
          ...bet,
          isActive: basketItemsIds.includes(bet.id)
        })),
      }))
    }));
  }
);


export type EventsBySport = {
  id: string;
  name: string;
  tournaments: TTournamentEvents[];
}

export const getEventsListBySport = (params: GetBetapiEventsListParams) => createSelector(
  getBetapiEventsByTourney(params),
  getAppSports,
  (data = [], appSports) => {

    const tournamentsBlackList = appSports?.tournamentsBlackList ?? [];
    const sportsBlacklist = appSports?.sportsBlacklist ?? [];


    const bySportId = data.reduce<Record<string, EventsBySport>>((acc, item) => {
      if (item.events.length > 0) {
        const someEvent = item.events[0];
        const sportId = someEvent.sport.id;
        const sportName = someEvent.sport.name;

        if (
          ![
            sportName,
            someEvent.sport.nameRu ?? '',
            someEvent.sport.nameEn ?? ''
          ].some((sName) => sportsBlacklist.includes(sName.toLowerCase()))
        ) {
          acc[sportId] = acc[sportId] || {
            id: sportId,
            name: sportName,
            tournaments: []
          };
          if (![item.name].some((it) =>
            tournamentsBlackList.includes(it.toLowerCase()))) {
            acc[sportId].tournaments.push(item);
          }
        }
      }
      return acc;
    }, {} as Record<string, EventsBySport>);

    const eventsBySports = [];

    for (const id in bySportId) {
      const spotEvents = bySportId[id];

      eventsBySports.push(({
        ...spotEvents,
        tournaments: sortByTopEntries(
          spotEvents.tournaments,
          appSports?.tournamentsPriority ?? [],
          (item) => item['name'].toLowerCase()
        )
      }));
    }

    return eventsBySports;
  }
);

export const getEvent = (params: GetBetapiEventParams) => createSelector(
  betapi.endpoints.getBetapiEvent.select(params),
  ({ data }) => data,
);

export const getEventDetailed = (
  mainGameParams: GetBetapiEventParams,
  subgameParams: GetBetapiEventParams,
) => createSelector(
  getEvent(mainGameParams),
  getEvent(subgameParams),
  getBasketItemsIds,
  getAppSports,
  (mainGame, subgame, basketItemsIds, appSports): TEventDetailed | undefined => {
    if (!mainGame) {
      return undefined;
    }
    const subGamesBlackList = appSports?.subGamesBlackList ?? [];
    const eventSubgames = mainGame?.subgames ?? [];

    const filteredSubgames = eventSubgames.filter((item) =>
      !subGamesBlackList.includes(item.name.toLowerCase()));

    const betGroups = mainGameParams.gameId === subgameParams.gameId
      ? mainGame.betsGroups
      : subgame?.betsGroups ?? [];

    return {
      ...mainGame,
      subgames: [
        {
          id: mainGame.id,
          num: mainGame.num,
          name: i18n.t('event.bets.main-tab', 'Основные'),
        },
        ...filteredSubgames
      ],
      betsGroups: betGroups.map((betGroup) => ({
        ...betGroup,
        bets: betGroup.bets.map((bet) => ({
          ...bet,
          isActive: basketItemsIds.includes(bet.id)
        })),
      }))
    };
  }
);
