import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { TEventSubgame } from '@/modules/line/model/line';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import ButtonIcon from '@/shared/components/button-icon/button-icon';
import { FC, useRef } from 'react';
import Desktop from '@/shared/components/responsive/desktop';
import './event-subgames-tabs.scss';

type EventSubgamesTabsProps = {
  className?: string;
  subgames: TEventSubgame[];
  activeSugbameId: string;
  onSubgameChange: (subgameId: string) => void;
  onToggleClick?: () => void;
}

const EventSubgamesTabs: FC<EventSubgamesTabsProps> = ({
  className,
  subgames,
  activeSugbameId,
  onSubgameChange,
  onToggleClick,
}) => {
  const b = bemCn('event-subgames-tabs');

  const containerRef = useRef<HTMLDivElement>(null);

  const handleLeftClick = () => {
    const container = containerRef.current;
    if (container) {
      container.style.scrollBehavior = 'smooth';
      container.scrollLeft -= 300;
    }
  };

  const handleRightClick = () => {
    const container = containerRef.current;
    if (container) {
      container.style.scrollBehavior = 'smooth';
      container.scrollLeft += 300;
    }
  };

  return (
    <div className={b(null, className)}>
      <button className={b('toggl')} type='button' onClick={onToggleClick}>
        <IconSvg className={b('toggl-icon')} name='collapse-all' />
      </button>

      <div className={b('list')} ref={containerRef}>
        {subgames.map((item) => (
          <button className={b('item', { active: activeSugbameId === item.id })}
            type='button'
            key={item.id}
            onClick={() => onSubgameChange(item.id)}
          >
            {item.name}
          </button>
        ))}
      </div>

      <Desktop>
        <div className={b('controlls')}>
          <ButtonIcon className={b('controlls-btn', { prev: true })}
            onClick={handleLeftClick}
            variant="bordered"
            icon='arrow-right'
          />
          <ButtonIcon className={b('controlls-btn', { next: true })}
            onClick={handleRightClick}
            variant="bordered"
            icon='arrow-right'
          />
        </div>
      </Desktop>
    </div>
  );
};

export default EventSubgamesTabs;
