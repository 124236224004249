import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { PartnerProject } from '@/modules/partner-program/model/types';
import PartnerProjectCard from '../partner-project-card/partner-project-card';
import { PropsWithCN } from '@/shared/types/common';
import './partner-projects-list.scss';

const b = bemCn('partner-projects-list');

type PartnerProjectsListProps = PropsWithCN & {
  projects: PartnerProject[];
}

const PartnerProjectsList = ({
  className,
  projects,
}: PartnerProjectsListProps) => (
  <div className={b(null, className)}>
    {projects.map((project) => (
      <PartnerProjectCard key={project.id} project={project} />
    ))}
  </div>
);

export default PartnerProjectsList;
