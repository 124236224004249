import { useTranslation } from 'react-i18next';
import { Subpartner } from '@/modules/partner-program/model/types';
import { PropsWithCN } from '@/shared/types/common';
import PPTable from '../../core/pp-table/pp-table';
import SubpartnerStatsBtn from '../subpartner-stats-btn/subpartner-stats-btn';
import SubpartnerLinksBtn from '../subpartner-links-btn/subpartner-links-btn';
import SubpartnerPromocodesBtn from '../subpartner-promocodes-btn/subpartner-promocodes-btn';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './subpartners-table.scss';

const b = bemCn('subpartners-table');

type SubpartnersTableProps = PropsWithCN & {
  subpartners: Subpartner[];
}

const SubpartnersTable = ({ subpartners }: SubpartnersTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  const getTableBtns = (subpartner: Subpartner): React.ReactNode => (
    <div className={b('btns')}>
      <SubpartnerStatsBtn
        className={b('controls-btn')}
        subpartner={subpartner}
      />
      <SubpartnerLinksBtn
        className={b('controls-btn')}
        subpartner={subpartner}
      />
      <SubpartnerPromocodesBtn
        className={b('controls-btn')}
        subpartner={subpartner}
      />
    </div>
  );

  return (
    <PPTable<Subpartner>
      className={b()}
      dataSource={subpartners}
    >
      <PPTable.Column<Subpartner>
        dataIndex=''
        title=''
        render={(_, subpartner) => getTableBtns(subpartner)}
      />
      <PPTable.Column<Subpartner>
        dataIndex='name'
        title={t('name', 'Имя')}
      />
      <PPTable.Column<Subpartner>
        dataIndex='created'
        title={t('registered', 'Зарегистрирован')}
        render={(_, { created }) => dayjsCustom(created).format('DD.MM.YYYY HH:mm')}
      />
      <PPTable.Column<Subpartner>
        dataIndex='login'
        title={t('login', 'Логин')}
      />
      <PPTable.Column<Subpartner>
        dataIndex='email'
        title='Email'
      />
      <PPTable.Column<Subpartner>
        dataIndex='phone'
        title={t('phone', 'Телефон')}
      />
    </PPTable>
  );
};

export default SubpartnersTable;
