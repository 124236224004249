import Container from '@shared/components/container/container';
import { bemCn } from '@shared/utils/helpers/bem-cn';

import './game-screen.scss';
import Loader from '@/shared/components/loader/loader';

const b = bemCn('game-screen');

type GameScreenProps = {
  src?: string;
  title?: string;
  isError?: boolean;
  errorMessage?: string;
  loading?: boolean;
}

const GameScreen = ({ src, title, isError, errorMessage, loading }: GameScreenProps) => (
  <div className={b({ loading })}>
    <Container className={b('container')}>
      {isError && errorMessage && (
        <div className={b('error-message')}>{errorMessage}</div>
      )}
      {loading && (
        <Loader className={b('loader')} />
      )}
      <iframe className={b('frame')}
        title={title}
        scrolling='no'
        allow="fullscreen"
        frameBorder="0"
        src={src}
      />
    </Container>
  </div>
);

export default GameScreen;
