import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useContext, useEffect, useState } from 'react';
import SearchFilter from '@/shared/components/search-filter/search-filter';
import { useTournamentEventsToggler } from '../../model/use-tournament-events-toggler';
import { useAppSelector } from '@shared/hooks';
import TournamentEventsList from '../tournament-events-list/tournament-events-list';
import { useGetBetapiEventsByTourneyQuery } from '../../model/betapi';
import { getTournamentsEvents } from '../../model/selectors';
import { DataType } from '../../model/types';
import EventsFilterContext from '../../model/events-filter-context';
import PromoBanners from '@/widgets/core/components/promo-banners/promo-banners';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '@/modules/theme/model/theme-context';
import { getFavoritesMatchesByDatatype } from '@/modules/user/store/selectors';
import FavoritesEventsList from '../favorites-events-list/favorites-events-list';
import './events-desktop.scss';

const b = bemCn('events-desktop');

type EventsDesktopProps = {
  dataType: DataType;
}

const EventsDesktop = ({ dataType }: EventsDesktopProps) => {
  const { i18n: { resolvedLanguage: dataLang } } = useTranslation();
  const [, setSearchFilter] = useState<string>('');
  const { isDark } = useThemeContext();
  const [isRefetch, setIsRefetch] = useState(true);
  const { filter } = useContext(EventsFilterContext);
  const { closedItems, handleToggleItem } = useTournamentEventsToggler();
  const isFavorites = !!filter.faorites;

  const params = {
    sportId: filter.sport,
    tournamentId: filter.tournament,
    pageLength: 100,
    dataType,
    dataLang,
  };

  const { isFetching: isEventsFetching } = useGetBetapiEventsByTourneyQuery(params, {
    pollingInterval: params.dataType === 'live' ? 4000 : 0,
    skip: isFavorites
  });

  const eventsList = useAppSelector(getTournamentsEvents(params));
  const favoritesEvents = useAppSelector((state) => getFavoritesMatchesByDatatype(state, dataType));

  useEffect(() => {
    if (!isEventsFetching) {
      setIsRefetch(false);
    }
  }, [isEventsFetching]);

  useEffect(() => {
    setIsRefetch(true);
  }, [filter.sport, filter.tournament]);

  const isListLoading = isEventsFetching && isRefetch;

  return (
    <div className={b()}>
      <PromoBanners className={b('banners')} pageName='line' />

      <SearchFilter className={b('search')}
        onChange={(search) => setSearchFilter(search)}
        inverted={isDark}
      />
      {!isFavorites && (
        <TournamentEventsList className={b('list')}
          events={eventsList}
          closedItems={closedItems}
          onToggle={handleToggleItem}
          loading={isListLoading}
        />
      )}

      {isFavorites && (
        <FavoritesEventsList items={favoritesEvents} />
      )}

    </div>
  );
};

export default EventsDesktop;
