

export const saveCurrencies = (currencies: string[]) => {
  localStorage.setItem('currencies', JSON.stringify(currencies));
};

export const getStoredCurrencies = () => {
  const currencies = localStorage.getItem('currencies');
  return currencies ? JSON.parse(currencies) as string[] : [];
};

export const dropStoredCurrencies = () => {
  localStorage.removeItem('currencies');
};
