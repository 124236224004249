import { useToggle } from 'usehooks-ts';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import BetsGroupItem from './bets-group-item/bets-group-item';
import { memo, useEffect, useMemo } from 'react';
import './bets-group.scss';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { TEventBetItem } from '../../../../model/line';

const MemoizedItem = memo(BetsGroupItem);

type BetsGroupProps = {
  className?: string;
  onBetClick?: (betId: string) => void;
  name: string;
  bets: TEventBetItem[];
  isOpened?: boolean;
}

const b = bemCn('bets-group');

const BetsGroup = ({ className, name, bets, isOpened, onBetClick }: BetsGroupProps) => {
  const [opened, toggleOpened, setOpened] = useToggle(false);

  useEffect(() => {
    if (isOpened !== undefined && isOpened !== opened) {
      setOpened(isOpened);
    }
  }, [isOpened]);

  const memoizedItems = useMemo(() => bets.map((item) => (
    <MemoizedItem className={b('item')}
      key={item.id}
      id={item.id}
      name={item.name}
      rate={item.rate}
      isActive={item.isActive}
      onClick={onBetClick}
      disabled={item.blocked}
    />
  )), [bets, onBetClick]);

  return (
    <div className={b({ opened }, className)}>
      <button className={b('head')} type='button' onClick={toggleOpened}>
        <span className={b('title')}>{name}</span>
        <IconSvg className={b('togle-icon')}
          name='chevron-down'
          width={8}
          height={8}
        />
      </button>
      <div className={b('items')}>
        {memoizedItems}
      </div>
    </div>
  );

};

export default BetsGroup;
