import { useAppSelector } from '@/shared/hooks';
import CasinoGamesSection, { CasinoGamesSectionProps } from '../casino-games-section/casino-section';
import { getCasinoGamesByCategory } from '../../model/selectors';


type CasinoGamesSectionByCategoryProps = Omit<CasinoGamesSectionProps, 'items'> & {
  category: string;
};

const CasinoGamesSectionByCategory = ({ category, link, ...props }: CasinoGamesSectionByCategoryProps) => {
  const games = useAppSelector((state) => getCasinoGamesByCategory(state, category));

  if (games.length <= 0) {
    return null;
  }

  return (
    <CasinoGamesSection
      {...props}
      items={games}
      link={link ?? `/games?category=${category}`}
    />
  );
};

export default CasinoGamesSectionByCategory;
