import iconsSprite from '@assets/icons/sprite.svg?url';
import { preloadImage } from '@shared/utils/helpers/preload';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './icon-svg.scss';

preloadImage(iconsSprite);

export type AvailableIcon =
  | 'alpine-skiing'
  | 'allert'
  | 'android'
  | 'apple'
  | 'arrow-right'
  | 'badminton'
  | 'baseball'
  | 'basketball'
  | 'biathlon'
  | 'boxing'
  | 'calendar'
  | 'chess'
  | 'cart'
  | 'check'
  | 'chevron-down'
  | 'chevron-right'
  | 'clock'
  | 'close'
  | 'coin-flat'
  | 'coin'
  | 'collapse-all'
  | 'copy'
  | 'coupon'
  | 'cricket'
  | 'cup'
  | 'darts'
  | 'desktop'
  | 'dota-2'
  | 'dots-menu'
  | 'edit'
  | 'exit'
  | 'fire'
  | 'football'
  | 'futsal'
  | 'games'
  | 'gmail'
  | 'handball'
  | 'hide'
  | 'hockey'
  | 'home'
  | 'instagram'
  | 'lock'
  | 'link'
  | 'live'
  | 'mailru'
  | 'mma'
  | 'odnoklassniki'
  | 'phone'
  | 'pie-chart'
  | 'plus'
  | 'print'
  | 'rugby'
  | 'search'
  | 'settings'
  | 'show'
  | 'star'
  | 'steam'
  | 'support'
  | 'support-tg'
  | 'switch'
  | 'table-tennis'
  | 'telegram'
  | 'tennis'
  | 'time-machine'
  | 'trash'
  | 'vkontakte'
  | 'volleyball'
  | 'warning'
  | 'yandex'
  | 'new'
  | 'coin-colored'
  | 'piggy-bank'
  | 'heart'
  | 'bell'
  | 'user'
  | 'new-star'
  | 'live-tv'
  | 'skiing'
  | 'f1'
  | 'beach-volleyball'
  | 'motosport'
  | 'bicycle-racing'
  | 'golf-1'
  | 'athletics'
  | 'rowing'
  | 'surfing'
  | 'stats'
  | 'warning-flat'
  | 'refresh';


type IconSvgProps = {
  name?: AvailableIcon;
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
}

const b = bemCn('svg-icon');

const IconSvg = (props: IconSvgProps) => {
  const {
    name = 'settings',
    fill = 'currentColor',
    width = 32,
    height = 32,
    className
  } = props;

  return (
    <svg className={b(null, className)} fill={fill} width={width} height={height}>
      <use xlinkHref={`${iconsSprite}#icon-${name}`}></use>
    </svg>
  );
};

export default IconSvg;
