import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useValueChanged } from '@shared/hooks/use-value-changed';
import './bets-group-item.scss';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { getIsAuth } from '@/modules/auth';
import { useAppSelector, useDispatchedActions } from '@/shared/hooks';


type BetsGroupItemProps = {
  className?: string;
  id: string;
  name: string;
  rate: number;
  isActive?: boolean;
  onClick?: (betId: string) => void;
  disabled?: boolean;
}

const b = bemCn('bets-group-item');

const BetsGroupItem = (props: BetsGroupItemProps) => {
  const { className, id, name, rate, isActive, onClick, disabled } = props;
  const [currentRate, diff] = useValueChanged(rate);
  const isAuth = useAppSelector(getIsAuth);
  const { changeAuthModal } = useDispatchedActions();

  const handleClick = () => {
    if (isAuth) {
      onClick?.(id);
    } else {
      changeAuthModal('login');
    }
  };

  return (
    <button className={b({ diff, active: isActive, disabled }, className)} type="button" disabled={disabled} onClick={handleClick}>
      <span className={b('name')}>{name}</span>
      <span className={b('rate')}>{currentRate}</span>
      <IconSvg className={b('lock-icon')} name="lock" width={16} height={16} />
    </button>
  );
};

export default BetsGroupItem;
