import { bemCn } from '@/shared/utils/helpers/bem-cn';
import Container from '@/shared/components/container/container';
import Breadcrumbs from '@/shared/components/breadcrumbs/breadcrumbs';
import { useGetFavoriteMatchesQuery } from '@/modules/user/store/user-api';
import { useAppSelector } from '@/shared/hooks';
import { getFavoritesMatchesByDatatype } from '@/modules/user/store/selectors';
import FavoritesEventsList from '@/modules/line/components/favorites-events-list/favorites-events-list';

import './live-favorites-screen-mobile.scss';
import { useTranslation } from 'react-i18next';

const b = bemCn('live-favorites-screen-mobile');
const LiveFavoritesScreenMobile = () => {
  const { t } = useTranslation();
  useGetFavoriteMatchesQuery();
  const items = useAppSelector((state) => getFavoritesMatchesByDatatype(state, 'live'));

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        <h1 className={b('title')}>
          {t('common.favorite', 'Избранные матчи')}
        </h1>
        <FavoritesEventsList className={b('list')} items={items} />
      </Container>
    </div>
  );
};


export default LiveFavoritesScreenMobile;
