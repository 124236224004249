import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { Trans } from 'react-i18next';
import bannerBg from './assets/casino-promo-banner-cards.jpg';
import { Link } from 'react-router-dom';
import type { PropsWithCN } from '@/shared/types/common';
import type { FC } from 'react';
import ImgSmart from '@/shared/components/img-smart/img-smart';
import './casino-promo-banner.scss';

const b = bemCn('casino-promo-banner');

const CasinoPromoBanner: FC<PropsWithCN> = ({ className }) => (
  <Link className={b(null, className)}
    to="/games"
  >
    <ImgSmart className={b('bg-img')}
      image={{ src: bannerBg, }}
    />
    <div className={b('wrapper')}>
      <h2 className={b('title')}>
        <Trans i18nKey="casino-widget.promo-banner.title">
          Заходи в казино <br /> и выиграй джекпот
        </Trans>
      </h2>
      <p className={b('text')}>
        <Trans i18nKey="casino-widget.promo-banner.text">
          Всё очень легко и просто
        </Trans>
      </p>
    </div>
  </Link>
);

export default CasinoPromoBanner;
