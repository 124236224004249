import { PartnerPromocode } from '@/modules/partner-program/model/types';
import PPCard from '../../core/pp-card/pp-card';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import PromocodeStatsBtn from '../promocode-stats-btn/promocode-stats-btn';
import ManagerUserPromocodeStatsBtn from '../manager-user-promocode-stats-btn/manager-user-promocode-stats-btn';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './partner-promocode-card.scss';

type PartnerPromocodeCardProps = {
  promocode: PartnerPromocode;
  role: 'partner' | 'manager';
}

const b = bemCn('partner-promocode-card');

const PartnerPromocodeCard = ({
  promocode,
  role,
}: PartnerPromocodeCardProps) => {
  const { id, name, created } = promocode;

  const getStatsBtns = () => {
    if (role === 'partner') {
      return (
        <PromocodeStatsBtn className={b('controls-btn')}
          icon='pie-chart'
          variant='bordered'
          promocode={promocode}
        />
      );
    } else if (role === 'manager') {
      return (
        <ManagerUserPromocodeStatsBtn className={b('controls-btn')}
          icon='pie-chart'
          variant='bordered'
          promocode={promocode}
        />
      );
    }
  };

  const items = [
    {
      label: 'id',
      value: id
    },
    {
      label: 'name',
      value: name
    },
    {
      label: 'created',
      value: dayjsCustom(created).format('DD.MM.YYYY')
    },
  ];

  return (
    <PPCard className={b()}
      items={items}
      contorls={getStatsBtns()}
    />
  );
};

export default PartnerPromocodeCard;
