import { useTranslation } from 'react-i18next';

const ManagerHomePage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  return (
    <div className="">
      <h1>{t('manager', 'Менеджер')}: {t('main-page', 'Главная страница')}</h1>
    </div>
  );
};

export { ManagerHomePage };
