import { useCreateVegasSessionQuery } from '@/modules/casino';
import { useParams } from 'react-router-dom';
import GameScreen from '../game-screen';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { SiteMaintenance, Maintenance } from '@/widgets/site-maintenance';
import { useTranslation } from 'react-i18next';

const VegasClub = () => {
  const { t } = useTranslation();
  const { data: appStatuses } = useGetStatusQuery();
  const isVegasActive = appStatuses?.vegas ?? false;
  const { gameId = '' } = useParams();
  const { data: sessionUrl, isLoading, isError } = useCreateVegasSessionQuery({
    gameId,
    lang: 'ru',
  }, { skip: !isVegasActive });

  if (!isVegasActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <GameScreen
      title='Vegas'
      src={sessionUrl}
      loading={isLoading}
      isError={isError}
      errorMessage={t('common.unknown-error', 'Неизвестная ошибка')}
    />
  );
};

export default VegasClub;
