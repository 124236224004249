import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { FC } from 'react';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import './payment-method.scss';

type PaymentMethodProps = {
  className?: string;
  title?: string;
  icons?: string[];
  link?: string;
  href?: string;
  label?: string;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
}


const b = bemCn('payment-method');

const PaymentMethod: FC<PaymentMethodProps> = ({
  className,
  title,
  icons,
  href,
  link,
  onClick,
  label,
  active,
  disabled,
}) => {

  const innerContent = (
    <>
      <span className={b('title')}>{title}</span>
      <span className={b('icons')}>
        {icons?.map((icon) => {
          if (icon === 'support') {
            return (
              <IconSvg className={b('icon')}
                key={icon}
                name='support'
                width={40}
                height={20}
              />
            );
          }

          return (
            <img className={b('icon')}
              key={icon}
              src={icon}
              width={40}
              height={20}
              alt=''
            />
          );
        })}
      </span>
      {label && <span className={b('label')}>{label}</span>}
    </>
  );

  if (href) {
    return (
      <a className={b({ active }, className)}
        href={href}
        // onClick={onClick}
        target='_blank'
        rel="noreferrer"
      >
        {innerContent}
      </a>
    );
  }


  if (link) {
    return (
      <Link className={b({ active }, className)}
        to={link}
      // onClick={onClick}
      >
        {innerContent}
      </Link>
    );
  }

  return (
    <button className={b({ active }, className)}
      type='button'
      onClick={onClick}
      disabled={disabled}
    >
      {innerContent}
    </button>
  );

};

export default PaymentMethod;
