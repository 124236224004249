import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/shared/hooks';
import { getPasswordResetId } from '../../model/selectors';
import { useDispatchedActions } from '@/shared/hooks';
import { useResetPasswordMutation } from '../../model/auth-api';
import AppModal from '@shared/components/app-modal/app-modal';
import PasswordChangeForm from '../password-change-form/password-change-form';

type PasswordChangeModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

const PasswordChangeModal = ({ isOpen, onClose }: PasswordChangeModalProps) => {
  const { t } = useTranslation();

  const [changePassword, { isLoading, isSuccess }] = useResetPasswordMutation();

  const passwordResetId = useAppSelector(getPasswordResetId);
  const { changeAuthModal } = useDispatchedActions();

  useEffect(() => {
    if (isSuccess) {
      changeAuthModal('password-change-success');
    }
  }, [isSuccess]);

  const handleFormSubmit = async (data: { password: string }) => {
    if (passwordResetId) {
      await changePassword({ newPassword: data.password, passwordResetId });
    }
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('reset-pass.title', 'Восстановление пароля')}
      description={t('reset-pass.description', 'Введите новый пароль')}
    >

      <PasswordChangeForm
        onFormSubmit={handleFormSubmit}
        loading={isLoading}
      />
    </AppModal>
  );
};

export default PasswordChangeModal;
