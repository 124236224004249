import Button from '@/shared/components/button/button';
import ResultModal from '@/shared/components/result-modal/result-modal';
import { useTranslation } from 'react-i18next';


type BetSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const BetSuccessModal = ({ isOpen, onClose }: BetSuccessModalProps) => {
  const { t } = useTranslation();
  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      status='success'
      title={t('result-modal.basket.success.title', 'Ставка успешно поставлена')}
    >
      <Button to="/bets-history" onClick={onClose}>
        {t('cart.bet-history', 'История ставок')}
      </Button>
    </ResultModal>
  );
};

export default BetSuccessModal;
