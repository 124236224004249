import { Outlet } from 'react-router-dom';
import PageFooter from '@/widgets/page-footer/components/page-footer/page-footer';
import PageHeader from '@/widgets/page-header/components/page-header/page-header';
import NavBar from '@/widgets/layout/components/nav-bar/nav-bar';
import Container from '@/shared/components/container/container';
import SystemWarning from '@/shared/components/system-warning/system-warning';

const LayoutCommon = () => (
  <>
    <PageHeader />
    <Container>
      <SystemWarning />
    </Container>
    <Outlet />
    <PageFooter />
    <NavBar />
  </>
);

export default LayoutCommon;
