import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '@/shared/i18n/i18n';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useWithdrawal } from '../../hooks/useWithdrawal';
import { WithdrawalMethodsItem } from '@shared/store/app/types';
import Button from '@shared/components/button/button';
import { TextFieldControlled } from '@/shared/components/text-field/text-field';
import { getAmountString } from '@shared/utils/helpers/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PaymentMethod from '@/modules/user-wallet/components/payment-method/payment-method';
import { phoneRegExp, getCredentialsInputTitle } from '../../utils/const';
import './withdrawal-form.scss';

type SchemaParams = {
  minAmount: number;
  maxAmount: number;
  currency: string;
  balance: number;
}

const schema = ({ minAmount, maxAmount, currency, balance }: SchemaParams) => yup.object({
  amount: yup
    .number()
    .min(minAmount, `${i18n.t('withdrawals.modal.errors.min-amount', 'Минимальная сумма вывода')} - ${getAmountString(minAmount, currency)}`)
    .max(maxAmount, `${i18n.t('withdrawals.modal.errors.max-amount', 'Максимальная сумма вывода')} - ${getAmountString(maxAmount, currency)}`)
    .test('balanceTest', i18n.t('withdrawals.modal.errors.balance', 'Недостаточно средств'), (value) => (value ? value <= balance : false))
    .required(i18n.t('withdrawals.modal.errors.empty-amount', 'Введите сумму'))
    .typeError(i18n.t('withdrawals.modal.errors.empty-amount', 'Введите сумму')),
  credentials: yup
    .string()
    .required(i18n.t('withdrawals.modal.errors.empty-credentials', 'Введите реквизиты')),
  phone: yup
    .string()
    .matches(phoneRegExp, i18n.t('withdrawals.modal.errors.phone', 'Введите номер телефона'))
    .required(i18n.t('withdrawals.modal.errors.phone', 'Введите номер телефона')),
}).required();

const b = bemCn('withdrawal-form');

type WithdrawalFormProps = {
  method: WithdrawalMethodsItem;
  balance: number;
}

type WithdrawalFormData = {
  amount: number;
  credentials: string;
  phone: string;
}

const WithdrawalFormPhone = ({ method, balance }: WithdrawalFormProps) => {
  const {
    title,
    icons,
    minAmount,
    maxAmount,
    currency,
  } = method;

  const { t } = useTranslation();

  const {
    initWithdrawal,
    isLoading,
    error,
  } = useWithdrawal();

  const { control, handleSubmit, setError, formState: { errors } } = useForm<WithdrawalFormData>({
    defaultValues: {
      amount: 0,
      credentials: '',
      phone: '+',
    },
    mode: 'all',
    resolver: yupResolver(schema({
      minAmount,
      maxAmount,
      currency,
      balance,
    })),
  });

  useEffect(() => {
    if (error?.root) {
      setError('root', { message: error?.root });
    }
    if (error?.withdrawal) {
      setError('amount', { message: error?.withdrawal });
    }
  }, [error?.root, error?.withdrawal, setError]);

  const handleFormSubmit = async (formData: WithdrawalFormData) => {
    const { amount, credentials, phone } = formData;
    if (amount) {
      await initWithdrawal({
        credentials: [credentials, phone.replace('+', '')],
        method: method.name,
        amount,
      });
    }
  };

  return (
    <form className={b()} action="" onSubmit={handleSubmit(handleFormSubmit)}>
      {errors.root && (
        <p className={b('error')}>{errors.root.message}</p>
      )}
      <PaymentMethod className={b('method')}
        title={title}
        icons={icons}
        active
        disabled
      />
      <TextFieldControlled className={b('field')}
        control={control}
        type='text'
        id='credentials'
        name='credentials'
        placeholder={getCredentialsInputTitle(method.name)}
        defaultValue={''}
      />
      <TextFieldControlled className={b('field')}
        control={control}
        type='number'
        id='amount'
        name='amount'
        placeholder={`${t('withdrawals.modal.amount', 'Сумма')}`}
        defaultValue={0}
      />
      <p className={b('desc')}>
        <span className={b('desc-item')}>{t('withdrawals.modal.limit-description', 'Сумма одного депозита')}</span>
        <span className={b('desc-item')}>
          {t('from', 'from')} {getAmountString(minAmount, currency)} {t('to', 'to')} {getAmountString(maxAmount, currency)}
        </span>
      </p>
      <TextFieldControlled className={b('field')}
        control={control}
        type='text'
        id='phone'
        name='phone'
        placeholder={`${t('withdrawals.modal.phone-number', 'Номер телефона')}`}
        defaultValue={'+'}
      />
      <p className={b('desc')}>
        <span className={b('desc-item')}>{t('withdrawals.modal.card-description', 'Введите номер телефона, привязанный к карте')}</span>
      </p>
      <Button className={b('btn')} type='submit' loading={isLoading}>
        {t('withdrawals.modal.submit-btn', 'Вывести')}
      </Button>
    </form>
  );
};

export default WithdrawalFormPhone;
