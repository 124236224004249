import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { baseApi } from '../shared/store/api.ts';

import { rootReducer } from './root-reducer.ts';
import { betapi } from '@/modules/line';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseApi.middleware, betapi.middleware),
});

setupListeners(store.dispatch);


export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
