import { useThemeContext } from '@/modules/theme/model/theme-context';
import Select from '@/shared/components/select/select';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { Trans, useTranslation } from 'react-i18next';
import './theme-change.scss';

const b = bemCn('theme-change');

type ThemeChangeProps = {
  className?: string;
  inverted?: boolean;
}

const ThemeChange = ({ className, inverted }: ThemeChangeProps) => {
  const { t } = useTranslation();
  const {
    theme,
    changeTheme,
    themesList
  } = useThemeContext();

  const options = themesList.map((item) => ({
    label: item,
    value: item,
  }));

  return (
    <div className={b({ inverted }, className)}>
      <h2 className={b('title')}>
        <Trans i18nKey="settings.site-theme">
          Цвет сайта
        </Trans>
      </h2>
      <Select
        placeholder={t('settings.site-theme', 'Цвет сайта')}
        options={options}
        value={theme}
        position='top'
        onChange={(value) => changeTheme(value ?? '')}
        inverted={inverted}
      />
    </div>
  );
};

export default ThemeChange;
