import { bemCn } from '@shared/utils/helpers/bem-cn';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { useTranslation } from 'react-i18next';
import './event-meta-countdown.scss';

type EventMetaCountdownProps = {
  className?: string;
  date: string;
}

const b = bemCn('event-meta-countdown');

const EventMetaCountdown = ({ className, date }: EventMetaCountdownProps) => {
  const { t } = useTranslation();
  const startMinutes = dayjsCustom(date).diff(dayjsCustom(), 'minutes') % 60;
  const startHours = dayjsCustom(date).diff(dayjsCustom(), 'hours') % 24;
  const startDays = dayjsCustom(date).diff(dayjsCustom(), 'days');

  const countdownValue = `${startDays} ${t('event.start-event-days', 'дн.')}
  ${startHours} ${t('event.start-event-hours', 'ч.')}
  ${startMinutes} ${t('event.start-event-minutes', 'мин.')}`;

  return (
    <p className={b(null, className)}>
      <span className={b('label')}>
        {t('event.start-event', 'Начало через')}
      </span>
      <span className={b('value')}>{countdownValue}</span>
    </p>
  );
};

export default EventMetaCountdown;
