import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import '@shared/i18n/i18n';
import App from './app/app';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/app-store';
import ThemeProvider from './modules/theme/components/theme-provider/theme-provider';
import AppLoader from './app/app-loader';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LoaderBig from './shared/components/loader-big/loader-big';
import Mobile from './shared/components/responsive/mobile';
import './index.scss';

const updateWindowDimensions = () => {
  document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('load', updateWindowDimensions);
window.addEventListener('resize', updateWindowDimensions);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <Mobile>
            <Helmet>
              <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no" />
            </Helmet>
          </Mobile>
          <Suspense fallback={<LoaderBig />}>
            <ThemeProvider>
              <AppLoader>
                <App />
              </AppLoader>
            </ThemeProvider>
          </Suspense>
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
