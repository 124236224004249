import { CasinoProvidersSection } from '@/modules/casino';
import PromoSection from '../../widgets/core/components/promo-section/promo-section';
import Container from '@shared/components/container/container';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useTranslation } from 'react-i18next';
import Desktop from '@/shared/components/responsive/desktop';
import { CasinoPromoBanner } from '@/modules/casino';
import SiteSectionsWidget from '@/widgets/site-sections/components/site-sections-widget/site-sections-widget';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import CasinoGamesSectionByCategory from '@/modules/casino/components/casino-games-section-by-category/casino-games-section-by-category';
import './home-screen.scss';

const b = bemCn('home-screen');

const HomeScreen = () => {
  useScrollToTop();
  const { t } = useTranslation();

  return (
    <main className={b()}>
      <Container className={b('promo-container')}>
        <PromoSection className={b('promo')} pageName='home' />
      </Container>

      <SiteSectionsWidget />

      <Desktop>
        <Container>
          <CasinoPromoBanner />
        </Container>
      </Desktop>


      <CasinoGamesSectionByCategory
        category='new'
        title='New'
        bg
      />
      <CasinoGamesSectionByCategory
        category='hot'
        title='Hot'
      />
      <CasinoGamesSectionByCategory
        category='casino'
        title='Casino'
        bg
      />
      <CasinoGamesSectionByCategory
        category='slots'
        title='Slots'
      />
      <CasinoGamesSectionByCategory
        category='crash games'
        title='Crash Games'
        bg
      />
      <CasinoGamesSectionByCategory
        category='tv games'
        title='Live Casino'
      />
      <CasinoGamesSectionByCategory
        category='keno'
        title='Keno'
        bg
      />

      <CasinoProvidersSection
        title={t('casino-widget.providers', 'Провайдеры')}
        link="/games?category=all-providers"
      />
    </main>
  );
};

export default HomeScreen;
