import { bemCn } from '@shared/utils/helpers/bem-cn';
import Basket from '../basket/basket';
import AppModal from '@shared/components/app-modal/app-modal';
import './basket-modal.scss';


type BasketModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const BasketModal = ({ isOpen, onClose }: BasketModalProps) => {
  const b = bemCn('basket-modal');

  return (
    <AppModal className={b()}
      isOpen={isOpen}
      onClose={onClose}
      style="dark"
    >
      <Basket onClose={onClose} theme='dark' />
    </AppModal>
  );
};

export default BasketModal;
