import { createSelector } from '@reduxjs/toolkit';

import notificationsApi from '../api/notifications-api';
import { BaseQueryFn, TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import { Notifications } from './types';

export const getNewNotificationsCount = createSelector(
  notificationsApi.endpoints.getUnreadNotificationsCount.select(),
  ({ data: count = 0 }) => count
);

type GetNotificationsFromResult = TypedUseQueryStateResult<Notifications, unknown, BaseQueryFn>


export const selectNotificationsFromResult = createSelector(
  (res: GetNotificationsFromResult) => res.data?.items ?? [],
  (res: GetNotificationsFromResult) => res.data?.count ?? 0,
  (notifications, count) => ({
    notifications,
    count,
  })
);
