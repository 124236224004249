import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg, { AvailableIcon } from '../icon-svg/icon-svg';
import './gradient-icon.scss';

export type ColorName =
  | 'pink'
  | 'orange'
  | 'light-blue'
  | 'purple'
  | 'green'
  | 'blue'
  | 'yellow'
  | 'dark-blue'
  | 'primary'
  | 'yellow-dark'
  | 'transparent';

export type GradientIconProps = {
  className?: string;
  iconName?: AvailableIcon;
  color?: ColorName;
}

export const GRADIENT_COLORS: ColorName[] = [
  'pink',
  'orange',
  'light-blue',
  'purple',
  'green',
  'blue',
  'yellow',
  // 'dark-blue',
  // 'primary',
];

const b = bemCn('gradient-icon');

const GradientIcon = ({ className, iconName, color, }: GradientIconProps) => (
  <span className={b({ color }, className)}>
    <IconSvg className={b('icon')} name={iconName} />
  </span>
);

export default GradientIcon;
