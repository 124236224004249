import { useEffect } from 'react';
import { useLoginMutation } from '../../model/auth-api';
import LoginForm from '../login-form/login-form';
import AppModal from '@shared/components/app-modal/app-modal';
import { useDispatchedActions } from '../../../../shared/hooks';
import { useTranslation } from 'react-i18next';
import { handleLoginError } from '../../utils/handle-login-error';

type LoginModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}


const LoginModal = ({ isOpen, onClose }: LoginModalProps) => {
  const { t } = useTranslation();
  const [login, { isSuccess, isLoading, loginError }] = useLoginMutation({
    selectFromResult: ({ error, ...result }) => ({
      ...result,
      loginError: handleLoginError(error)
    })
  });
  const { changeAuthModal: chengeAuthModal } = useDispatchedActions();

  const handleRegisterClick = () => chengeAuthModal('registration');

  const handleResetPasswordClick = () => chengeAuthModal('password-reset');

  useEffect(() => {
    if (isSuccess) {
      onClose?.();
    }
  }, [isSuccess]);


  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('auth.modal.title', 'Вход')}
      description={t('auth.modal.description', 'Введите логин и пароль, чтобы войти')}
    >
      <LoginForm
        onFormSubmit={login}
        loading={isLoading}
        error={loginError}
        onRegisterClick={handleRegisterClick}
        onResetPasswordClick={handleResetPasswordClick}
      />
    </AppModal>
  );
};

export default LoginModal;
