import { useGetBetgamesTokenQuery } from '@/modules/casino';
import { getUserCurrency } from '@/modules/user';
import { useAppSelector } from '@/shared/hooks';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { SiteMaintenance, Maintenance } from '@/widgets/site-maintenance';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const clientUrl = 'https://web.twainsports.com';
const apiUrl = 'http://7ds0t9ij.twainsports.com';
const wsUrl = 'https://ws.twainsports.com';

type StartGameParams = {
  containerId?: string;
  clientUrl?: string;
  apiUrl?: string;
  wsUrl?: string;
  partnerCode?: string;
  token?: string;
  timezone?: string;
  locale?: string;
  platformID?: string;
  gameId?: string;
}

const startGame = ({
  containerId = 'betgames-container',
  partnerCode,
  token,
  locale,
  platformID,
  gameId
}: StartGameParams) => {
  // @ts-ignore
  window.TwainSports.setup({
    containerElement: document.getElementById(containerId),
    clientUrl,
    apiUrl,
    wsUrl,
    partnerCode,
    token,
    timezone: '3',
    locale,
    dynamicHeight: '1',
    platformID,
    gameId
  });
};

const loadScript = (onLoad: () => void) => {
  const betgamesScript = document.getElementById('betgames-script');
  if (!betgamesScript) {
    const script = document.createElement('script');

    script.onload = onLoad;
    script.type = 'text/javascript';
    script.id = 'betgames-script';
    script.src = `${clientUrl}/public/ts-loader.js?${Date.now()}`;
    document.head.appendChild(script);
  } else {
    onLoad();
  }
};

const removeScript = () => {
  // @ts-ignore
  if (window.TwainSports) {
    // @ts-ignore
    window.TwainSports = undefined;
  }

  document.getElementById('betgames-script')?.remove();
};

const BetgamesTwainSport = () => {
  const { data: appStatuses } = useGetStatusQuery();
  const isBetgamesActive = appStatuses?.betgames ?? false;
  const { isMobile } = useResponsive();
  const { gameId } = useParams();
  const currency = useAppSelector(getUserCurrency);
  const { data, isSuccess } = useGetBetgamesTokenQuery(
    isBetgamesActive && currency ? { currency } : skipToken
  );

  const partnerCode = data?.partnerCodeTwain;
  const token = data?.token ?? '-';

  useEffect(() => {
    if (isBetgamesActive && data?.token && data.partnerCodeTwain && isSuccess) {
      loadScript(() => startGame({
        containerId: 'betgames-container',
        clientUrl: clientUrl,
        apiUrl: apiUrl,
        wsUrl: wsUrl,
        partnerCode,
        token,
        locale: 'ru',
        platformID: isMobile ? '1' : '0',
        gameId: gameId ?? '24',
      }));
    }

    return removeScript;
  }, [data]);

  if (!isBetgamesActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <div>
      <div id="betgames-container"></div>
    </div>
  );
};

export default BetgamesTwainSport;
