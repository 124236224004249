import { bemCn } from '@shared/utils/helpers/bem-cn';
import {
  getNewNotificationsCount,
} from '@/modules/notifications';
import Dropdown from '@shared/components/dropdown/dropdown';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import NotificationsDropdownContent from './notifications-dropdown-content';
import { useAppSelector } from '@/shared/hooks';

import './notifications-dropdown.scss';

const b = bemCn('notifications-dropdown');

type NotificationsDropdownProps = {
  className?: string;
};

const NotificationsDropdown = ({ className }: NotificationsDropdownProps) => {
  const newNotificationsCount = useAppSelector(getNewNotificationsCount);

  return (
    <Dropdown
      className={b(null)}
      classNameContent={b('content')}
      renderTriger={({ toggel }) => (
        <ButtonIcon className={b('button', className)}
          icon="bell"
          variant="secondary"
          onClick={toggel}
        >
          {!!newNotificationsCount && (
            <span className={b('button-counter')}>{newNotificationsCount}</span>
          )}
        </ButtonIcon>
      )}
      renderContent={({ isOpened }) => {
        const content = isOpened ? <NotificationsDropdownContent /> : null;

        return content;
      }}
    />
  );
};

export default NotificationsDropdown;
