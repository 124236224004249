import { bemCn } from '@shared/utils/helpers/bem-cn';
import Flag from '@shared/components/flag/falg';
import { currencyFlag } from '@/shared/utils/constants/currency';
import './currency-option.scss';

type CurrencyOptionProps = {
  className?: string;
  currency: string;
  label?: string;
}

const b = bemCn('currency-option');

const CurrencyOption = ({
  className,
  currency,
  label
}: CurrencyOptionProps) => {
  const flagname = currencyFlag[currency] ?? '';

  return (
    <div className={b(null, className)}>
      <Flag className={b('icon')}
        countryName={flagname}
      />
      <span className={b('label')}>{label}</span>
    </div>
  );
};

export default CurrencyOption;
