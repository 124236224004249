import { useTranslation } from 'react-i18next';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useCreatePartnerPromocodeMutation, useGetPartnerPromocodeScriptsQuery } from '../../../api/partnerApi';
import AppModal from '@/shared/components/app-modal/app-modal';
import Form from '@/shared/components/form/form';
import Button from '@/shared/components/button/button';
import * as yup from 'yup';
import './create-partner-promocode-modal.scss';
import { useEffect } from 'react';
import { handleCreatePartnerPromocodeError } from '@/modules/partner-program/lib/error-handler';

const b = bemCn('create-partner-promocode-modal');

type FormData = {
  promocode: string;
  scriptId: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object({
  promocode: yup.string().required('Введите название'),
  scriptId: yup.string().required('Выберите бонус'),
});

type CreatePartnerPromocodeModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const CreatePartnerPromocodeModal = ({
  isOpen,
  onClose,
}: CreatePartnerPromocodeModalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const { data: scripts = [] } = useGetPartnerPromocodeScriptsQuery();
  const [createPromocode, { isSuccess, error }] = useCreatePartnerPromocodeMutation();
  const handledError = handleCreatePartnerPromocodeError(error);

  useEffect(() => {
    if (isSuccess) {
      onClose?.();
    }
  }, [isSuccess]);

  const scriptOptions = scripts.map((script) => ({
    label: script.description,
    value: `${script.id}`,
  }));

  const handleFormSubmit = (formData: FormData) => {

    createPromocode({
      name: formData.promocode,
      scriptId: Number(formData.scriptId),
    });
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      className={b()}
      title={t('create-promocode', 'Создать промокод')}
    >
      <Form<FormData>
        className={b('form')}
        onSubmit={handleFormSubmit}
        form={{ schema, error: handledError }}
      >
        <Form.TextField
          className={b('field')}
          name='promocode'
          label={t('title', 'Название')}
          placeholder={t('enter-promocode', 'Введите промокод')}
        />
        <Form.Select
          className={b('field')}
          placeholder={t('select-bonus', 'Выберите бонус')}
          options={scriptOptions}
          name='scriptId'
        />
        <Button type='submit'>
          {t('add-promocode', 'Добавить промокод')}
        </Button>
      </Form>

    </AppModal>
  );
};

export default CreatePartnerPromocodeModal;
