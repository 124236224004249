import i18n from '@/shared/i18n/i18n';
import { CouponType } from '../model/types';
import { ColorName } from '@/shared/components/gradient-icon/gradient-icon';

export const couponTypeLabel: Record<CouponType, string> = {
  ordinar: i18n.t('bet-history.single', 'Ординар'),
  express: i18n.t('bet-history.express', 'Экспресс'),
};

export const couponIconColorByTheme: Record<string, ColorName> = {
  default: 'purple',
  'dark-purple': 'purple',
  'blue': 'yellow-dark',
  'dark-blue': 'dark-blue',
  'light-blue': 'light-blue',
};
