import ResultModal from '@/shared/components/result-modal/result-modal';
import { useTranslation } from 'react-i18next';


type EmailConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const EmailConfirmModal = ({ isOpen, onClose }: EmailConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.confirmation.title', 'Подтверждение')}
      description={t('result-modal.confirmation.description', 'Мы направили вам на почту письмо с подтверждением')}
      status='success'
    />
  );
};

export default EmailConfirmModal;
