export type WebId = string | null | undefined;

export const saveWebId = (webId: WebId) => {
  if (webId) {
    localStorage.setItem('webId', webId);
  }
};

export const getWebId = (): WebId =>
  localStorage.getItem('webId') ?? undefined;

export const dropWebId = () =>
  localStorage.removeItem('webId');
