import { Outlet } from 'react-router-dom';
import { AuthorizationStatus } from '../../shared/utils/constants/common';
import Loader from '../../shared/components/loader/loader';
import ProtectedScreen from '@/pages/protected-screen/protected-screen';
import { useAppSelector, useDispatchedActions } from '@shared/hooks';
import { PropsWithChildren, useEffect } from 'react';
import { getAuthorizationStatus } from '@/modules/auth';

const PrivateRoute = ({ children }: PropsWithChildren) => {
  const authorizationStatus = useAppSelector(getAuthorizationStatus);
  const { changeAuthModal: chengeAuthModal } = useDispatchedActions();
  const isAuth = authorizationStatus === AuthorizationStatus.Auth;
  const isAuthLoading = authorizationStatus === AuthorizationStatus.Unknown;

  useEffect(() => {
    if (!isAuth && !isAuthLoading) {
      chengeAuthModal('login');
    }
  }, [isAuth, isAuthLoading, chengeAuthModal]);


  if (isAuth) {
    if (children) {
      return children;
    }
    return <Outlet />;
  }

  if (isAuthLoading) {
    return <Loader />;
  }

  return (
    <ProtectedScreen />
  );
};

export default PrivateRoute;
