import Breadcrumbs from '@/shared/components/breadcrumbs/breadcrumbs';
import Container from '@/shared/components/container/container';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import {
  TransactionsList,
  TRANSACTIONS_PER_PAGE,
  useGetTransactionsQuery,
} from '@/modules/user';
import type { GetTransactionsParams } from '@/modules/user';
import Pagination from '@/shared/components/pagination/pagination';
import { useState } from 'react';
import i18n from '@/shared/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import './transactions-page.scss';

const b = bemCn('transactions-page');

type ITab = {
  name: string;
  id: GetTransactionsParams['type'] | 'all';
};

const tabs: ITab[] = [
  {
    id: 'all',
    name: i18n.t('transactions.all', 'Все'),
  },
  {
    id: 'deposits',
    name: i18n.t('transactions.deposits', 'Пополнения'),
  },
  {
    id: 'withdrawals',
    name: i18n.t('transactions.withdrawal', 'Вывод'),
  },
  {
    id: 'bets',
    name: i18n.t('transactions.bets', 'Ставки'),
  },
  {
    id: 'transfers',
    name: i18n.t('transactions.transfer', 'Переводы'),
  },
];

const initParams: GetTransactionsParams = {
  page: 1,
  pageSize: TRANSACTIONS_PER_PAGE,
  type: undefined,
};

const TransactionsPage = () => {
  useScrollToTop();
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);

  const { data: transactions, isFetching } = useGetTransactionsQuery(params);
  const items = transactions?.items ?? [];
  const itemsCount = transactions?.count ?? 0;

  const changeParams = (newParams: Partial<GetTransactionsParams>) => {
    setParams((prev) => ({
      ...prev,
      ...newParams,
    }));
  };

  const changeTab = (newTab: ITab['id']) => {
    setParams((prev) => ({
      ...prev,
      page: 1,
      type: newTab !== 'all' ? newTab : undefined,
    }));
  };

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs />
        <h1 className={b('title')}>
          {t('transactions.title', 'Детализация')}
        </h1>
        <div className={b('tabs')}>
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={b('tab-item', {
                active: tab.id === params.type
                  || (tab.id === 'all' && !params.type)
              })}
              type='button'
              onClick={() => changeTab(tab.id)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <TransactionsList
          className={b('list')}
          transactions={items}
          loading={isFetching}
        />
        <Pagination
          page={params.page}
          pageSize={TRANSACTIONS_PER_PAGE}
          totalItems={itemsCount}
          onChange={(page) => changeParams({ page })}
        />
      </Container>
    </div>
  );
};

export default TransactionsPage;
