import { useOnClickOutside, useToggle } from 'usehooks-ts';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useEffect, useRef } from 'react';
import './casino-filter-sort-mobile-dropdown.scss';

type RenderParams = {
  toggel: () => void;
  open: () => void;
  close: () => void;
}

type DropdownProps = {
  className?: string;
  classNameContent?: string;
  renderTriger: (params: RenderParams) => JSX.Element;
  renderContent: (params: RenderParams) => JSX.Element;
  open?: boolean;
}

const CasinoFilterSortMobileDropdown = ({
  className,
  classNameContent,
  renderTriger,
  renderContent,
  open = false,
}: DropdownProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [opened, toggleOpened, setOpened] = useToggle(open);

  useOnClickOutside(ref, () => setOpened(false));

  useEffect(() => {
    if (open !== opened) {
      setOpened(open);
    }
  }, [open]);

  // TODO: Добавить закрытие попапа по ESC

  const b = bemCn('casino-filter-sort-mobile-dropdown');

  return (
    <>
      <div className={b({ opened }, className)} ref={ref}>
        {renderTriger({
          toggel: () => toggleOpened(),
          open: () => setOpened(true),
          close: () => setOpened(false),
        })}

        <div className={b('content', classNameContent)}>
          {renderContent({
            toggel: () => toggleOpened(),
            open: () => setOpened(true),
            close: () => setOpened(false),
          })}
        </div>
      </div>
      <div className={b('overlay')}></div>
    </>

  );
};

export default CasinoFilterSortMobileDropdown;
