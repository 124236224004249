import { CasinoGame } from '../model/types';


export const saveCasinoGames = (key: string, games: CasinoGame[]) => {
  localStorage.setItem(key, JSON.stringify(games));

};

export const getStoredCasinoGames = (key: string): CasinoGame[] | null => {
  const value = localStorage.getItem(key);

  if (value) {
    return JSON.parse(value) as CasinoGame[];
  }

  return null;
};


export const dropStoredCasinoGames = (key: string) =>
  localStorage.removeItem(key);
