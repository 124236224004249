import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './partner-players-list.scss';
import PartnerPlayerCard from '../partner-player-card/partner-player-card';
import { PlayerStats } from '@/modules/partner-program/model/types';

const b = bemCn('partner-players-list');

type PartnerPlayersListProps = {
  players: PlayerStats[];
}

const PartnerPlayersList = ({ players }: PartnerPlayersListProps) => (
  <div className={b()}>
    {players.map((player) => (
      <PartnerPlayerCard key={player.playerId} player={player} />
    ))}
  </div>
);

export default PartnerPlayersList;
