import { bemCn } from '@/shared/utils/helpers/bem-cn';
import Button from '@/shared/components/button/button';
import cardBgLine from './assets/site-section-card-1.jpg';
import cardBgLive from './assets/site-section-card-2.jpg';
import cardBgStream from './assets/site-section-card-3.jpg';
import cardBgResult from './assets/site-section-card-4.jpg';
import { FC } from 'react';
import { PropsWithCN } from '@/shared/types/common';
import { useTranslation } from 'react-i18next';
import './site-section-card.scss';

type SiteSectionCardProps = {
  className?: string;
  title: string;
  desc?: string;
  btn: string;
  link?: string;
  img?: string;
}

const b = bemCn('site-section-card');

const SiteSectionCard: FC<SiteSectionCardProps> = ({
  className,
  title,
  desc,
  btn,
  link,
  img = cardBgLine,
}) => (
  <div className={b(null, className)}>
    <img className={b('bg-image')} src={img} alt='' />
    <div className={b('wrapper')}>
      <h3 className={b('title')}>{title}</h3>
      <p className={b('desc')}>{desc}</p>
      <Button className={b('btn')} to={link}>
        {btn}
      </Button>
    </div>
  </div>
);

const SiteSectionCardLine = (props: PropsWithCN) => {
  const { t } = useTranslation();
  return (
    <SiteSectionCard
      {...props}
      title={t('site-sections.line.title')}
      desc={t('site-sections.line.description')}
      btn={t('site-sections.line.btn')}
      link='/line'
      img={cardBgLine}
    />
  );
};

const SiteSectionCardLive = (props: PropsWithCN) => {
  const { t } = useTranslation();
  return (
    <SiteSectionCard
      {...props}
      title={t('site-sections.live.title')}
      desc={t('site-sections.live.description')}
      btn={t('site-sections.live.btn')}
      link='/live'
      img={cardBgLive}
    />
  );
};

const SiteSectionCardStream = (props: PropsWithCN) => {
  const { t } = useTranslation();
  return (
    <SiteSectionCard
      {...props}
      title={t('site-sections.streams.title')}
      desc={t('site-sections.streams.description')}
      btn={t('site-sections.streams.btn')}
      link='/streams'
      img={cardBgStream}
    />
  );
};

const SiteSectionCardResult = (props: PropsWithCN) => {
  const { t } = useTranslation();
  return (
    <SiteSectionCard
      {...props}
      title={t('site-sections.results.title')}
      desc={t('site-sections.results.description')}
      btn={t('site-sections.results.btn')}
      link='/results'
      img={cardBgResult}
    />
  );
};

export {
  SiteSectionCardLine,
  SiteSectionCardLive,
  SiteSectionCardStream,
  SiteSectionCardResult
};

export default SiteSectionCard;
