import { useActivatePromocodeMutation } from '@/modules/user/store/user-api';
import PromocodeActivateForm, { PromocodeActivateFormProps } from './promocode-activate-form';
import { handlePromocodeError } from '../../utils/handle-promocode-error';

type Props = Omit<PromocodeActivateFormProps, 'onSubmit' | 'loading' | 'error'>

const PromocodeActivateFormConnected = (props: Props) => {
  const [activate, { isLoading, error }] = useActivatePromocodeMutation();

  return (
    <PromocodeActivateForm
      {...props}
      error={handlePromocodeError(error)}
      onSubmit={({ promocode }) => activate(promocode)}
      loading={isLoading}
    />
  );
};

export default PromocodeActivateFormConnected;
