import { ChangeEvent, FocusEventHandler } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '../icon-svg/icon-svg';
import withController from '../../hocs/with-controller';
import './checkbox.scss';

export type CheckboxProps = {
  className?: string;
  id?: string;
  name?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  label?: React.ReactNode;
  inverted?: boolean;
  value?: string;
  disabled?: boolean;
  status?: 'error' | 'success';
  error?: string;
}

const b = bemCn('checkbox');

const Checkbox = (props: CheckboxProps) => {
  const {
    className,
    id,
    name,
    checked,
    onChange = () => { },
    label,
    inverted,
    value,
    disabled,
    onBlur,
    status,
    error
  } = props;

  const isError = status === 'error';

  return (
    <label className={b({ inverted, status }, className)} htmlFor={id}>
      <input className='visually-hidden'
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={(e) => onChange(e, e.target.checked)}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
      />
      <span className={b('indicator')}>
        <IconSvg className={b('icon')}
          name='check'
        />
      </span>
      {label && (
        <span className={b('label-wrapper')}>
          <span className={b('label')}>{label}</span>
          {isError && error && <span className={b('error')}>{error}</span>}
        </span>
      )}
    </label>
  );

};

export const CheckboxControlled = withController(Checkbox);

export default Checkbox;
