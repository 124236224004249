import { bemCn } from '@shared/utils/helpers/bem-cn';
import SportEvents from '../sport-events/sport-events';
import Loader from '@/shared/components/loader/loader';
import { memo, useMemo } from 'react';
import './sport-events-list.scss';
import { EventsBySport } from '../../model/selectors';

const MemoizedSportEvents = memo(SportEvents);

const b = bemCn('sport-events-list');

type SportEventsListProps = {
  className?: string;
  events: EventsBySport[];
  loading?: boolean;
}

const SportEventsList = ({ className, events, loading }: SportEventsListProps) => {
  const memoizedSports = useMemo(() => events.map((item) => (
    <MemoizedSportEvents
      key={item.id}
      sportName={item.name}
      eventsByTournament={item.tournaments}
    />
  )), [events]);

  return (
    <div className={b(null, className)}>
      {loading && <Loader className={b('loader')} />}
      {memoizedSports}
    </div>
  );
};

export default SportEventsList;
