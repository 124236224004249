import { useCallback, useState } from 'react';
import { CouponType } from '../model/types';

export type CouponsFilter = {
  page: number;
  type?: CouponType;
  pageSize: number;
}

const COUPONS_PER_PAGE = 15;

const initFilter: CouponsFilter = {
  page: 1,
  pageSize: COUPONS_PER_PAGE,
  type: undefined
};

export const useCouponsFilter = (initial?: CouponsFilter) => {
  const [filter, setFilter] = useState<CouponsFilter>(initial ?? initFilter);

  const changeFilter = useCallback((newFilter: Partial<CouponsFilter>) => {
    setFilter((prev) => ({
      ...prev,
      ...newFilter,
    }));
  }, []);

  const resetFilter = useCallback(() => {
    setFilter(initial ?? initFilter);
  }, [initial]);

  return {
    filter,
    initialFilter: initial ?? initFilter,
    changeFilter,
    resetFilter,
    COUPONS_PER_PAGE,
  };
};
