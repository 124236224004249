import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getTimezoneNumber } from './timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

// TODO: Перенести в shared так как используется и в modules и в widgets
export const dayjsCustom = (date?: dayjs.ConfigType, tz?: number) =>
  dayjs(date).utcOffset(tz ?? getTimezoneNumber());
