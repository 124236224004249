import { bemCn } from '@shared/utils/helpers/bem-cn';
import AppModal from '@shared/components/app-modal/app-modal';
import { useDispatchedActions } from '@shared/hooks';
import Tabs from '@/shared/components/tabs/tabs';
import './register-modal.scss';
import RegisterFormByEmail from '../register-form/register-form-by-email';
// import RegisterFormByTelegram from '../register-form/register-form-by-telegram';
import {
  useRegisterByEmailMutation,
  // useRegisterByTelegramMutation
} from '@/modules/auth';
// import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  handleRegisterErrorEmail,
  // handleRegisterErrorTelegram
} from '../../utils/handle-register-error';

type RegisterModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const b = bemCn('register-modal');


// TODO: Надо сделать выбор регистраций по фитчафлагу!!!

const RegisterModal = ({ isOpen, onClose }: RegisterModalProps) => {
  const { t } = useTranslation();
  const { changeAuthModal: chengeAuthModal, /* closeAuthModal */ } = useDispatchedActions();
  const [registerByEmail, {
    isLoading: loadingByEmail,
    error: errorByEmail,
  }] = useRegisterByEmailMutation();
  // const [registerByTelegram, {
  //   isLoading: loadingByTelegram,
  //   isSuccess: successByTelegram,
  //   error: errorByTelegram,
  // }] = useRegisterByTelegramMutation();
  const handleLoginClick = () => chengeAuthModal('login');

  // useEffect(() => {
  //   if (successByTelegram) {
  //     closeAuthModal();
  //   }
  // }, [successByTelegram, closeAuthModal]);

  return (
    <AppModal className={b()}
      isOpen={isOpen}
      onClose={onClose}
      title={t('reg.modal.title', 'Регистрация')}
      description={t('reg.modal.description', 'Выберите удобный способ регистрации')}
    >
      <Tabs classNameBar={b('tabs')}>
        <Tabs.Tab title='Email'>
          <RegisterFormByEmail
            onFormSubmit={(formData) => registerByEmail(formData)}
            loading={loadingByEmail}
            error={handleRegisterErrorEmail(errorByEmail)}
            onLoginClick={handleLoginClick}
          />
        </Tabs.Tab>
        {/* <Tabs.Tab title='Telegram'>
          <RegisterFormByTelegram
            onFormSubmit={(formData) => registerByTelegram(formData)}
            loading={loadingByTelegram}
            error={handleRegisterErrorTelegram(errorByTelegram)}
            onLoginClick={handleLoginClick}
          />
        </Tabs.Tab> */}
      </Tabs>

    </AppModal>
  );
};

export default RegisterModal;
