import { bemCn } from '@/shared/utils/helpers/bem-cn';
import Form from '@/shared/components/form/form';
import Button from '@/shared/components/button/button';
import {
  useChangePasswordMutation,
  type ChangePasswordParams,
} from '@/modules/auth';
import * as yup from 'yup';
import { UseFormReset } from 'react-hook-form';
import './password-change.scss';
import { useTranslation } from 'react-i18next';
import i18n from '@/shared/i18n/i18n';

type FormData = ChangePasswordParams;

const schema: yup.ObjectSchema<FormData> = yup
  .object({
    oldPassword: yup.string()
      .trim()
      .min(3, i18n.t('reset-pass.errors.short-pass', 'Слишком короткий пароль'))
      .required(i18n.t('reset-pass.errors.empty-pass', 'Введите пароль')),
    newPassword: yup.string()
      .trim()
      .min(3, i18n.t('reset-pass.errors.short-pass', 'Слишком короткий пароль'))
      .required(i18n.t('reset-pass.errors.empty-pass', 'Введите пароль')),
  })
  .required();

const defaultValues: FormData = {
  oldPassword: '',
  newPassword: '',
};

const b = bemCn('password-change');

type PasswordChangeProps = {
  className?: string;
  inverted?: boolean;
}

const PasswordChange = ({ className, inverted }: PasswordChangeProps) => {
  const { t } = useTranslation();
  const [changePassword, { isLoading }] =
    useChangePasswordMutation();


  const handleSubmit = (
    formData: FormData,
    _?: unknown,
    reset?: UseFormReset<FormData>
  ) => {
    changePassword(formData)
      .unwrap()
      .then(() => reset?.())
      .catch(() => { });
  };

  return (
    <Form<FormData> className={b({ inverted }, className)}
      onSubmit={handleSubmit}
      form={{
        schema,
        disabled: isLoading,
        defaultValues,
      }}
    >
      <h2 className={b('title')}>
        {t('change-password.title', 'Изменение пароля')}
      </h2>
      <Form.TextField className={b('field')}
        type='password'
        name="oldPassword"
        placeholder={t('change-password.current-pass', 'Введите текущий пароль')}
        inverted={inverted}
      />
      <Form.TextField className={b('field')}
        type='password'
        name="newPassword"
        placeholder={t('change-password.new-pass', 'Введите новый пароль')}
        inverted={inverted}
      />
      <Button className={b('btn')}
        type='submit'
        variant="bordered"
        loading={isLoading}
      >
        {t('change-password.submit-btn', 'Изменить пароль')}
      </Button>
    </Form>
  );
};

export default PasswordChange;
