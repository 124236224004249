import { ComponentType } from 'react';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';


const withController = <P extends object>(Component: ComponentType<P>) => {


  const WithController = <C extends FieldValues>(props: P & UseControllerProps<C>) => {
    const { field, fieldState } = useController(props);

    return (
      <Component
        {...props as P}
        name={field.name}
        value={typeof field.value === 'string' ? field.value : undefined}
        checked={typeof field.value === 'boolean' ? field.value : undefined}
        onChange={field.onChange}
        onBlur={field.onBlur}
        status={fieldState.invalid ? 'error' : undefined}
        error={fieldState.error?.message}
        disabled={field.disabled || props.disabled}
      />
    );
  };

  return WithController;
};

export default withController;
