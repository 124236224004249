import { FC, useEffect, useMemo, useState } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '../icon-svg/icon-svg';
import { useDebounce } from '../../hooks/use-debounce';
import './pagination.scss';

type PaginationProps = {
  className?: string;
  onChange?: (page: number) => void;
  page?: number;
  totalItems?: number;
  pageSize?: number;
};

const DOTS = '...';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const getPageRange = (totalPages: number, currentPage: number) => {
  const firstPage = 1;
  const lastPage = totalPages;

  if (totalPages <= 5) {
    return range(firstPage, lastPage);
  }

  if (currentPage <= 2) {
    return [...range(1, 3), DOTS, lastPage];
  }

  if (currentPage >= lastPage - 1) {
    return [firstPage, DOTS, ...range(lastPage - 2, lastPage)];
  }


  return [firstPage, DOTS, currentPage, DOTS, lastPage];
};

const b = bemCn('pagination');

// TODO: Сделать оптимизацию, првоерить повторный рендеринг и т.п.

const Pagination: FC<PaginationProps> = ({
  className,
  page = 1,
  pageSize = 1,
  totalItems = 1,
  onChange = () => undefined,
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const [inputValue, setInputValue] = useState<number | ''>('');
  const debouncedInputValue = useDebounce(inputValue, 800);
  const totalPageCount = Math.ceil(totalItems / pageSize);
  const paginationRange = useMemo(
    () => getPageRange(totalPageCount, currentPage),
    [totalPageCount, currentPage]
  );
  const lastPage = paginationRange[paginationRange.length - 1];

  useEffect(() => {
    if (currentPage !== page) {
      onChange(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [page]);

  useEffect(() => {
    if (debouncedInputValue && debouncedInputValue <= totalPageCount && debouncedInputValue >= 1) {
      setCurrentPage(debouncedInputValue);
      setInputValue('');
    }
  }, [debouncedInputValue]);


  if (totalPageCount <= 1) {
    return null;
  }

  const handlePrevClick = () =>
    setCurrentPage(currentPage - 1);

  const handleNextClick = () =>
    setCurrentPage(currentPage + 1);

  const handlePageChange = (newPage: number) =>
    setCurrentPage(newPage);

  const handleInputChange = (val: number) => {
    setInputValue(val);
  };

  return (
    <div className={b(null, className)}>
      <button className={b('btn', { prev: true })}
        type='button'
        onClick={handlePrevClick}
        disabled={currentPage === 1}
      >
        <IconSvg className={b('btn-icon')} name="arrow-right" />
      </button>

      {paginationRange.map((pageNumber, i) => {
        const keyVal = `${pageNumber}-${i}`;

        if (pageNumber === DOTS) {
          return (
            <input className={b('btn', { input: true })}
              key={keyVal}
              type='number'
              placeholder={DOTS}
              value={inputValue}
              onChange={(e) => handleInputChange(Number(e.target.value))}
            />
          );
        }

        return (
          <button className={b('btn', { active: pageNumber === currentPage })}
            key={keyVal}
            type='button'
            disabled={pageNumber === DOTS || pageNumber === currentPage}
            onClick={pageNumber !== DOTS
              ? () => handlePageChange(Number(pageNumber))
              : undefined}
          >
            {pageNumber}
          </button>
        );
      })}

      <button className={b('btn', { next: true })}
        type='button'
        onClick={handleNextClick}
        disabled={lastPage === currentPage}
      >
        <IconSvg className={b('btn-icon')} name="arrow-right" />
      </button>
    </div>
  );
};


export default Pagination;
