import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useTranslation } from 'react-i18next';
import { PlayerStats } from '../../../model/types';
import { PPToggledCard } from '../../core/pp-togled-card/pp-togled-card';
import './partner-player-card.scss';

const b = bemCn('partner-player-card');

type PartnerPlayerCardProps = {
  player: PlayerStats;
}

const PartnerPlayerCard = ({ player: stats }: PartnerPlayerCardProps) => {
  const {
    income,
    playerId,
    betsIncome,
    inbetIncome,
    betgamesIncome,
    vegasIncome,
    deposits,
    adminDeposits,
    playerName
  } = stats;

  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  return (
    <PPToggledCard className={b()}
      title={`ID: ${playerId}`}
      desc={`${playerName}`}
    >
      <div className={b('list')}>
        <div className={b('item')}>
          <div className={b('item-label')}>{t('deposits', 'Депозиты')}</div>
          <div className={b('item-value')}>
            {Number(deposits) + Number(adminDeposits)}
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item-label')}>{t('bet-income', 'Прибыль ставки')}</div>
          <div className={b('item-value')}>
            {betsIncome}
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item-label')}>{t('casino-income', 'Прибыль Казино')}</div>
          <div className={b('item-value')}>
            {Number(inbetIncome) + Number(betgamesIncome) + Number(vegasIncome)}
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item-label')}>{t('income', 'Доход компании')}</div>
          <div className={b('item-value')}>{income}</div>
        </div>
      </div>
    </PPToggledCard>
  );
};

export default PartnerPlayerCard;
