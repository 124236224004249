import { useCreateSmartsoftSessionQuery } from '@/modules/casino';
import GameScreen from '../game-screen';
import { useParams } from 'react-router-dom';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { SiteMaintenance, Maintenance } from '@/widgets/site-maintenance';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '@/app-config';


const SmartSoft = () => {
  const { t, i18n } = useTranslation();
  const { data: appStatuses } = useGetStatusQuery();
  const isSmartsoftActive = appStatuses?.smartsoft ?? false;
  const { gameCategory = '', gameName = '' } = useParams();

  const { data: sessionUrl, isLoading, isError } = useCreateSmartsoftSessionQuery({
    lang: i18n.resolvedLanguage ?? DEFAULT_LANG,
    gameCategory: gameCategory,
    gameName: gameName,
    returnUrl: `${window.location.origin}/games?provider=SmartSoft&category=all-providers`
  }, { skip: !isSmartsoftActive });

  if (!isSmartsoftActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <GameScreen
      title="Smartsoft"
      src={sessionUrl}
      loading={isLoading}
      isError={isError}
      errorMessage={t('common.unknown-error', 'Неизвестная ошибка')}
    />
  );
};

export default SmartSoft;
