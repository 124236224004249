import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { PPNavigationItem } from '../pp-navigation';
import { NavLink } from 'react-router-dom';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { useToggle } from 'usehooks-ts';
import './partner-navigation-mobile.scss';


const b = bemCn('partner-navigation-mobile');

type PartnerNavigationMobileProps = {
  items: PPNavigationItem[];
}

const PartnerNavigationMobile = ({ items }: PartnerNavigationMobileProps) => {
  const [opened, toggleOpened, setOpened] = useToggle(false);

  const activeItem = items.find((item) => item.link === window.location?.pathname);


  return (
    <div className={b()}>
      {activeItem && (
        <div className={b('selected-item')} onClick={toggleOpened}>
          <IconSvg className={b('selected-item-icon')} name={activeItem.icon} />
          <div className={b('selected-item-name')}>{activeItem.name}</div>
        </div>
      )}
      {opened && (
        <div className={b('items-list')}>
          {items.map((item) => (
            <NavLink className={b('item')}
              key={item.id}
              to={item.link}
              onClick={() => setOpened(false)}
            >
              <IconSvg className={b('item-icon')} name={item.icon} />
              <span className={b('item-name')}>{item.name}</span>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default PartnerNavigationMobile;
