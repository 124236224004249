import { useState, useEffect } from 'react';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import ReactModal from 'react-modal';
import { existVerticalScroll, getBodyScrollTop } from '@/shared/utils/helpers/common';
import ButtonIcon from '@/shared/components/button-icon/button-icon';
import './drawer.scss';
import { PropsWithCN } from '@/shared/types/common';

const b = bemCn('drawer');

type DrawerProps = PropsWithCN & {
  isOpen: boolean;
  children?: React.ReactNode;
  title?: string;
  onClose?: () => void;
  contentLabel?: string;
  id?: string;
}

const Drawer = ({
  className,
  isOpen,
  children,
  title,
  onClose,
  contentLabel,
  id,
}: DrawerProps) => {
  const [scrollOffset, setScrollOffset] = useState<number | null>(null);

  useEffect(() => {
    if (isOpen) {
      if (existVerticalScroll()) {
        setScrollOffset(getBodyScrollTop());
        document.body.style.top = `-${getBodyScrollTop()}px`;
        document.body.classList.add('drawer-body-scroll-fixed');
      }
    } else if (scrollOffset !== null) {
      document.body.style.top = '';
      document.body.classList.remove('drawer-body-scroll-fixed');
      window.scrollTo(0, scrollOffset);
      setScrollOffset(null);
    }
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      portalClassName={b()}
      className={b('content', className)}
      overlayClassName={b('overlay', { 'fade-in': isOpen, 'fade-out': !isOpen })}
      onRequestClose={onClose}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}

      contentLabel={contentLabel}
      id={id}
    >
      <ButtonIcon className={b('close-btn')} icon='close' variant='text' onClick={onClose} />
      {title && (
        <h2 className={b('title')}>{title}</h2>
      )}
      {children}
    </ReactModal>
  );
};

export default Drawer;
