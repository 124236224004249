import { useTranslation } from 'react-i18next';
import PPCard from '../../core/pp-card/pp-card';
import { PartnerLink } from '@/modules/partner-program/model/types';
import PartnerLinkStatsBtn from '../partner-link-stats-btn/partner-link-stats-btn';
import ManagerUserLinkStatsBtn from '../manager-user-link-stats-btn/manager-user-link-stats-btn';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './partner-link-card.scss';

const b = bemCn('partner-link-card');

type PartnerLinkCardProps = {
  link: PartnerLink;
  role: 'partner' | 'manager';
}

const PartnerLinkCard = ({ link, role }: PartnerLinkCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  const getStatsBtns = (): React.ReactNode => {
    if (role === 'partner') {
      return (
        <PartnerLinkStatsBtn
          className={b('controls-btn')}
          icon='pie-chart'
          variant='bordered'
          link={link}
        />
      );
    } else if (role === 'manager') {
      return (
        <ManagerUserLinkStatsBtn
          className={b('controls-btn')}
          icon='pie-chart'
          variant='bordered'
          link={link}
        />
      );
    }
  };

  const items = [
    {
      label: t('for-project', 'Для проекта'),
      value: link.projectName
    },
    {
      label: t('your-link', 'Ваша ссылка'),
      value: link.link
    },
    {
      label: 'created',
      value: dayjsCustom(link.created).format('DD.MM.YYYY')
    },
  ];

  return (
    <PPCard className={b()}
      items={items}
      contorls={getStatsBtns()}
    />
  );
};

export default PartnerLinkCard;
