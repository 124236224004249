import Form from '@/shared/components/form/form';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useState } from 'react';
import './register-promocode.scss';
import { useTranslation } from 'react-i18next';

type RegisterPromocodeProps = {
  className?: string;
}

const RegisterPromocode = ({ className }: RegisterPromocodeProps) => {
  const { t } = useTranslation();
  const b = bemCn('register-promocode');
  const [opened, setOpened] = useState(false);

  const showFiled = () => setOpened(true);
  const hideFiled = () => setOpened(false);

  return (
    <div className={b(null, className)}>
      {opened ? (
        <Form.TextField
          className={b('field')}
          type='text'
          name="promocode"
          placeholder={t('reg.modal.promo.code', 'Промокод')}
          clearable
          onClear={hideFiled}
        />
      ) : (
        <button className={b('show-btn')} type='button' onClick={showFiled}>
          {t('reg.modal.promo.title', 'Добавить промокод')}
          <IconSvg className={b('show-btn-icon')} name='plus' />
        </button>
      )}
    </div>
  );
};

export default RegisterPromocode;
