import { baseApi } from '@/shared/store/api';
import { CreatePartnerLink, CreatePartnerProject, CreatePartnerPromocode, GetPartnerLinkPlayers, GetPartnerLinks, GetPartnerLinkStats, GetPartnerPlayers, GetPartnerProjectPlayers, GetPartnerProjectStats, GetPartnerPromocodePlayers, GetPartnerPromocodes, GetPartnerPromocodeStats, GetPartnerStats, GetPratnerProjects, PartnerLinks, PartnerLinksServer, PartnerPlayers, PartnerPlayersServer, PartnerProjects, PartnerPromocodes, PartnerPromocodesServer, PartnerStats, PromocodeScript, PromocodeScriptServer } from '../model/types';
import { adapteLink, adaptePartnerStats, adaptePlayerStats, adaptePromocode, adaptePromocodeScript } from '../lib/adapter';

const partnerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPartnerProjects: build.query<PartnerProjects, GetPratnerProjects>({
      query: (params) => ({
        url: '/v1/partner/projects',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      providesTags: ['partner-projects'],
    }),

    getPartnerProjectStats: build.query<PartnerStats, GetPartnerProjectStats>({
      query: ({ projectId, ...params }) => ({
        url: `/v1/partner/projects/${projectId}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        },
      }),
      transformResponse: adaptePartnerStats,
    }),

    getPartnerProjectPlayers: build.query<PartnerPlayers, GetPartnerProjectPlayers>({
      query: ({ projectId, ...params }) => ({
        url: `/v1/partner/projects/${projectId}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(adaptePlayerStats),
      })
    }),

    getPartnerLinks: build.query<PartnerLinks, GetPartnerLinks>({
      query: (params) => ({
        url: '/v1/partner/links',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      providesTags: ['partner-links'],
      transformResponse: (res: PartnerLinksServer) => ({
        count: res.count,
        items: res.items.map(adapteLink),
      })
    }),

    getPartnerLinkStats: build.query<PartnerStats, GetPartnerLinkStats>({
      query: ({ refCode, ...params }) => ({
        url: `/v1/partner/links/${refCode}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: adaptePartnerStats,
    }),

    getPartnerLinkPlayers: build.query<PartnerPlayers, GetPartnerLinkPlayers>({
      query: ({ refCode, ...params }) => ({
        url: `/v1/partner/links/${refCode}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(adaptePlayerStats),
      })
    }),

    getPartnerPromocodeScripts: build.query<PromocodeScript[], void>({
      query: () => '/v1/partner/promocode_scripts',
      transformResponse: (res: PromocodeScriptServer[]) =>
        res.map(adaptePromocodeScript),
    }),

    getPartnerPromocodes: build.query<PartnerPromocodes, GetPartnerPromocodes>({
      query: (params) => ({
        url: '/v1/partner/promocodes',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      providesTags: ['partner-promocodes'],
      transformResponse: (res: PartnerPromocodesServer) => ({
        count: res.count,
        items: res.items.map(adaptePromocode),
      })
    }),

    getPartnerPromocodeStats: build.query<PartnerStats, GetPartnerPromocodeStats>({
      query: ({ promocodeId, ...params }) => ({
        url: `/v1/partner/promocodes/${promocodeId}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: adaptePartnerStats,
    }),

    getPartnerPromocodePlayers: build.query<PartnerPlayers, GetPartnerPromocodePlayers>({
      query: ({ promocodeId, ...params }) => ({
        url: `/v1/partner/promocodes/${promocodeId}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(adaptePlayerStats),
      })
    }),

    getPartnerStats: build.query<PartnerStats, GetPartnerStats>({
      query: (params) => ({
        url: '/v1/partner/stats',
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: adaptePartnerStats,
    }),

    getPartnerPlayers: build.query<PartnerPlayers, GetPartnerPlayers>({
      query: (params) => ({
        url: '/v1/partner/players',
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(adaptePlayerStats),
      })
    }),

    createPartnerProject: build.mutation<unknown, CreatePartnerProject>({
      query: (params) => ({
        url: '/v1/partner/projects',
        method: 'POST',
        params: {
          name: params.name,
        }
      }),
      invalidatesTags: ['partner-projects'],
    }),

    createPartnerLink: build.mutation<unknown, CreatePartnerLink>({
      query: (params) => ({
        url: '/v1/partner/links',
        method: 'POST',
        params: {
          'project_id': params.projectId,
        }
      }),
      invalidatesTags: ['partner-links'],
    }),

    createPartnerPromocode: build.mutation<unknown, CreatePartnerPromocode>({
      query: (params) => ({
        url: '/v1/partner/promocodes',
        method: 'POST',
        params: {
          'script_id': params.scriptId,
          'name': params.name,
        }
      }),
      invalidatesTags: ['partner-promocodes'],
    }),
  })
});

export const {
  useGetPartnerProjectsQuery,
  useGetPartnerProjectStatsQuery,
  useGetPartnerProjectPlayersQuery,

  useGetPartnerLinksQuery,
  useGetPartnerLinkStatsQuery,
  useGetPartnerLinkPlayersQuery,

  useGetPartnerPromocodesQuery,
  useGetPartnerPromocodeStatsQuery,
  useGetPartnerPromocodePlayersQuery,

  useGetPartnerStatsQuery,
  useGetPartnerPlayersQuery,

  useGetPartnerPromocodeScriptsQuery,

  useCreatePartnerProjectMutation,
  useCreatePartnerLinkMutation,
  useCreatePartnerPromocodeMutation,
} = partnerApi;

export { partnerApi };
