import { bemCn } from '@shared/utils/helpers/bem-cn';
import { NavLink } from 'react-router-dom';
import IconSvg, { AvailableIcon } from '@/shared/components/icon-svg/icon-svg';
import './pp-navigation.scss';

export type PPNavigationItem = {
  id: string;
  name: string;
  icon?: AvailableIcon;
  link: string;
}

type PPNavigationProps = {
  items: PPNavigationItem[];
}

const PPNavigation = ({ items }: PPNavigationProps) => {
  const b = bemCn('pp-navigation');

  return (
    <div className={b()}>
      {items.map((item) => (
        <NavLink
          className={({ isActive }) => b('item', { active: isActive })}
          key={item.id}
          to={item.link}
        >
          <IconSvg className={b('item-icon')} name={item.icon} />
          <span className={b('item-name')}>{item.name}</span>
        </NavLink>
      ))}
    </div>
  );
};

export { PPNavigation };
