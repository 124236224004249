import Loader from '../loader/loader';
import { bemCn } from '@/shared/utils/helpers/bem-cn';

import './loader-center.scss';

const b = bemCn('loader-center');

type LoaderProps = {
  className?: string;
}

const LoaderCenter = ({ className }: LoaderProps) => (
  <div className={b(null, className)}>
    <Loader />
  </div>
);

export default LoaderCenter;
