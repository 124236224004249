import { bemCn } from '@/shared/utils/helpers/bem-cn';
import SubpartnerCard from '../subpartner-card/subpartner-card';
import './subpartners-list.scss';
import { Subpartner } from '@/modules/partner-program/model/types';

const b = bemCn('subpartners-list');

type SubpartnerListProps = {
  subpartners: Subpartner[];
}

const SubpartnerList = ({ subpartners }: SubpartnerListProps) => (
  <div className={b()}>
    {subpartners.map((subpartner) => (
      <SubpartnerCard
        key={subpartner.id}
        subpartner={subpartner}
      />
    ))}
  </div>
);


export default SubpartnerList;
