import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useParams } from 'react-router-dom';
import Container from '@shared/components/container/container';
import { bonusessListByLang } from '../bonuses-screen/bonuses-list';
import TextContent from '@/shared/components/text-content/text-content';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import './bonuse-screen.scss';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '@/app-config';

const b = bemCn('bonuse-screen');
const BonuseScreen = () => {
  const { slug } = useParams();
  const { i18n } = useTranslation();
  const lang = i18n.resolvedLanguage ?? DEFAULT_LANG;
  const bonuses = bonusessListByLang[lang.toLowerCase()] ?? bonusessListByLang['default'];
  const content = bonuses.find((item) => item.id === slug)?.content;

  if (!content) {
    return null;
  }

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs />
        <TextContent className={b('content')}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </TextContent>
      </Container>
    </div>
  );
};

export default BonuseScreen;
