import { useTranslation } from 'react-i18next';
import { PartnerLink } from '@/modules/partner-program/model/types';
import ButtonIcon, { IButtonIconProps } from '@/shared/components/button-icon/button-icon';
import PartnerStatsDrawer from '../../core/partner-stats-drawer/partner-stats-drawer';
import { useState } from 'react';
import { useGetSubPartnerLinkStatsQuery, useGetSubPartnerLinkPlayersQuery } from '@/modules/partner-program/api/managerApi';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { usePPFilter } from '@/modules/partner-program/hooks/usePPFilter';
import { usePPPagination } from '@/modules/partner-program/hooks/usePPPagination';
import { useAppSelector } from '@/shared/hooks';
import { selectPPSelectedCurrency } from '@/modules/partner-program/model/selectors';

type ManagerUserLinkStatsBtnProps = IButtonIconProps & {
  link: PartnerLink;
}

const ManagerUserLinkStatsBtn = ({ link, className }: ManagerUserLinkStatsBtnProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [opened, setOpened] = useState(false);

  const currency = useAppSelector(selectPPSelectedCurrency);

  const initParams = {
    refCode: link.refCode,
  };

  const {
    filterParams,
    handleFilterChange,
  } = usePPFilter(initParams);
  const {
    params: paginationParams,
    handlePageChange,
  } = usePPPagination();

  const {
    data: linkStatsData,
    isLoading: isStatsLoading
  } = useGetSubPartnerLinkStatsQuery({ ...filterParams, currency }, { skip: !opened || !currency });

  const {
    data: linkPlayers, isLoading:
    isPlayersLoading
  } = useGetSubPartnerLinkPlayersQuery({ ...filterParams, ...paginationParams, currency }, { skip: !opened || !currency });
  const totalPlayersCount = linkPlayers?.count ?? 0;

  return (
    <>
      <ButtonIcon className={className}
        icon='stats'
        variant='bordered'
        onClick={() => setOpened(true)}
      />
      <PartnerStatsDrawer
        title={t('ref-code-stats', 'Статистика по реферальному коду')}
        isOpen={opened}
        onClose={() => setOpened(false)}
        metaInfo={
          <>
            <p>{t('project-title', 'Название проекта')}: {link.projectName}</p>
            <p>{t('created', 'Создан')}: {dayjsCustom(link.created).format('DD.MM.YYYY')}</p>
          </>
        }
        stats={linkStatsData}
        players={linkPlayers?.items}
        isStatsLoading={isStatsLoading}
        isPlayersLoading={isPlayersLoading}
        totalPlayersCount={totalPlayersCount}
        paginationParams={paginationParams}
        handleFilterChange={handleFilterChange}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default ManagerUserLinkStatsBtn;
