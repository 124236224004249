import { HandledError, handleQueryError } from '@/shared/utils/helpers/handle-query-error';
import { BasketFormError } from '../components/basket/use-basket';
import i18n from '@/shared/i18n/i18n';


const basketErrors: Record<string, string> = {
  'Event has finished or is not available': i18n.t('backend-errors.bet.event-has-finished-or-is-not-available', 'Событие завершилось или недоступно'),
};

export const handleBasketError = (error?: HandledError): BasketFormError | undefined => {
  const queryError = handleQueryError(error);

  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;

    if (typeof detail === 'string') {
      const errors: BasketFormError = {};
      switch (detail) {
        default:
          errors.root = basketErrors[detail] ?? detail;
      }

      return errors;
    }

    if (Array.isArray(detail)) {
      const errors: BasketFormError = {};
      detail.forEach((err) => {
        const location = err.loc?.[1];
        const message = err.msg;

        switch (location) {
          case 'amount':
            errors.amount = message;
            break;
          case 'agree':
            errors.agree = message;
            break;
          default:
            errors.root = message;
        }
      });

      return errors;
    }
  }
};
