import { DEFAULT_LANG } from '@/app-config';
import type { Lang } from '@/modules/basket';

const lineLang: Record<string, Lang> = {
  'ru': 'ru',
  'uk': 'ua',
  'en': 'en',
  'az': 'az',
  'uz': 'uz',
};

export const getLineLang = (siteLang?: string) => {
  if (siteLang) {
    return lineLang[siteLang] ?? lineLang[DEFAULT_LANG];
  }
  return lineLang[DEFAULT_LANG] ?? 'en';
};
