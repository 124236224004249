import { useTranslation } from 'react-i18next';
import GameScreen from '../game-screen';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { useParams } from 'react-router-dom';
import { useCreateEndorphinaSessionQuery } from '@/modules/casino/api/casino-api';
import { Maintenance, SiteMaintenance } from '@/widgets/site-maintenance';

const Endorphina = () => {
  const { t } = useTranslation();
  const { data: appStatuses } = useGetStatusQuery();
  const isEndorphinaActive = appStatuses?.endorphina ?? false;
  const { gameId = '' } = useParams();
  const { data: sessionUrl, isLoading, isError } = useCreateEndorphinaSessionQuery({
    exitUrl: `${window.location.origin}/games`,
    gameId: gameId
  }, { skip: !isEndorphinaActive });

  if (!isEndorphinaActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <GameScreen
      title="Fivemen"
      src={sessionUrl}
      loading={isLoading}
      isError={isError}
      errorMessage={t('common.unknown-error', 'Неизвестная ошибка')}
    />
  );
};

export default Endorphina;
