import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { PPToggledCard } from '@/modules/partner-program';
import { PartnerStats } from '../../model/types';
import { parseTotalStats } from '../../lib/parseTotalStats';
import Descriptions from '@/shared/components/descriptions/descriptions';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import './pp-total-stat.scss';

type PPTotalStatProps = {
  stats: PartnerStats;
}

const b = bemCn('pp-total-stat-mobile');
const PPTotalStat = ({ stats }: PPTotalStatProps) => {
  const { mainStats, commonStats, providersStats } = parseTotalStats(stats);
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <Descriptions>
          {mainStats.map(({ label, value }) => (
            <Descriptions.Item key={label + value} label={label} >
              <span className={b('item-with-coin')}>
                <IconSvg
                  className={b('item-with-coin-icon')}
                  name='coin-colored'
                  width={16}
                  height={16}
                />
                {value}
              </span>
            </Descriptions.Item>
          ))}
        </Descriptions>

        <Descriptions>
          {commonStats.map(({ label, value }) => (
            <Descriptions.Item key={label + value} label={label}>
              {value}
            </Descriptions.Item>
          ))}
        </Descriptions>

        {providersStats.map((provider) => (
          <PPToggledCard className={b('provider')}
            key={provider.name}
            title={`${t('provider', 'Провайдер')}: ${provider.name}`}
            desc={`${t('provider-income', 'Прибыль')}: ${provider.income}`}
          >
            <div className={b('provider-list')}>
              <div className={b('provider-item')}>
                <div className={b('provider-item-label')}>{t('placed', 'Ставки')}</div>
                <div className={b('provider-item-value')}>
                  {provider.placed}
                </div>
              </div>
              <div className={b('provider-item')}>
                <div className={b('provider-item-label')}>{t('winnings', 'Выигрыш')}</div>
                <div className={b('provider-item-value')}>
                  {provider.winnings}
                </div>
              </div>
            </div>
          </PPToggledCard>
        ))}

      </div>
    </div>
  );
};

export { PPTotalStat };
