import { bemCn } from '@shared/utils/helpers/bem-cn';
import { PropsWithCN } from '@shared/types/common';
import logoAnimated from '@assets/logo-dobro-animated-big.svg';
import { preloadImage } from '@/shared/utils/helpers/preload';
import './loader-big.scss';

preloadImage(logoAnimated);

const b = bemCn('loader-big');

const LoaderBig = ({ className }: PropsWithCN) => (
  <div className={b(null, className)}>
    <img className={b('image')} src={logoAnimated} alt="" />
  </div>
);

export default LoaderBig;
