import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useWithdrawalMethods } from '../../hooks/useWithdrawalMethods';
import AppModal from '@shared/components/app-modal/app-modal';
import PaymentMethod from '@/modules/user-wallet/components/payment-method/payment-method';
import WithdrawalModal from '../withdrawal-modal/withdrawal-modal';
import WithdrawalSuccessModal from '../withdrawal-success-modal/withdrawal-success-modal';
import './withdrawal-methods-modal.scss';

type WithdrawalMethodsModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const b = bemCn('withdrawal-methods-modal');

const WithdrawalMethodsModal = ({
  isOpen,
  onClose
}: WithdrawalMethodsModalProps) => {
  const { t } = useTranslation();

  const {
    methods,
    id,
    isListEmpty,
    activeModal,
    selectedMethod,
    handleMethodClick,
    handleModalClose,
  } = useWithdrawalMethods();

  return (
    <AppModal className={b()}
      isOpen={isOpen}
      onClose={onClose}
      title={
        <>
          {t('withdrawals.title', 'Вывод')}
          <span className={b('id')}>ID: {id}</span>
        </>
      }
      description={`${t('withdrawals.description', 'Выберите метод для вывода')}`}
      classNameTitle={b('title')}
      classNameDesc={b('desc')}
    >
      <div className={b('list')}>
        {isListEmpty && (
          <p className={b('empty')}>
            {t('withdrawals.empty-list', 'There are no withdrawal methods available')}
          </p>
        )}
        {methods.map((method) => (
          <PaymentMethod className={b('item')}
            key={method.id}
            title={method.title}
            icons={method.icons}
            onClick={() => handleMethodClick(method)}
          />
        ))}
      </div>
      <WithdrawalModal
        isOpen={activeModal === 'withdrawal-modal'}
        onClose={handleModalClose}
        method={selectedMethod}
      />

      <WithdrawalSuccessModal
        isOpen={activeModal === 'withdrawal-success'}
        onClose={handleModalClose}
      />
    </AppModal>
  );

};

export default WithdrawalMethodsModal;
