import Adaptive from '@/shared/components/responsive/adaptive';
import CouponCardDesktop from './coupon-card-desktop/coupon-card-desktop';
import CouponCardMobile from './coupon-card-mobile/coupon-card-mobile';
import type { Coupon } from '../../model/types';


type CouponCardProps = {
  className?: string;
  coupon: Coupon;
}

const CouponCard = (props: CouponCardProps) => (
  <Adaptive
    mobile={<CouponCardMobile {...props} />}
    desktop={<CouponCardDesktop {...props} />}
  />
);

CouponCard.Mobile = CouponCardMobile;
CouponCard.Desktop = CouponCardDesktop;

export { CouponCard };
