import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useAppSelector, useDispatchedActions } from '@/shared/hooks';
import { getBalanceHidden } from '@/shared/store/app/selectors';
import './hide-balance.scss';
import { useTranslation } from 'react-i18next';

const b = bemCn('hide-balance');

type HideBalanceProps = {
  className?: string;
  inverted?: boolean;
}

const HideBalance = ({ className, inverted }: HideBalanceProps) => {
  const { toggleBalanceHidden } = useDispatchedActions();
  const active = useAppSelector(getBalanceHidden);
  const handleOnClick = () => toggleBalanceHidden();
  const { t } = useTranslation();

  return (
    <div className={b({ inverted }, className)}>
      <h2 className={b('title')}>{t('settings.balance', 'Баланс')}</h2>
      <button className={b('btn', { active })} type='button' onClick={handleOnClick}>
        <span className={b('btn-text')}>
          {t('settings.hide-balance')}
        </span>
        <span className={b('btn-toggler')}></span>
      </button>
    </div>
  );
};

export default HideBalance;
