import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useResponsive } from '../shared/hooks/use-responsive';
import SidebarMenu from '@/widgets/layout/components/sidebar-menu/sidebar-menu';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CouponsPage, CouponDetailsPage } from '@/pages/coupons';
import GamesScreen from '@/pages/games-screen/games-screen';
import RulesScreen from '@/pages/rules-screen/rules-screen';
import RulesScreenMobile from '@/pages/rules-screen/rules-screen-mobile';
import BonusesScreen from '@/pages/bonuses-screen/bonuses-screen';
import BonuseScreen from '@/pages/bonuse-screen/bonuse-screen';
import HomeScreen from '@/pages/home-screen/home-screen';
import { LayoutCommon, GameScreenLayout } from '@/widgets/layout';
import PrivateRoute from '@/widgets/private-route/private-route';
import LiveScreenMobile from '@/pages/live-screen-mobile/live-screen-mobile';
import EventScreenMobile from '@/pages/event-screen-mobile/event-screen-mobile';
import LineScreenMobile from '@/pages/line-screen-mobile/line-screen-mobile';
import LineEventsScreenMobile from '@/pages/line-events-screen-mobile/line-events-screen-mobile';
import ProfileScreen from '@/pages/profile-screen/profile-screen';
import NotificationsScreen from '@/pages/notifications-screen/notifications-screen';
import PromocodesScreen from '@/pages/promocodes-screen/promocodes-screen';
import PromocodeActivateScreen from '@/pages/promocode-activate-screen/promocode-activate-screen';
import { partnerProgramRoute } from './app-routes/partner-program-route';
import { EventCardBigContainer, EventsDesktop, LineLayoutDesktop } from '@/modules/line';
import AppModals from '@/widgets/layout/components/app-modals/app-modals';
import TransactionsPage from '@/pages/transactions-page/transactions-page';
import VerifyEmailPage from '@/pages/auth/components/verify-email-page/verify-email-page';
import ResetPasswordPage from '@/pages/auth/components/reset-password-page/reset-password-page';
import Betgames from '@/pages/game-screen/betgames/betgames';
import Tvbet from '@/pages/game-screen/tvbet/tvbet';
import VegasClub from '@/pages/game-screen/vegas-club/vegas-club';
import Aviatrix from '@/pages/game-screen/aviatrix/aviatrix';
import Fivemen from '@/pages/game-screen/fivemen/fivemen';
import SmartSoft from '@/pages/game-screen/smartsoft/smartsoft';
import Agt from '@/pages/game-screen/agt/agt';
import Mascot from '@/pages/game-screen/mascot/mascot';
import Digitain from '@/pages/game-screen/digitain/digitain';
import Endorphina from '@/pages/game-screen/endorphina/endorphina';
import PragmaticPlay from '@/pages/game-screen/pragmatic-play/pragmatic-play';
import InbetGames from '@/pages/game-screen/inbet-games/inbet-games';
import RplGames from '@/pages/game-screen/rpl-games/rpl-games';
import BetgamesTwainSport from '@/pages/game-screen/betgames-twain-sport/betgames-twain-sport';
import TvBetFastSport from '@/pages/game-screen/tvbet-fastsport/tvbet-fastsport';
import UserSettingsPage from '@/pages/user-settings-page/user-settings-page';
import WithdrawalsPage from '@/pages/withdrawals-page/withdrawals-page';
import AnyRoutePage from '@/pages/any-route-page/any-route-page';
import { AppFeature } from '@/app-config';
import ButtonToTop from '@/shared/components/button-to-top/button-to-top';
import LineFavoritesScreenMobile from '@/pages/line-favorites-screen-mobile/line-favorites-screen-mobile';
import LiveFavoritesScreenMobile from '@/pages/live-favorites-screen-mobile/live-favorites-screen-mobile';
import './app.scss';

const b = bemCn('app');

const mobileRoutes = (
  <Route>
    <Route path="live" element={<LiveScreenMobile />} />
    <Route path="live/favorites" element={
      <PrivateRoute>
        <LiveFavoritesScreenMobile />
      </PrivateRoute>
    }
    />
    <Route path="live/:eventId" element={<EventScreenMobile dataType='live' />} />

    <Route path="line" element={<LineScreenMobile />} />
    <Route path="line/events/:tournamentId" element={<LineEventsScreenMobile />} />
    <Route path="line/favorites" element={
      <PrivateRoute>
        <LineFavoritesScreenMobile />
      </PrivateRoute>
    }
    />
    <Route path="line/:eventId" element={<EventScreenMobile dataType='line' />} />

    <Route path="rules/:slug" element={<RulesScreenMobile />} />

    <Route element={<PrivateRoute />}>
      <Route path="profile" element={<ProfileScreen />} />
      <Route path="profile/notifications" element={<NotificationsScreen />} />
      <Route path='profile/transactions' element={<TransactionsPage />} />
      <Route path='profile/withdrawals' element={<WithdrawalsPage />} />
      <Route path='profile/settings' element={<UserSettingsPage />} />
      <Route path="profile/promocodes" element={<PromocodesScreen />} />
      <Route path="profile/promocodes/activate" element={<PromocodeActivateScreen />} />
    </Route>
  </Route>
);

const desktopRoutes = (
  <Route>
    <Route path="live" element={<LineLayoutDesktop dataType='live' />}>
      <Route index element={<EventsDesktop dataType='live' />} />
      <Route path="favorites" element={<Navigate to='/live' replace />} />
      <Route path=':eventId' element={<EventCardBigContainer dataType='live' />} />
      <Route path="*" element={<Navigate to='/live' replace />} />
    </Route>
    <Route path="line" element={<LineLayoutDesktop dataType='line' />}>
      <Route index element={<EventsDesktop dataType='line' />} />
      <Route path="favorites" element={<Navigate to='/line' replace />} />
      <Route path=':eventId' element={<EventCardBigContainer dataType='line' />} />
      <Route path="*" element={<Navigate to='/line' replace />} />
    </Route>
    <Route path="rules/:slug" element={<RulesScreen />} />
  </Route>
);

const gameRoutes = (
  <Route element={<GameScreenLayout />}>
    <Route element={<PrivateRoute />}>
      <Route path="games/betgames/:gameId" element={<Betgames />} />
      <Route path="games/twain-sport/:gameId" element={<BetgamesTwainSport />} />
      <Route path="games/tvbet/:gameId" element={<Tvbet />} />
      <Route path="games/fastsport/:gameId" element={<TvBetFastSport />} />
      <Route path="games/inbetgames/:gameId" element={<InbetGames />} />
      <Route path="/games/rplgames/:gameId" element={<RplGames />} />
      <Route path="games/vegas/:gameId" element={<VegasClub />} />
      <Route path="games/pragmatic/:gameId" element={<PragmaticPlay />} />
      <Route path="games/mascot/:gameId" element={<Mascot />} />
      <Route path="games/digitain/:gameId" element={<Digitain />} />
      <Route path="games/agt/:gameId" element={<Agt />} />
      <Route path="games/smartsoft/:gameName/:gameCategory" element={<SmartSoft />} />
      <Route path="games/fivemen/:gameId" element={<Fivemen />} />
      <Route path="games/aviatrix" element={<Aviatrix />} />
      <Route path="games/endorphina/:gameId" element={<Endorphina />} />
    </Route>
  </Route>
);

const App = () => {
  const { isMobile, isDesktop } = useResponsive();

  return (
    <div id="app" className={b()}>
      <Routes>
        {gameRoutes}
        <Route element={<LayoutCommon />} >
          <Route index element={<HomeScreen />} />
          <Route path='email/verify/:id' element={<VerifyEmailPage />} />
          <Route path='/password/reset/:id' element={<ResetPasswordPage />} />
          <Route path="games" element={<GamesScreen />} />
          {AppFeature.BONUS_AND_PROMO && (
            <Route>
              <Route path="bonuses" element={<BonusesScreen />} />
              <Route path="bonuses/:slug" element={<BonuseScreen />} />
            </Route>
          )}

          <Route element={<PrivateRoute />}>
            <Route path="bets-history" element={<CouponsPage />} />
            <Route path="bets-history/:couponId" element={<CouponDetailsPage />} />
          </Route>

          {partnerProgramRoute}
          {isMobile && mobileRoutes}
          {isDesktop && desktopRoutes}

          <Route path="*" element={<AnyRoutePage />} />
        </Route>
      </Routes>
      <SidebarMenu className={b('sidebar')} />
      <ButtonToTop />
      <AppModals />
    </div>
  );
};

export default App;
