import { bemCn } from '@shared/utils/helpers/bem-cn';
import EventCardTeam from '../event-card-team/event-card-team';
import EventBoardTimer from './event-board-timer/event-board-timer';
import './event-board.scss';

const b = bemCn('event-board');

type EventBoardProps = {
  className?: string;
  period: string;
  team1Name: string;
  team2Name: string;
  team1Logo: string;
  team2Logo: string;
  totalScore: string;
  periodsScore: string;
  isLive: boolean;
  timer: number;
}

const EventBoard = (props: EventBoardProps) => {
  const {
    className,
    team1Name,
    team2Name,
    team1Logo,
    team2Logo,
    period,
    totalScore,
    periodsScore,
    isLive,
    timer,
  } = props;

  return (
    <div className={b(null, className)}>
      <div className={b('wrapper')}>

        {isLive && <p className={b('period')}>{period}</p>}
        <div className={b('teams-wrapper')}>
          <EventCardTeam
            className={b('team')}
            logo={team1Logo}
            name={team1Name}
            size='lg'
          />
          {isLive && timer ? (
            <div className={b('score-wrapper')}>
              <EventBoardTimer className={b('timer')} time={timer} />
              <p className={b('total-score')}>{totalScore}</p>
            </div>
          ) : (
            <p className={b('vs')}>vs</p>
          )}
          <EventCardTeam
            className={b('team')}
            logo={team2Logo}
            name={team2Name}
            size='lg'
          />
        </div>

        {isLive && <p className={b('periods-score')}>{periodsScore}</p>}
      </div>
    </div>
  );
};

export default EventBoard;
