import { AppRoute } from '../../utils/constants/routes';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { Link } from 'react-router-dom';
import { COMPANY_NAME } from '@/app-config';
import Flag from '../flag/falg';
import { useAppSelector } from '@/shared/hooks';
import { getUserGeoCountry } from '@/modules/user/store/selectors';
import { getMainLogotoype } from '@/shared/store/app/selectors';
import './logotype.scss';

type LogotypeProps = {
  className?: string;
  url?: string;
  img?: string;
  showFlag?: boolean;
}

const b = bemCn('logotype');

const Logotype = ({
  className,
  url = AppRoute.Root,
  img,
  showFlag,
}: LogotypeProps) => {
  const countryCode = useAppSelector(getUserGeoCountry);
  const logotype = useAppSelector(getMainLogotoype);

  return (
    <Link className={b(null, className)} to={url}>
      <img className={b('img')}
        src={img ?? logotype}
        width={220}
        height={46}
        alt={COMPANY_NAME}
      />
      {showFlag && (
        <Flag className={b('flag')}
          countryCode={countryCode}
          width={15}
          height={15}
        />
      )}
    </Link>
  );
};

export default Logotype;
