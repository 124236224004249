import { FC } from 'react';
import { PropsWithCN } from '../../../../shared/types/common';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import PromoBanners from '../promo-banners/promo-banners';
import { CasinoWinners } from '@/modules/casino';
import './promo-section.scss';

const b = bemCn('promo-section');

const PromoSection: FC<PropsWithCN> = ({ className, pageName }) => (
  <section className={b(null, className)}>
    <PromoBanners className={b('banners')} pageName={pageName} />
    <CasinoWinners className={b('winners')} />
  </section>
);

export default PromoSection;
