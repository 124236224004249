import { BasketItem, BasketRes } from './types';


export const adapteBasket = (res: BasketRes): BasketItem[] =>
  res.map((item) => ({
    lang: item.lang,
    dataType: item.data_type,
    id: item.basket_id,
    gameId: item.game_id,
    gameMid: item.game_mid,
    eventDate: item.event_date,
    team1Name: item.opp_1_name,
    team2Name: item.opp_2_name,
    subGameName: item.sub_game_name,
    subGameNameRu: item.sub_game_name_ru,
    tournamentName: item.tournament_name,
    groupName: item.group_name,
    outcomeName: item.outcome_name,
    sportName: item.sport_name,
    sportNameRu: item.sport_name_ru,
    rate: item.rate,
    available: item.available,
    isBlocked: !item.available,
  }));
