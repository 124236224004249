import { bemCn } from '@/shared/utils/helpers/bem-cn';
import Select from '@/shared/components/select/select';
import { timezoneTitles } from '../../utils/const';
import { useState } from 'react';
import { getTimezone, saveTimezone } from '../../utils/timezone';
import './timezone-change.scss';
import { useTranslation } from 'react-i18next';

const timeZoneOptions = Object.entries(timezoneTitles).map(([value, label]) => ({ label, value }));

const b = bemCn('timezone-change');

type TimezoneChangeProps = {
  className?: string;
  inverted?: boolean;
}

const TimezoneChange = ({ className, inverted }: TimezoneChangeProps) => {
  const { t } = useTranslation();
  const [currentTimezone, setCurrentTimezone] = useState<string | null>(getTimezone());

  const handleChange = (newValue: string | null) => {
    if (newValue !== null && newValue !== currentTimezone) {
      setCurrentTimezone(newValue);
      saveTimezone(newValue);
      window.location.reload();
    }
  };

  return (
    <div className={b({ inverted }, className)}>
      <h2 className={b('title')}>
        {t('settings.user-timezone', 'Часовой пояс')}
      </h2>
      <Select
        placeholder={t('settings.user-timezone', 'Часовой пояс')}
        options={timeZoneOptions}
        value={currentTimezone}
        position='top'
        onChange={handleChange}
        inverted={inverted}
      />
    </div>
  );
};

export default TimezoneChange;
