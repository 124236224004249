import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import TextContent from '@/shared/components/text-content/text-content';
import IconSvg from '@/shared/components/icon-svg/icon-svg';

import './rule-content-mobile.scss';

type Props = {
  id: string;
  title: string;
  content: string;
};

const RuleContentMobile = (props: Props) => {
  const { title, content, id } = props;
  const b = bemCn('rule-content-mobile');

  const [isActive, setIsActive] = useState(false);

  const iconClass = isActive
    ? `${b('toggle-icon')} ${b('toggle-icon--opened')}`
    : b('toggle-icon');

  return (
    <li className={b('item')} onClick={() => setIsActive(!isActive)}>
      <NavLink
        to={`/rules/${id}`}
        className={b('item-title')}
      >
        <span className={b('name')}>{title}</span>
        <IconSvg className={iconClass} name="chevron-down" />
      </NavLink>
      {isActive && (
        <TextContent className={b('content')}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </TextContent>
      )}
    </li>
  );
};

export default RuleContentMobile;
