import { useResponsive } from '@/shared/hooks/use-responsive';
import { FC, PropsWithChildren } from 'react';

type MobileProps = PropsWithChildren;

const Mobile: FC<MobileProps> = ({ children }) => {
  const { isMobile } = useResponsive();

  return isMobile ? children : null;
};

export default Mobile;
