import { bemCn } from '@shared/utils/helpers/bem-cn';
import GradientIcon from '@shared/components/gradient-icon/gradient-icon';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { getAmountString, roundNumber } from '@shared/utils/helpers/common';
import IconStatus from '../../icon-status/icon-status';
import { useToggle } from 'usehooks-ts';
import { FC } from 'react';
import { Coupon } from '../../../model/types';
import { couponIconColorByTheme, couponTypeLabel } from '../../../helpers/const';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '@/modules/theme/model/theme-context';
import { useAppSelector } from '@/shared/hooks';
import { getCouponLogotype } from '@/shared/store/app/selectors';
import './coupon-card-desktop.scss';

type CouponCardDesktopProps = {
  className?: string;
  coupon: Coupon;
}

const b = bemCn('coupon-card-desktop');

const CouponCardDesktop: FC<CouponCardDesktopProps> = ({
  className,
  coupon
}) => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const [opened, toggleOpened] = useToggle(false);
  const logotype = useAppSelector(getCouponLogotype);
  const {
    id,
    type,
    created,
    rate,
    betAmount,
    winAmount,
    currency,
    bets,
    isLive,
    status,
  } = coupon;

  const iconColor = couponIconColorByTheme[theme] ?? couponIconColorByTheme.default;

  // TODO: Сделсть обработчик onKeyPress для открытия/закрытия по клавише
  return (
    <div className={b({ opened, status }, className)}>
      <div className={b('info')} role='button' tabIndex={0} onClick={toggleOpened}>
        <div className={b('info-wrapper')}>
          <p className={b('type')}>{couponTypeLabel[type]}</p>
          <p className={b('live-label', { show: isLive })}>LIVE</p>
          <p className={b('number')}>№: {id}</p>
        </div>
        <p className={b('date')}>
          {dayjsCustom(created).format('DD.MM.YYYY / HH:mm')}
        </p>
        <p className={b('bet-amount')}>
          {getAmountString(betAmount, currency)}
        </p>
        {/* <p className={b('logo-label')}>{label}</p> */}
        <img className={b('logo-label')} src={logotype} />
        <p className={b('rate', { main: true })}>{roundNumber(rate)}</p>
        {/* <IconStatus className={b('status')} status={status} /> */}
        <p className={b('win-amount', { main: true })}>
          {status === 'placed' && getAmountString(betAmount * rate, currency)}
          {status !== 'placed' && getAmountString(winAmount ?? 0, currency)}
        </p>
        <p className={b('status-text')}>
          {status === 'refund' && t('bet-history.refund', 'Возврат')}
          {status === 'placed' && t('bet-history.placed', 'Ожидание')}
          {status === 'win' && t('bet-history.win', 'Выигрыш')}
          {status === 'loss' && t('bet-history.loss', 'Проигрыш')}
        </p>
        <IconSvg className={b('toggle-icon')} name="chevron-down" />
      </div>
      <div className={b('details')}>
        <div className={b('list')}>
          {bets.map((bet) => (
            <div className={b('item')} key={bet.id}>
              <div className={b('item-wrapper')}>
                <GradientIcon className={b('item-icon')}
                  iconName={bet.sportIcon}
                  color={iconColor}
                />
                <p className={b('item-tournament')}>
                  {bet.sportName} / {bet.tournamentName}
                </p>
                <p className={b('item-teams')}>
                  <span className={b('item-team')}>{bet.team1}</span>
                  <span className={b('item-team')}>{bet.team2}</span>
                </p>
              </div>
              <p className={b('item-date')}>
                {dayjsCustom(bet.gameDate).format('DD.MM.YYYY / HH:mm')}
              </p>
              <p className={b('item-score')}>
                {/* <span className={b('item-score-total')}>{bet.gameScore}</span> */}
                <span className={b('item-score-periods')}>{bet.gameScore}</span>
              </p>
              <p className={b('item-rate')}>{roundNumber(bet.rate)}</p>
              <p className={b('item-outcome')}>{bet.outcomeName}</p>
              <IconStatus className={b('item-status')} status={bet.status} />
            </div>
          ))}
        </div>
        <div className={b('footer')}>
          <p className={b('win-amount-label')}>
            {status === 'refund' && t('bet-history.refund', 'Возврат')}
            {status === 'placed' && t('bet-history.possible-prize', 'Возможный выигрыш')}
            {status !== 'placed' && status !== 'refund' && t('bet-history.win', 'Выигрыш')}
          </p>
          <p className={b('win-amount')}>
            {status === 'placed' && getAmountString(betAmount * rate, currency)}
            {status !== 'placed' && getAmountString(winAmount ?? 0, currency)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CouponCardDesktop;
