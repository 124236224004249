import { useState } from 'react';

import type { PPFilterData } from '../model/types';

export const usePPFilter = <T>(initParams: T) => {
  const [filterParams, setFilterParams] = useState(initParams);

  const handleFilterChange = (filterData: PPFilterData) => {
    setFilterParams((prev) => ({
      ...prev,
      ...filterData,
    }));
  };

  return {
    filterParams,
    handleFilterChange,
  };
};
