import { getPaymentMethodIcons } from '@shared/utils/helpers/common';
import type {
  Appsport,
  AppSports,
  AppsportServer,
  AppStatus,
  AppStatusRes,
  BanksList,
  BanksListRes,
  ITopupMethodsByCurrency,
  ITopupMethodsServer,
  TBanner,
  TBannerServer,
  WithdrawalMethodsByCurrency,
  WithdrawalMethodsServer,
} from './types';

const adapteStatus = (res: AppStatusRes): AppStatus => {
  const { status_message: statusMessage, ...status } = res;
  return {
    ...status,
    statusMessage,
  };
};

const adapteBanksList = (res: BanksListRes): BanksList => {
  const { dictionary = [] } = res;

  return dictionary.map(({
    schema: id,
    bankName: name,
    logoURL,
  }) => ({ id, name, logoURL, }));
};


export const adapteWithdrawalMethods = (res: WithdrawalMethodsServer): WithdrawalMethodsByCurrency => {
  if (!res) {
    return {};
  }

  const methods: WithdrawalMethodsByCurrency = {};

  Object.entries(res)
    .forEach(([methodName, methodData]) => {
      Object.entries(methodData.limits)
        .forEach(([currency, [minAmount, maxAmount]]) => {
          methods[currency] = methods[currency] ?? [];
          if (methodData.is_active) {
            methods[currency].push({
              id: methodName,
              name: methodName,
              title: methodData.displayed_name,
              isActive: methodData.is_active,
              currency,
              minAmount,
              maxAmount,
              priority: methodData.priority ?? 0,
              icons: getPaymentMethodIcons(methodName),
            });
          }
        });
    });

  for (const currency in methods) {
    methods[currency] = methods[currency]
      .sort((a, b) => b.priority - a.priority);
  }

  return methods;
};


export const adapteTupupMethods = (res: ITopupMethodsServer): ITopupMethodsByCurrency => {
  if (!res) {
    return {};
  }

  const methods: ITopupMethodsByCurrency = {};

  Object.entries(res)
    .forEach(([system, systemMethods]) => ({
      id: system,
      methods: Object.entries(systemMethods)
        .forEach(([methodName, methodData]) => {
          Object.entries(methodData.limits)
            .forEach(([currency, [minAmount, maxAmount]]) => {
              methods[currency] = methods[currency] ?? [];
              if (methodData.is_active) {
                methods[currency].push({
                  id: `${system}:${methodName}`,
                  name: methodName,
                  title: methodData.displayed_name,
                  system: system,
                  currency,
                  isActive: methodData.is_active,
                  minAmount,
                  maxAmount,
                  priority: methodData.priority ?? 0,
                  icons: getPaymentMethodIcons(methodName),
                });
              }
            });
        })
    }));

  for (const currency in methods) {
    methods[currency] = methods[currency]
      .sort((a, b) => b.priority - a.priority);
  }

  return methods;
};

const isBannerRoute = (link: string) => link.slice(0, 4) === 'http';

export const adapteBanners = (res: TBannerServer[]): TBanner[] => (
  res.map((banner) => ({
    pages: banner.pages,
    id: banner.id,
    name: banner.name,
    isDesktop: banner.is_desktop,
    currency: banner.currency,
    priority: banner.priority,
    url: banner.url,
    title: banner.title,
    subtitle: banner.subtitle,
    buttonText: banner.button_text,
    [!isBannerRoute(banner.link) ? 'link' : 'href']: banner.link,
  }))
);

const adapteAppSport = (sport: AppsportServer): Appsport => ({
  name: sport.name,
  blacklisted: sport.blacklisted,
  subGamesBlackList: sport.sub_games_black_list,
  tournamentsBlackList: sport.tournaments_black_list,
  tournamentsPriority: Object.fromEntries(
    Object.entries(sport.tournaments_priority)
      .map(([pLevel, pValue]) => ([Number(pLevel), pValue]))
  )
});

export const adapteAppSports = (sports: AppsportServer[]): AppSports => {
  const tournamentsBlackList: string[] = [];
  const sportsBlacklist: string[] = [];
  const subGamesBlackList: string[] = [];

  let tournamentsPriority: string[] = [];
  const tournamentsByPriorityLevel: {
    [key: number]: string[];
  } = {};
  const items: Appsport[] = [];

  for (const sport of sports) {
    if (sport.blacklisted) {
      sportsBlacklist.push(sport.name.toLowerCase());
    }
    items.push(adapteAppSport(sport));

    subGamesBlackList.push(...sport.sub_games_black_list.map((item) => item.toLowerCase()));
    tournamentsBlackList.push(...sport.tournaments_black_list.map((item) => item.toLowerCase()));

    Object.entries(sport.tournaments_priority)
      .forEach(([pLevel, pValue]) => {
        const lvl = parseInt(pLevel, 10);

        if (!tournamentsByPriorityLevel[lvl]) {
          tournamentsByPriorityLevel[lvl] = [];
        }
        tournamentsByPriorityLevel[lvl] = [
          ...tournamentsByPriorityLevel[lvl],
          ...pValue,
        ];
      });
  }

  Object.entries(tournamentsByPriorityLevel)
    .sort(([lvlA], [lvlB]) => parseInt(lvlA, 10) - parseInt(lvlB, 10))
    .forEach(([, pValue]) => {
      tournamentsPriority = [
        ...tournamentsPriority,
        ...pValue,
      ];
    });

  return {
    items: items,
    tournamentsPriority: tournamentsPriority
      .map((item) => item.toLowerCase()),
    tournamentsBlackList: tournamentsBlackList,
    sportsBlacklist: sportsBlacklist,
    subGamesBlackList: subGamesBlackList,
  };
};


export {
  adapteStatus,
  adapteBanksList
};
