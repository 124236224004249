import { combineReducers } from '@reduxjs/toolkit';

import { baseApi } from '../shared/store/api.ts';
import { authSlice } from '@/modules/auth';
import { casinoSlice } from '@/modules/casino';
import { betapi } from '@/modules/line';
import { userSlice } from '@/modules/user';
import { ppSlice } from '@/modules/partner-program';
import appSlice from '@shared/store/app/app-slice.ts';

export const rootReducer = combineReducers({
  [appSlice.name]: appSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [casinoSlice.name]: casinoSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [betapi.reducerPath]: betapi.reducer,
  [userSlice.name]: userSlice.reducer,
  [ppSlice.name]: ppSlice.reducer,
});
