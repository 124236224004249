import AppModal from '@/shared/components/app-modal/app-modal';
import WithdrawalsList from '../withdrawals-list/withdrawals-list';
import { useTranslation } from 'react-i18next';

type WithdrawalsModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const WithdrawalsModal = ({ isOpen, onClose }: WithdrawalsModalProps) => {
  const { t } = useTranslation();

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('profile.nav.withdrawal-requests', 'Заявки на вывод')}
    >
      <WithdrawalsList inverted />
    </AppModal>
  );
};

export default WithdrawalsModal;
