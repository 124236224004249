import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import GradientIcon from '@shared/components/gradient-icon/gradient-icon';
import { useAppSelector, useDispatchedActions } from '@shared/hooks';
import { getIsMenuOpened } from '@shared/store/app/selectors';
import {
  useDebounceValue,
  // useOnClickOutside,
  useScrollLock
} from 'usehooks-ts';
import { useEffect, useRef } from 'react';
import { extractInitials, scrollToTop } from '@shared/utils/helpers/common';
import { getUserData } from '@/modules/user';
import SocialLinks from '@/widgets/core/components/social-links/social-links';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { useLogoutMutation, getIsAuth } from '@/modules/auth';
import { useTranslation } from 'react-i18next';
import { getNewNotificationsCount } from '@/modules/notifications';
import './sidebar-menu.scss';

type SidebarMenuProps = {
  className?: string;
}

const b = bemCn('sidebar-menu');

const SidebarMenu = ({ className }: SidebarMenuProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();
  const { setMenuOpened, logout } = useDispatchedActions();
  const { name } = useAppSelector(getUserData);
  const isMenuOpened = useAppSelector(getIsMenuOpened);
  const isAuth = useAppSelector(getIsAuth);
  const [debouncedIsOpen] = useDebounceValue(isMenuOpened, 200);
  const [serverLogout] = useLogoutMutation();
  const newNotificationsCount = useAppSelector(getNewNotificationsCount);
  const menuRef = useRef<HTMLDivElement>(null);
  useScrollLock({ autoLock: isMenuOpened, });

  // !!! отключил закрытие меню при клике в другие облати изза бага: при клике на кнопку меню оно переоткрывается.
  // useOnClickOutside(menuRef, () => {
  //   if (isMenuOpened) {
  //     setMenuOpened(false);
  //   }
  // });

  useEffect(() => {
    scrollToTop();
  }, [isMenuOpened]);

  if (!debouncedIsOpen || !isAuth) {
    return null;
  }

  const handleItemClick = () => {
    setMenuOpened(false);
  };

  const handleLogoutClick = async () => {
    await serverLogout();
    logout();
    setMenuOpened(false);
  };

  if (isDesktop) {
    return null;
  }

  return (
    <div className={b({ 'fade-in': isMenuOpened, 'fade-out': !isMenuOpened }, className)} ref={menuRef}>
      <div className={b('user')}>
        <Link className={b('user-info')} to="/profile" onClick={handleItemClick}>
          <p className={b('user-icon')}>{extractInitials(name ?? '')}</p>
          <div className={b('user-wrapper')}>
            <p className={b('user-label')}>
              {t('main-nav.profile', 'Личный кабинет')}
            </p>
            <p className={b('user-name')}>{name}</p>
          </div>
          <IconSvg className={b('user-arrow')} name="chevron-right" />
        </Link>
      </div>
      <div className={b('list')}>
        {/* <Link className={b('item')} to="/favorites" onClick={handleItemClick}>
          <GradientIcon className={b('item-icon')} iconName='star' color='transparent' />
          <span className={b('item-name')}>
            {t('common.favorite', 'Избранное')}
          </span>
          <IconSvg className={b('item-arrow')} name="chevron-right" />
        </Link> */}
        <Link className={b('item')} to="/profile/notifications" onClick={handleItemClick}>
          <GradientIcon className={b('item-icon')} iconName='bell' color='transparent' />
          <span className={b('item-name')}>
            {t('user-nav.notifications', 'Уведомления')}
          </span>
          {!!newNotificationsCount && (
            <span className={b('counter-number')}>{newNotificationsCount}</span>
          )}
          <IconSvg className={b('item-arrow')} name="chevron-right" />
        </Link>
      </div>

      <div className={b('list')}>
        <Link className={b('item')} to="/live" onClick={handleItemClick}>
          <GradientIcon className={b('item-icon')} iconName='live' color='purple' />
          <span className={b('item-name')}>
            {t('main-nav.live', 'Live')}
          </span>
          <IconSvg className={b('item-arrow')} name="chevron-right" />
        </Link>
        <Link className={b('item')} to="/line" onClick={handleItemClick}>
          <GradientIcon className={b('item-icon')} iconName='desktop' />
          <span className={b('item-name')}>
            {t('main-nav.line', 'Линия')}
          </span>
          <IconSvg className={b('item-arrow')} name="chevron-right" />
        </Link>
        <Link className={b('item')} to="/games" onClick={handleItemClick}>
          <GradientIcon className={b('item-icon')} iconName='coin' color='green' />
          <span className={b('item-name')}>
            {t('main-nav.casino', 'Казино')}
          </span>
          <IconSvg className={b('item-arrow')} name="chevron-right" />
        </Link>
        <Link className={b('item')} to="/bets-history" onClick={handleItemClick}>
          <GradientIcon className={b('item-icon')} iconName='coin-flat' color='pink' />
          <span className={b('item-name')}>
            {t('profile.nav.bet-history', 'История ставок')}
          </span>
          <IconSvg className={b('item-arrow')} name="chevron-right" />
        </Link>
        <button className={b('item')} type='button'
          onClick={handleLogoutClick}
        >
          <GradientIcon className={b('item-icon')} iconName='exit' color='transparent' />
          <span className={b('item-name')}>
            {t('user-nav.logout', 'Выйти')}
          </span>
          <IconSvg className={b('item-arrow')} name="chevron-right" />
        </button>
      </div>

      <SocialLinks className={b('social')} />
    </div>
  );
};


export default SidebarMenu;
