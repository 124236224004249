import { createSlice } from '@reduxjs/toolkit';
import { NameSpace } from '@/shared/utils/constants/common';
import { aviatrixList, batgamesList, elCasinoList, fastSportList, tvbetList, twainSportList } from './const';
import { CasinoState } from './types';
import casinoApi from '../api/casino-api';
import { saveCasinoGames } from '../utils/casino-localstorage';


const initialState: CasinoState = {
  betgamesList: batgamesList,
  twainSportList: twainSportList,
  tvbetList: tvbetList,
  fastSportList: fastSportList,
  elCasinoList: elCasinoList,
  aviatrix: aviatrixList,
};

const casinoSlice = createSlice({
  name: NameSpace.Casino,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(casinoApi.endpoints.getInbetGames.matchFulfilled, (_, action) => {
        saveCasinoGames('inbet/games', action.payload);
      })
      .addMatcher(casinoApi.endpoints.getRplGames.matchFulfilled, (_, action) => {
        saveCasinoGames('rpl/games', action.payload);
      })
      .addMatcher(casinoApi.endpoints.getVegasGames.matchFulfilled, (_, action) => {
        saveCasinoGames('vegas/games', action.payload);
      })
      .addMatcher(casinoApi.endpoints.getMascotGames.matchFulfilled, (_, action) => {
        saveCasinoGames('mascot/games', action.payload);
      })
      .addMatcher(casinoApi.endpoints.getSmartsoftGames.matchFulfilled, (_, action) => {
        saveCasinoGames('smartsoft/games', action.payload);
      })
      .addMatcher(casinoApi.endpoints.getPragmaticLobbyGames.matchFulfilled, (_, action) => {
        const category = action.meta.arg.originalArgs;
        if (category === undefined || category === 'all') {
          saveCasinoGames('pragmatic/lobby_games/all', action.payload);
        }
        if (category === 'hot') {
          saveCasinoGames('pragmatic/lobby_games/hot', action.payload);
        }
        if (category === 'new') {
          saveCasinoGames('pragmatic/lobby_games/new', action.payload);
        }
      })
      .addMatcher(casinoApi.endpoints.getPragmaticGames.matchFulfilled, (_, action) => {
        saveCasinoGames('pragmatic/games', action.payload);
      })
      .addMatcher(casinoApi.endpoints.getAgtGames.matchFulfilled, (_, action) => {
        saveCasinoGames('agt/games', action.payload);
      })
      .addMatcher(casinoApi.endpoints.getDigitainGames.matchFulfilled, (_, action) => {
        saveCasinoGames('digitain/games', action.payload);
      })
      .addMatcher(casinoApi.endpoints.getEndorphinaGames.matchFulfilled, (_, action) => {
        saveCasinoGames('endorphina/games', action.payload);
      })
      .addMatcher(casinoApi.endpoints.getFivemenGames.matchFulfilled, (_, action) => {
        saveCasinoGames('fivemen/games', action.payload);
      });
  }
});


export default casinoSlice;
