import { useCallback, useState } from 'react';

export const useTournamentEventsToggler = (itemsIds?: string[]) => {
  const [closedItems, setClosedItems] = useState<string[]>([]);
  const isAllClosed = closedItems.length === itemsIds?.length;

  const handleToggleItem = useCallback((itemId: string) => {
    setClosedItems((prev) => {
      const index = prev.indexOf(itemId);
      const temp = [...prev];

      if (index > -1) {
        temp.splice(index, 1);
      } else {
        temp.push(itemId);
      }

      return temp;
    });
  }, []);


  const handleToggleAll = useCallback(() => {
    if (isAllClosed) {
      setClosedItems([]);
    } else {
      setClosedItems(itemsIds ?? []);
    }
  }, [isAllClosed, itemsIds]);

  return {
    closedItems,
    isAllClosed,
    handleToggleItem,
    handleToggleAll,
  };
};
