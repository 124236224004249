import { bemCn } from '@shared/utils/helpers/bem-cn';
import { FC, memo, useMemo, useState } from 'react';
import AppModal from '@shared/components/app-modal/app-modal';
import PromocodeCard from '../promocode-card/promocode-card';
import { GetPromocodesParams } from '@/modules/user/store/types';
import { useGetPromocodesQuery } from '@/modules/user/store/user-api';
import Button from '@shared/components/button/button';
import './promocodes-modal.scss';
import { useTranslation } from 'react-i18next';

const MemoizedPromocodeCard = memo(PromocodeCard);

const b = bemCn('promocodes-modal');

const PAGE_SIZE = 10;

const initParams: GetPromocodesParams = {
  pageSize: PAGE_SIZE,
  page: 1,
  ascending: false
};


type PromocodesModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const PromocodesModal: FC<PromocodesModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);
  const { data, isLoading } =
    useGetPromocodesQuery(params, { skip: !isOpen });

  const handleShowMore = () => {
    setParams((prev) => ({
      ...prev,
      pageSize: prev.pageSize && prev.pageSize + PAGE_SIZE,
    }));
  };

  const isBtnShow = Number(data?.count) >= Number(params.pageSize);

  const memoizedPromocodes = useMemo(() => data?.items.map((item) => (
    <MemoizedPromocodeCard
      key={item.name + item.usedDate}
      promocode={item}
    />
  )), [data?.items]);

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('promocodes.promocodes-history')}
      className={b()}
    >
      {memoizedPromocodes?.length === 0 && (
        <div className={b('empty')}>
          <p>{t('promocodes.no-active-promocodes')}</p>
        </div>
      )}

      {!isLoading && memoizedPromocodes && memoizedPromocodes?.length > 0 && (
        <div className={b('list')}>
          {memoizedPromocodes}
          {isBtnShow && (
            <Button className={b('show-more')} variant='secondary' onClick={handleShowMore}>
              {t('common.show-more', 'Загрузить ещё')}
            </Button>
          )}
        </div>
      )}
    </AppModal>
  );
};

export default PromocodesModal;
