import { useTranslation } from 'react-i18next';
import { PropsWithCN } from '@/shared/types/common';
import { PlayerStats } from '@/modules/partner-program/model/types';
import PPTable from '../../core/pp-table/pp-table';

type PartnerPlayersTableProps = PropsWithCN & {
  players: PlayerStats[];
}

const PartnerPlayersTable = ({ className, players }: PartnerPlayersTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  return (
    <PPTable<PlayerStats>
      dataSource={players}
      className={className}
    >
      <PPTable.Column<PlayerStats>
        dataIndex="playerId"
        title="ID"
      />
      <PPTable.Column<PlayerStats>
        dataIndex="playerName"
        title={t('name', 'Имя')}
      />
      <PPTable.Column<PlayerStats>
        dataIndex="deposits"
        title={t('deposits', 'Депозиты')}
        render={(_, { adminDeposits, deposits }) => (
          Number(deposits) + Number(adminDeposits)
        )}
      />
      <PPTable.Column<PlayerStats>
        dataIndex="betsIncome"
        title={t('bet-income', 'Прибыль ставки')}
      />
      <PPTable.Column<PlayerStats>
        dataIndex=""
        title={t('casino-income', 'Прибыль Казино')}
        render={(_, { inbetIncome, betgamesIncome, vegasIncome }) => (
          Number(inbetIncome) + Number(betgamesIncome) + Number(vegasIncome)
        )}
      />
      <PPTable.Column<PlayerStats>
        dataIndex="income"
        title={t('income', 'Доход компании')}
      />
    </PPTable>
  );
};

export default PartnerPlayersTable;
