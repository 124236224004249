import { FieldValues, FormProvider, SubmitHandler, useController, UseControllerProps, useFormContext, UseFormReset } from 'react-hook-form';
import TextField, { TextFieldProps } from '../text-field/text-field';
import { useYupForm, UseYupFormProps } from '@/shared/hooks/useForm';
import React, { HTMLAttributes, useEffect } from 'react';
import Select, { SelectProps } from '../select/select';
import Checkbox, { CheckboxProps } from '../checkbox/checkbox';

type FormProps<T extends FieldValues> = Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'> & {
  form?: UseYupFormProps<T>;
  onSubmit?: (data: T, event?: React.BaseSyntheticEvent, reset?: UseFormReset<T>) => unknown;
}

const Form = <T extends FieldValues>({
  form,
  onSubmit = () => undefined,
  ...formProps
}: FormProps<T>) => {
  const methods = useYupForm<T>(form ?? {});
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset();
  }, []);

  const customSubmitHandler: SubmitHandler<T> = (...params) => {
    onSubmit(...params, reset);
  };

  return (
    <FormProvider {...methods}>
      <form {...formProps} onSubmit={handleSubmit(customSubmitHandler)} />
    </FormProvider>
  );
};

const RootError = <T extends FieldValues>(props: HTMLAttributes<HTMLParagraphElement>) => {
  const { formState: { errors } } = useFormContext<T>();
  if (!errors.root) {
    return null;
  }
  return (
    <p {...props}>
      {errors.root.message}
    </p>
  );
};

const FormTextField = <T extends FieldValues>(props: TextFieldProps & UseControllerProps<T>) => {
  const { onClear } = props;
  const { control } = useFormContext<T>();
  const { field, fieldState } = useController({ ...props, control });

  const handleClear = () => {
    onClear?.();
    field.onChange(undefined);
    field.onBlur();
  };

  return (
    <TextField
      {...props}
      onClear={handleClear}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      status={fieldState.invalid ? 'error' : undefined}
      error={fieldState.error?.message}
      disabled={field.disabled || props.disabled}
    />
  );
};

const FormSelect = <T extends FieldValues>(props: SelectProps & UseControllerProps<T>) => {
  const { control } = useFormContext<T>();
  const { field, fieldState } = useController({ ...props, control });
  return (
    <Select
      {...props}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      status={fieldState.invalid ? 'error' : undefined}
      error={fieldState.error?.message}
      disabled={field.disabled || props.disabled}
    />
  );
};

const FormCheckbox = <T extends FieldValues>(props: CheckboxProps & UseControllerProps<T>) => {
  const { control } = useFormContext<T>();
  const { field, fieldState } = useController({ ...props, control });
  return (
    <Checkbox
      {...props}
      name={field.name}
      checked={typeof field.value === 'boolean' ? field.value : undefined}
      onChange={field.onChange}
      onBlur={field.onBlur}
      status={fieldState.invalid ? 'error' : undefined}
      error={fieldState.error?.message}
      disabled={field.disabled || props.disabled}
    />
  );
};


Form.TextField = FormTextField;
Form.RootError = RootError;
Form.Select = FormSelect;
Form.Checkbox = FormCheckbox;

export default Form;
