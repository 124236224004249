

import { useResponsive } from '@/shared/hooks/use-responsive';
import { FC } from 'react';

type AdaptiveProps = {
  mobile?: React.ReactNode;
  desktop?: React.ReactNode;
};

const Adaptive: FC<AdaptiveProps> = ({ mobile, desktop }) => {
  const { isDesktop, isMobile } = useResponsive();

  if (isMobile) {
    return mobile;
  }

  if (isDesktop) {
    return desktop;
  }

  return null;
};

export default Adaptive;
