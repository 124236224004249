import { useMediaQuery } from 'react-responsive';

export const useResponsive = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
  // const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 1023px)' });

  return {
    isDesktop,
    isMobile,
  };
};
