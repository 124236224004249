import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import './footer-nav.scss';

const b = bemCn('footer-nav');

export type FooterNavItem = {
  title: string;
  link?: string;
  href?: string;
  disabled?: boolean;
}

export type FooterNavProps = {
  className?: string;
  title: string;
  items: FooterNavItem[];
}

const FooterNav = (props: FooterNavProps) => {
  const { className, title, items } = props;

  return (
    <div className={b(null, className)}>
      <h2 className={b('title')}>{title}</h2>
      <ul className={b('list')}>
        {items.map(({ title: itemTitile, link, href }) => (
          <li className={b('item')} key={itemTitile + link + href}>
            {link ? (
              <Link className={b('link')} to={link}>{itemTitile}</Link>
            ) : (
              <a
                className={b('link')}
                href={href}
                target="_blank"
                rel="noreferrer"
              >{itemTitile}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterNav;
