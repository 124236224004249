import { useCallback } from 'react';

import { getBasketCount } from '@/modules/basket';
import { useDispatchedActions, useAppSelector } from '@shared/hooks';
import EventCardDesktop, { EventCardDesktopProps } from './event-card-desktop/event-card-desktop';
import EventCardMobile, { EventCardMobileProps } from './event-card-mobile/event-card-mobile';
import Adaptive from '@/shared/components/responsive/adaptive';
import { useAddToBasketMutation } from '@/modules/basket';
import { useTranslation } from 'react-i18next';

type EventCardProps = EventCardMobileProps | EventCardDesktopProps

const EventCard = (props: EventCardProps) => {
  const { i18n } = useTranslation();
  const [addToBasket] = useAddToBasketMutation();
  const { changeAppModal } = useDispatchedActions();
  const basketCount = useAppSelector(getBasketCount);

  const handleBetClick = useCallback(async (betId: string) => {
    await addToBasket({
      dataType: props.event.dataType,
      betId,
      lang: i18n.resolvedLanguage,
    });

  }, [props.event.dataType]);

  const handleBetClickMobile = (betId: string) => {
    handleBetClick(betId);

    if (basketCount === 0) {
      changeAppModal('basket');
    }
  };

  return (
    <Adaptive
      mobile={(
        // <EventCardMobile {...props} onBetClick={handleBetClick} />
        <EventCardMobile {...props} onBetClick={handleBetClickMobile} />
      )}
      desktop={(
        <EventCardDesktop {...props} onBetClick={handleBetClick} />
      )}
    />
  );
};

export default EventCard;
