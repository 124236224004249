import { Link, useLocation } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import casinoPreview from '@assets/casino-preview.png?url';
import { useState } from 'react';
import casinoCardsImg from '@/assets/icons/icon-casino-cards.svg?url';
import './sidebar-games-item.scss';

const b = bemCn('sidebar-games-item');


type Props = {
  className?: string;
  name?: string;
  link?: string;
  preview?: string;
}

const SidebarGamesItem = (props: Props) => {
  const {
    className,
    preview = casinoPreview,
    name = 'Название игры',
    link = '#',
    // isFavorite = false
  } = props;
  const location = useLocation();
  const [isImgError, setImgError] = useState(false);

  return (
    <div className={b(null, className)}>
      <Link className={b('link')} to={link}
        state={{ returnUrl: location.pathname }}
      >
        <p className={b('picture')}>
          <img className={b('img', { error: isImgError })}
            src={isImgError ? casinoCardsImg : preview}
            onError={() => setImgError(true)}
            alt={name}
            loading='lazy'
          />
        </p>
        <p className={b('name')}>{name}</p>
      </Link>
    </div>
  );
};

export default SidebarGamesItem;
