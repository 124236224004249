import { useState } from 'react';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useTranslation } from 'react-i18next';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import TextField from '@/shared/components/text-field/text-field';

import './pp-filter.scss';

import type { ChangeEventHandler } from 'react';
import type { PPFilterData } from '@/modules/partner-program/model/types';

const b = bemCn('pp-filter');

const transformDate = (date: string) => {
  const dateFrom = dayjsCustom(date).startOf('day').toISOString();
  const dateTo = dayjsCustom(date).endOf('day').toISOString();

  return { dateFrom, dateTo };
};

type Props = {
  className?: string;
  handleFilterChange?: (filterData: PPFilterData) => void;
};

const PPFilter = (props: Props) => {
  const {
    className,
    handleFilterChange,
  } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [createdFrom, setCreatedFrom] = useState('');
  const [createdTo, setCreatedTo] = useState('');

  const onDateFromChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setCreatedFrom(event.target.value);

    const filterData: PPFilterData = {
      createdFrom: transformDate(event.target.value).dateFrom,
      createdTo: createdTo ? transformDate(createdTo).dateTo : undefined,
    };

    handleFilterChange?.(filterData);
  };

  const onDateToChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setCreatedTo(event.target.value);

    const filterData: PPFilterData = {
      createdFrom: createdFrom ? transformDate(createdFrom).dateFrom : undefined,
      createdTo: transformDate(event.target.value).dateTo,
    };

    handleFilterChange?.(filterData);
  };

  return (
    <div className={b(undefined, className)}>
      <div className={b('fields')}>
        <TextField className={b('field')}
          label={t('filter.date-from', 'Дата (от)')}
          inverted
          value={createdFrom}
          type="date"
          onChange={onDateFromChange}
        />
        <TextField className={b('field')}
          label={t('filter.date-to', 'Дата (до)')}
          inverted
          value={createdTo}
          type="date"
          onChange={onDateToChange}
        />
      </div>
    </div>
  );
};

export default PPFilter;
