import { useState } from 'react';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import Container from '@shared/components/container/container';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import type { TEventsFilter } from '@/modules/line';
import { EventsFilterMobile, PageControlsMobile } from '@/modules/line';
import { DataType, withEventsFilterItems } from '@/modules/line';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import './line-screen-mobile.scss';

const EventsFilterWithItems = withEventsFilterItems(EventsFilterMobile);

const initialFilter: TEventsFilter = {
  sport: '0',
  country: '0',
  tournament: '0'
};

const dataType: DataType = 'line';

const b = bemCn('line-screen-mobile');

const LineScreenMobile = () => {
  const [filter, setFilter] = useState(initialFilter);
  useScrollToTop();

  const handleFilterChange = (newFilter: Partial<TEventsFilter>) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newFilter
    }));
  };

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        <PageControlsMobile className={b('controlls')} dataType='line' />
        <EventsFilterWithItems
          dataType={dataType}
          filter={filter}
          onFilteChange={handleFilterChange}
        />
      </Container>
    </div>
  );
};

export default LineScreenMobile;
