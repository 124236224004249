import { HandledError, handleQueryError } from '@/shared/utils/helpers/handle-query-error';

type CreateProjectError = {
  root?: string;
  name?: string;
}

export const handleCreatePartnerProjectError = (error: HandledError) => {
  const queryError = handleQueryError(error);

  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;

    if (typeof detail === 'string') {
      const errors: CreateProjectError = {};
      switch (detail) {
        case 'Project with this name exists':
          errors.name = 'Такой проект уже существует';
          break;
        default:
          errors.root = detail;
      }

      return errors;
    }

    if (Array.isArray(detail)) {
      const errors: CreateProjectError = {};
      detail.forEach((err) => {
        const location = err.loc?.[1];
        const message = err.msg;

        switch (location) {
          case 'name':
            errors.name = message;
            break;
          default:
            errors.root = message;
        }
      });

      return errors;
    }
  }
};

type CreatePromocodeError = {
  root?: string;
  promocode?: string;
  scriptId?: string;
}


export const handleCreatePartnerPromocodeError = (error: HandledError) => {
  const queryError = handleQueryError(error);

  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;

    if (typeof detail === 'string') {
      const errors: CreatePromocodeError = {};
      switch (detail) {
        case 'Promocode already exists':
          errors.promocode = 'Промокод уже существует';
          break;
        default:
          errors.root = detail;
      }

      return errors;
    }

    if (Array.isArray(detail)) {
      const errors: CreatePromocodeError = {};
      detail.forEach((err) => {
        const location = err.loc?.[1];
        const message = err.msg;

        switch (location) {
          case 'promocode':
            errors.promocode = message;
            break;
          case 'scriptId':
            errors.scriptId = message;
            break;
          default:
            errors.root = message;
        }
      });

      return errors;
    }
  }
};
