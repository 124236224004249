import { useState } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import defaultLogo from '@assets/team-logo-default.png';
import './event-card-team.scss';

type EventCardTeamProps = {
  className?: string;
  logo: string;
  name: string;
  size?: 'md' | 'lg';
}

const b = bemCn('event-card-team');
const EventCardTeam = ({ className, logo, name, size }: EventCardTeamProps) => {
  const [isLogoError, setLogoError] = useState(false);

  return (
    <div className={b({ size }, className)}>
      <p className={b('logo')}>
        <img
          className={b('logo-img')}
          src={isLogoError ? defaultLogo : logo}
          alt=""
          onError={() => setLogoError(true)}
          loading='lazy'
        />
      </p>
      <p className={b('name')}>{name}</p>
    </div>
  );

};

export default EventCardTeam;
