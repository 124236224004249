import Button, { IButtonProps } from '@/shared/components/button/button';
import { useState } from 'react';
import CreatePartnerPromocodeModal from '../create-partner-promocode-modal/create-partner-promocode-modal';


const CreatePartnerPromocodeBtn = ({ children, ...props }: IButtonProps) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Button {...props} onClick={() => setOpened(true)}>
        {children}
      </Button>
      <CreatePartnerPromocodeModal
        isOpen={opened}
        onClose={() => setOpened(false)}
      />
    </>
  );
};

export default CreatePartnerPromocodeBtn;
