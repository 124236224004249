import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { useToggle } from 'usehooks-ts';
import './pp-togled-card.scss';

const b = bemCn('pp-togled-card');

type PPToggledCardProps = {
  className?: string;
  title: string;
  desc?: string;
  children?: React.ReactNode;
}

const PPToggledCard = ({
  className,
  title,
  desc,
  children
}: PPToggledCardProps) => {
  const [opened, toggleOpened] = useToggle(false);

  return (
    <div className={b({ opened }, className)}>
      <div className={b('head')} onClick={toggleOpened} role="button">
        {title && <p className={b('title')}>{title}</p>}
        {desc && <p className={b('desc')}>{desc}</p>}
        <IconSvg className={b('toggler')} name="chevron-down" />
      </div>
      {opened && (
        <div className={b('content')}>
          {children}
        </div>
      )}
    </div>
  );
};

export { PPToggledCard };
