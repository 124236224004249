import ResultModal from '@/shared/components/result-modal/result-modal';
import { useTranslation } from 'react-i18next';

type RegisterSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
}


const RegisterSuccessModal = ({ isOpen, onClose }: RegisterSuccessModalProps) => {
  const { t } = useTranslation();
  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.confirmation.success.title', 'Поздравляем!')}
      description={t('result-modal.confirmation.success.description', 'Вы успешно зарегистрированы на нашем сайте')}
      status='success'
    />
  );
};

export default RegisterSuccessModal;
