import { baseApi } from '@/shared/store/api';

import type {
  GetNotificationsParams,
  Notifications,
} from '../model/types';
import { adapteNotifications } from '../model/adapter';

const notificationsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUnreadNotificationsCount: build.query<number, void>({
      query: () => ({
        url: '/v1/user/notifications/unread'
      }),
      providesTags: ['notifications'],
    }),

    getNotifications: build.query<Notifications, GetNotificationsParams>({
      query: (params) => ({
        url: '/v1/user/notifications',
        params: {
          ...params,
          'page_size': params.pageSize,
        },
      }),
      transformResponse: adapteNotifications,
      providesTags: ['notifications'],
    }),

    markReadNotifications: build.mutation<void, void>({
      query: () => '/v1/user/notifications/mark_read',
      invalidatesTags: ['notifications'],
    }),

  }),
});

export const {
  useGetUnreadNotificationsCountQuery,
  useGetNotificationsQuery,
  useMarkReadNotificationsMutation,
} = notificationsApi;

export default notificationsApi;
