import { bemCn } from '@shared/utils/helpers/bem-cn';
import Button from '@shared/components/button/button';
import { useEffect, useRef } from 'react';
import { useOnClickOutside, useToggle } from 'usehooks-ts';
import Flag from '@shared/components/flag/falg';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_LANGS, DEFAULT_LANG } from '@/app-config';
import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from '@/modules/user';
import { useAppSelector } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';
import './lang-selector.scss';

const b = bemCn('lang-selector');


const langLabel: Record<string, string> = {
  'uk': 'ua',
  'kk': 'kz'
};

const flagByLang: Record<string, string> = {
  'uk': 'UA',
  'kk': 'KZ',
  'da': 'DK'
};


type LangSelectorProps = {
  className?: string;
  dropdown?: 'bottom' | 'top';
}

const LangSelector = ({ className, dropdown }: LangSelectorProps) => {
  const { i18n } = useTranslation();
  const isAuth = useAppSelector(getIsAuth);
  const { data: userSettiongs } = useGetUserSettingsQuery(undefined, { skip: !isAuth });
  const userLang = userSettiongs?.lang;
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [isOpen, toggelIsOpen, setIsOpen] = useToggle(false);
  const activeLang = i18n.resolvedLanguage ?? DEFAULT_LANG;
  const selectorRef = useRef<HTMLDivElement>(null);

  const closeDropdown = () => setIsOpen(false);
  const handleLangClick = (newLang: string) => {
    closeDropdown();
    if (i18n.resolvedLanguage !== newLang) {
      i18n.changeLanguage(newLang);
      if (userSettiongs) {
        updateUserSettings({
          ...userSettiongs,
          lang: newLang
        });
      }
      window.location.reload();
    }
  };

  useOnClickOutside(selectorRef, closeDropdown);

  useEffect(() => {
    if (!!userLang && userLang !== activeLang && AVAILABLE_LANGS.includes(userLang)) {
      i18n.changeLanguage(userLang);
    }
  }, [userLang]);

  return (
    <div className={b({ dropdown, opened: isOpen }, className)} ref={selectorRef}>
      <Button className={b('button')} variant="secondary" onClick={toggelIsOpen}>
        <span className={b('text')}>{langLabel[activeLang] ?? activeLang}</span>
        <Flag className={b('icon')} countryCode={flagByLang[activeLang] ?? activeLang} />
      </Button>
      <ul className={b('options')}>
        {AVAILABLE_LANGS.map((item) => (
          <li className={b('option')} key={item}>
            <Button className={b('button', { active: item === activeLang })} variant="secondary"
              onClick={() => handleLangClick(item)}
            >
              <span className={b('text')}>{langLabel[item] ?? item}</span>
              <Flag className={b('icon')} countryCode={flagByLang[item] ?? item} width={15} height={15} />
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LangSelector;
