import { bemCn } from '@/shared/utils/helpers/bem-cn';
import type { TransactionItem } from '../../store/types';
import { getTransactionDiff, getTransactionTitle } from '../../utils/transactions';
import { currenciesTitles } from '@/shared/utils/constants/currency';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { useTranslation } from 'react-i18next';
import './transaction-item.scss';
import { useAppSelector } from '@/shared/hooks';
import { getUserData } from '../../store/selectors';

type TransactionItemProps = {
  className?: string;
  transaction: TransactionItem;
  inverted?: boolean;
}

const b = bemCn('transaction-item');
const TransactionCard = ({
  className,
  transaction,
  inverted,
}: TransactionItemProps) => {
  const { t } = useTranslation();
  const { id } = useAppSelector(getUserData);
  const {
    created,
    amount,
    currency,
    type,
    userIdTo,
    userIdFrom,
  } = transaction;

  const diff: string = amount < 0 ? 'decrease' : getTransactionDiff(type);
  const isTransfer = type === 'user_user_transfer';
  const transferTitle = id === userIdTo
    ? `${t('transactions.transfer-from')} ${userIdFrom}`
    : `${t('transactions.transfer-to')} ${userIdTo}`;

  return (
    <div className={b({ inverted }, className)}>
      <div className={b('row')}>
        <p className={b('name')}>
          {isTransfer
            ? transferTitle :
            getTransactionTitle(type)}
        </p>
        <p className={b('date')}>
          {dayjsCustom(created).format('DD.MM.YYYY / HH:mm')}
        </p>
      </div>
      <div className={b('row')}>
        <p className={b('currency')}>
          {t('transactions.account', 'Счет')}: {currenciesTitles[currency].title ?? currency}
        </p>
        <p className={b('amount', { type: diff })}>
          {diff === 'increase' && '+'}
          {diff === 'decrease' && '-'}
          {amount} {currenciesTitles[currency].symbol ?? currency}
        </p>
      </div>
    </div>
  );
};

export default TransactionCard;
