import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/shared/hooks';
import { getPrimaryWallet } from '@/modules/user/store/selectors';
import { WithdrawalMethodsItem } from '@shared/store/app/types';
import AppModal from '@shared/components/app-modal/app-modal';
import WithdrawalForm from '../withdrawal-forms/withdrawal-form';
import WithdrawalFormPhone from '../withdrawal-forms/withdrawal-form-phone';
import WithdrawalFormBank from '../withdrawal-forms/withdrawal-form-bank';


type WithdrawalModalProps = {
  method: WithdrawalMethodsItem | null;
  isOpen: boolean;
  onClose?: () => void;
}

const WithdrawalModal = ({ method, isOpen, onClose }: WithdrawalModalProps) => {
  const { t } = useTranslation();
  const primaryWallet = useAppSelector(getPrimaryWallet);

  const renderForm = () => {
    if (!method || !primaryWallet) {
      return null;
    }

    switch (method.name.toLocaleLowerCase()) {
      case 'card':
        return (
          <WithdrawalFormPhone
            method={method}
            balance={primaryWallet.amount}
          />
        );
      case 'sbp':
        return (
          <WithdrawalFormBank
            method={method}
            balance={primaryWallet.amount}
          />
        );
      default:
        return (
          <WithdrawalForm
            method={method}
            balance={primaryWallet.amount}
          />
        );
    }
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('withdrawals.title', 'Вывод')}
    >
      {renderForm()}
    </AppModal>
  );
};

export default WithdrawalModal;
