import { useNavigate } from 'react-router-dom';

import { bemCn } from '@shared/utils/helpers/bem-cn';
import Flag from '@shared/components/flag/falg';
import GradientIcon, { GRADIENT_COLORS } from '@shared/components/gradient-icon/gradient-icon';
import IconSvg from '@shared/components/icon-svg/icon-svg';
import Loader from '@shared/components/loader/loader';
import { ITournamentItem } from '../../model/line';
import { FC } from 'react';
import FilterContext, { useFilterContext } from '../../model/filterContext';
import type { TEventsFilter } from '../../model/types';
import { IEventsFilterCountry, IEventsFilterSport } from '../../model/types';
import type { ColorName as GradientColor } from '@shared/components/gradient-icon/gradient-icon';
import './events-filter-mobile.scss';

const b = bemCn('events-filter-mobile');

type TournamentBtnProps = {
  tournament: ITournamentItem;
}

const TournamentBtn: FC<TournamentBtnProps> = ({ tournament }) => {
  const navigate = useNavigate();
  const { name, id, dataType } = tournament;
  return (
    <button className={b('tournament-btn')}
      type="button"
      onClick={() => navigate(`/${dataType}/events/${id}`)}
    >
      {name}
    </button>
  );
};

type CountryBtnProps = {
  country: IEventsFilterCountry;
  active: boolean;
}

const CountryBtn: FC<CountryBtnProps> = ({ country, active }) => {
  const { onFilteChange } = useFilterContext();
  return (
    <button className={b('country-btn', { active })}
      type="button"
      onClick={() => onFilteChange({
        country: active ? '' : country.id,
        tournament: '0',
      })}
    >
      <Flag className={b('country-icon')} countryId={country.id} />
      <span className={b('country-name')}>{country.name}</span>
      <IconSvg className={b('country-toggle-icon')} name="chevron-down" />
    </button>
  );
};

type SportBtnProps = {
  sport: IEventsFilterSport;
  active: boolean;
  color?: GradientColor;
  disabled?: boolean;
}

const SportBtn: FC<SportBtnProps> = ({ sport, active, color, disabled }) => {
  const { onFilteChange } = useFilterContext();
  return (
    <button className={b('sport-btn', { active })}
      disabled={disabled}
      type="button"
      onClick={() => onFilteChange({
        sport: active ? '0' : sport.id,
        country: '0',
        tournament: '0',
      })}
    >
      <GradientIcon className={b('sport-icon')}
        iconName={sport.icon}
        color={color ?? 'purple'}
      />
      <span className={b('sport-name')}>{sport.name}</span>
      {sport.counter ? (
        <span className={b('sport-counter')}>{sport.counter}</span>
      ) : null}
    </button>
  );
};

type CountryItemProps = {
  country: IEventsFilterCountry;
};

const CountryItem: FC<CountryItemProps> = ({ country }) => {
  const { filter } = useFilterContext();
  const isActive = country.id === filter.country;
  return (
    <div className={b('country-item')}>
      <CountryBtn country={country} active={isActive} />
      {isActive && country.tournaments.map((tournament) => (
        <TournamentBtn key={tournament.id} tournament={tournament} />
      ))}
    </div>
  );
};

type SportItemProps = {
  sport: IEventsFilterSport;
  index: number;
  disabled?: boolean;
}

const SportItem: FC<SportItemProps> = ({ sport, index, disabled }) => {
  const { filter } = useFilterContext();
  const isActive = sport.id === filter.sport;
  return (
    <div className={b('sport-item')}>
      <SportBtn
        sport={sport}
        active={isActive}
        color={GRADIENT_COLORS[index % GRADIENT_COLORS.length]}
        disabled={disabled}
      />
      {isActive && !disabled && sport.countries.map((country) => (
        <CountryItem key={country.id} country={country} />
      ))}
    </div>
  );
};

const ItemsLoader = () => (
  <div className={b('loader-wrapper')}>
    <Loader className={b('loader')} />
    {new Array(10).fill(null).map((_, i) => (
      <SportItem
        key={i}
        index={i}
        sport={{
          id: '',
          name: '',
          counter: 0,
          icon: 'cup',
          dataType: 'line',
          countries: []
        }}
        disabled
      />
    ))}
  </div>
);


type EventsFilterMobileProps = {
  className?: string;
  items: IEventsFilterSport[];
  loading?: boolean;
  filter: TEventsFilter;
  onFilteChange: (newFilter: Partial<TEventsFilter>) => void;
}

const EventsFilterMobile: FC<EventsFilterMobileProps> = ({
  className,
  items,
  loading,
  filter,
  onFilteChange,
}) => (
  <FilterContext.Provider value={{ filter, onFilteChange }}>
    <div className={b({ loading }, className)}>
      {loading && <ItemsLoader />}
      {!loading && items.map((sport, index) => (
        <SportItem key={sport.id} sport={sport} index={index} />
      ))}
    </ div>
  </FilterContext.Provider>
);

export default EventsFilterMobile;
