import ReactModal from 'react-modal';
import React, { useEffect, useState } from 'react';

import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '../icon-svg/icon-svg';
import { existVerticalScroll, getBodyScrollTop } from '@/shared/utils/helpers/common';
import './app-modal.scss';

type AppModalProps = {
  className?: string;
  classNameTitle?: string;
  classNameDesc?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  contentLabel?: string;
  id?: string;
  title?: React.ReactNode;
  description?: string;
  style?: 'dark' | 'light';
}

const b = bemCn('app-modal');

const AppModal = (props: AppModalProps) => {
  const {
    className,
    classNameTitle,
    classNameDesc,
    children,
    id,
    isOpen,
    onClose,
    contentLabel,
    title,
    description,
    style = 'light',
  } = props;

  const [scrollOffset, setScrollOffset] = useState<number | null>(null);

  useEffect(() => {
    if (isOpen) {
      if (existVerticalScroll()) {
        setScrollOffset(getBodyScrollTop());
        document.body.style.top = `-${getBodyScrollTop()}px`;
        document.body.classList.add('body-scroll-fixed');
      }
    } else if (scrollOffset !== null) {
      document.body.style.top = '';
      document.body.classList.remove('body-scroll-fixed');
      window.scrollTo(0, scrollOffset);
      setScrollOffset(null);
    }
  }, [isOpen]);


  return (
    <ReactModal
      portalClassName={b()}
      className={b('content', { style }, className)}
      overlayClassName={b('overlay', { 'fade-in': isOpen, 'fade-out': !isOpen })}

      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={200}

      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel={contentLabel}
      bodyOpenClassName={null}
      id={id}
    >
      <button className={b('close-button')} type='button' onClick={onClose}>
        <IconSvg className={b('close-icon')} name='close' />
      </button>
      {title && <h2 className={b('title', classNameTitle)}>{title}</h2>}
      {description && <p className={b('desc', classNameDesc)}>{description}</p>}
      {children}
    </ReactModal>
  );
};

export default AppModal;
