import { useTranslation } from 'react-i18next';
import ResultModal from '@/shared/components/result-modal/result-modal';


type PasswordResetSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
}


const PasswordResetSuccessModal = ({
  isOpen,
  onClose,
}: PasswordResetSuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.confirmation.reset-success.title', 'Пароль успешно изменен')}
      description={t('result-modal.confirmation.reset-success.description', 'Можете войти в аккаунт')}
      status='success'
    />
  );
};

export default PasswordResetSuccessModal;
