import { useState, useEffect, useCallback } from 'react';
import {
  useGetNotificationsQuery,
  useGetUnreadNotificationsCountQuery,
  useMarkReadNotificationsMutation,
} from '../api/notifications-api';

import { selectNotificationsFromResult } from '../model/selectors';

const notificationsPerPage = 5;

const initParams = {
  page: 1,
  pageSize: notificationsPerPage,
};

export const useNotifications = () => {
  const [params, setParams] = useState(initParams);
  const [markRead] = useMarkReadNotificationsMutation();
  const { data: newNotificationsCount = 0, isSuccess } = useGetUnreadNotificationsCountQuery();
  const {
    notifications,
    count,
    isLoading: isNotificationLoading,
    isFetching: isNotificationsFetching,
    isSuccess: isNotificationsLoaded,
  } = useGetNotificationsQuery(params, {
    skip: !isSuccess,
    selectFromResult: (result) => ({
      ...result,
      ...selectNotificationsFromResult(result)
    })
  });

  useEffect(() => {
    if (newNotificationsCount && isNotificationsLoaded) {
      markRead();
    }
  }, [isNotificationsLoaded, newNotificationsCount, markRead]);

  const isShowMoreBtn = notifications.length < count;

  const handleShowMoreClick = useCallback(() => {
    setParams((prev) => ({
      ...prev,
      pageSize: prev.pageSize + notificationsPerPage
    }));
  }, []);

  return {
    newNotificationsCount,
    isNotificationLoading,
    isNotificationsFetching,
    notifications,
    handleShowMoreClick,
    isShowMoreBtn,
  };
};
