import { useCountdown } from 'usehooks-ts';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useEffect } from 'react';
import './event-board-timer.scss';

type EventBoardTimerProps = {
  className?: string;
  time: number;
  isStoped?: boolean;
}

const b = bemCn('event-board-timer');

const EventBoardTimer = (props: EventBoardTimerProps) => {
  const { className, time, isStoped } = props;
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: time,
    isIncrement: true,
  });

  useEffect(() => {
    if (!isStoped) {
      startCountdown();
    }
  }, []);

  useEffect(() => {
    resetCountdown();
    if (!isStoped) {
      startCountdown();
    }
  }, [time]);


  const second = count % 60;
  const minute = Math.floor(count / 60);

  return (
    <p className={b(null, className)}>
      <span className={b('item')}>{minute}</span>
      <span className={b('divider')}>:</span>
      <span className={b('item')}>{second}</span>
    </p>
  );
};

export default EventBoardTimer;
