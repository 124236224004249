import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import './protected-screen.scss';

const b = bemCn('protected-screen');

const ProtectedScreen = () => {
  const navigate = useNavigate();

  // TODO: Убрать редирект, или сделатьего "умным", чтобы при разлогинивании редиректило, а при обычном переходе показывало заглушку
  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div className={b('')}>
      <h1 className={b('title')}>У вас нет доступа к этой странице</h1>
    </div>
  );
};

export default ProtectedScreen;
