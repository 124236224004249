import flagsSprite from '@assets/flags/sprite.svg';
import { getCountryIcon, getCountryIconByCode } from '@shared/utils/helpers/flag-icon';
import { bemCn } from '@/shared/utils/helpers/bem-cn';

import './flag.scss';

type Props = {
  countryId?: string;
  countryCode?: string;
  countryName?: string;
  className?: string;
  width?: number;
  height?: number;
};

const b = bemCn('flag');

const Flag = (props: Props) => {
  const {
    countryId,
    countryCode,
    countryName,
    className,
    width = 18,
    height = 18,
  } = props;
  let icon = '';
  if (countryId) {
    icon = getCountryIcon(countryId);
  } else if (countryCode) {
    icon = getCountryIconByCode(countryCode);
  } else if (countryName) {
    icon = countryName;
  } else {
    icon = 'world';
  }

  return (
    <svg className={b(null, className)} width={width} height={height}>
      <use xlinkHref={`${flagsSprite}#flag-${icon}`}></use>
    </svg>
  );
};

export default Flag;
