import { useTranslation } from 'react-i18next';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useCreatePartnerLinkMutation } from '../../../api/partnerApi';
import AppModal from '@/shared/components/app-modal/app-modal';
import Form from '@/shared/components/form/form';
import Button from '@/shared/components/button/button';
import * as yup from 'yup';
import './create-partner-link-modal.scss';

const b = bemCn('create-partner-link-modal');

type FormData = {
  projectId: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object({
  projectId: yup.string().required('Введите название'),
});

type CreatePartnerLinkModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const CreatePartnerLinkModal = ({
  isOpen,
  onClose,
}: CreatePartnerLinkModalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [createLink] = useCreatePartnerLinkMutation();

  const handleFormSubmit = (formData: FormData) => {
    console.log('New Promocode', formData);

    createLink({
      projectId: Number(formData.projectId)
    });
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      className={b()}
      title="Создать прект"
    >
      <Form<FormData>
        className={b('form')}
        onSubmit={handleFormSubmit}
        form={{ schema }}
      >
        <Form.TextField
          className={b('field')}
          name='name'
          label={t('title', 'Название')}
          placeholder={t('enter-project-title', 'Введите название проекта')}
        />
        <Button type='submit'>
          {t('add-project', 'Добавить проект')}
        </Button>
      </Form>
    </AppModal>
  );
};

export default CreatePartnerLinkModal;
