import { getUseExtraWallets, getUserData } from '@/modules/user';
import { ProfileCard, ProfileNav } from '@/modules/profile';
import Button from '@shared/components/button/button';
import Container from '@shared/components/container/container';
import IconSvg from '../../shared/components/icon-svg/icon-svg';
import { useAppSelector, useDispatchedActions } from '../../shared/hooks';
import { useLogoutMutation } from '@/modules/auth';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useTranslation } from 'react-i18next';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import './profile-screen.scss';


const b = bemCn('profile-screen');

const ProfileScreen = () => {
  useScrollToTop();
  const { t } = useTranslation();
  const [serverLogout] = useLogoutMutation();
  const { logout } = useDispatchedActions();
  const userData = useAppSelector(getUserData);
  const extraWallets = useAppSelector(getUseExtraWallets);

  const handleLogoutClick = async () => {
    await serverLogout();
    logout();
  };

  return (
    <div className={b()}>
      <Container className={b('container')}>
        <div className={b('header')}>
          <h1 className={b('title')}>
            {t('main-nav.profile', 'Личный кабинет')}
          </h1>
          <Button className={b('logout-btn')}
            variant="text"
            onClick={handleLogoutClick}
          >
            {t('user-nav.logout', 'Выйти')}
            <IconSvg className={b('logout-icon')}
              name='exit'
              width={12}
              height={12}
            />
          </Button>
        </div>
        <ProfileCard className={b('card')}
          userData={userData}
          extraWallets={extraWallets}
        />
        <ProfileNav className={b('nav')} />
      </Container>
    </div>
  );
};


export default ProfileScreen;
