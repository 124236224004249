import { Link, useLocation } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { ClassNameFormatter } from '@bem-react/classname';
import casinoPreview from '@assets/casino-preview.png?url';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import { useState } from 'react';
import casinoCardsImg from '@/assets/icons/icon-casino-cards.svg?url';
import { CasinoGame, getProviderIconUrl } from '@/modules/casino';

import { useAddGameToFavoriteMutation, useDeleteFavoriteGameMutation } from '@/modules/user/store/user-api';
import { useAppSelector } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';
import './casino-game-card.scss';

const b = bemCn('casino-game-card');

const getLabel = (bem: ClassNameFormatter, gameName: string, label?: string | React.ReactNode) => {
  if (typeof label === 'string') {

    const icon = getProviderIconUrl(label.toLocaleLowerCase());

    return (
      <p className={bem('label')}>
        {icon && <img className={b('label-icon')} src={icon} alt={`${label} ${gameName}`} />}
        <span className={bem('labet-text')}>{label}</span>
      </p>
    );
  }

  return label;
};

type CasinoGameCardProps = {
  className?: string;
  name?: string;
  link?: string;
  isFavorite?: boolean;
  preview?: string;
  gameProvider?: string;
  game: CasinoGame;
}

const CasinoGameCard = (props: CasinoGameCardProps) => {
  const {
    className,
    preview = casinoPreview,
    name = 'Название игры',
    link = '#',
    isFavorite = false,
    gameProvider,
    game,
  } = props;
  const location = useLocation();
  const [isImgError, setImgError] = useState(false);
  const isAuth = useAppSelector(getIsAuth);

  const [addToFavorite, { isLoading: isAddLoading }] = useAddGameToFavoriteMutation();
  const [deleteFavorite, { isLoading: isDeleteLoading }] = useDeleteFavoriteGameMutation();

  const handleFavoriteClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (!isAddLoading && !isDeleteLoading) {
      if (isFavorite && !!game.favoriteId) {
        deleteFavorite(game.favoriteId);
      } else {
        addToFavorite({
          gameId: game.id,
          gameName: game.name,
          provider: game.provaider,
          mainProvider: game.mainProvider,
        });
      }
    }
  };

  return (
    <div className={b(null, className)}>
      <Link className={b('link')} to={link}
        state={{ returnUrl: location.pathname }}
      >
        <p className={b('picture')}>
          <img className={b('img', { error: isImgError })}
            src={isImgError ? casinoCardsImg : preview}
            onError={() => setImgError(true)}
            alt={name}
            loading='lazy'
          />
        </p>
        <p className={b('name')}>{name}</p>
        {getLabel(b, name, gameProvider)}
        {isAuth && (
          <ButtonIcon className={b('favorite', { active: isFavorite })}
            onClick={handleFavoriteClick}
            icon='heart'
            variant='secondary'
            disabled={isAddLoading || isDeleteLoading}
          >
            <span className='visually-hidden'>Add game to favorite</span>
          </ButtonIcon>
        )}
      </Link>
    </div>
  );
};

export default CasinoGameCard;
