import { bemCn } from '@shared/utils/helpers/bem-cn';
import type { Coupon } from '../../model/types';
import { CouponCard } from '../coupon-card/coupon-card';
import { memo, useMemo } from 'react';
import Loader from '@/shared/components/loader/loader';
import './coupon-list.scss';

const MemoizedCouponCard = memo(CouponCard);

const b = bemCn('coupon-list');

type CouponListProps = {
  className?: string;
  coupons?: Coupon[];
  loading?: boolean;
}

const CouponList = ({ className, coupons = [], loading }: CouponListProps) => {
  const isEmpty = coupons.length === 0;

  const memoizedCoupons = useMemo(() => coupons.map((coupon) => (
    <MemoizedCouponCard className={b('item')}
      key={coupon.id}
      coupon={coupon}
    />
  )), [coupons]);


  return (
    <div className={b({ loading }, className)}>
      {!loading && isEmpty && <p>Список пуст</p>}
      {!loading && memoizedCoupons}
      {loading && <Loader className={b('loader')} />}
    </div>
  );
};

export { CouponList };
