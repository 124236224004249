import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NameSpace } from '@/shared/utils/constants/common';
// import userApi from '@/modules/user/store/user-api';

import type { PPState } from './types';

const initialState: PPState = {
  ppSelectedCurrency: '',
};

const ppSlice = createSlice({
  name: NameSpace.PartnerProgram,
  initialState,
  reducers: {
    changeSelectedCurrency: (state, action: PayloadAction<string>) => {
      state.ppSelectedCurrency = action.payload;
    },
  },
  // extraReducers(builder) {
  //   builder
  //     .addMatcher(
  //       userApi.endpoints.getUserWallets.matchFulfilled,
  //       (state, action) => {
  //         const primaryWallet = action.payload.find((wallet) => wallet.isPrimary);
  //         state.ppSelectedCurrency = primaryWallet?.currency ?? '';
  //       },
  //     );
  // }
});

export default ppSlice;
