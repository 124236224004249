import { useEffect, useState } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import GradientIcon, { GRADIENT_COLORS } from '@shared/components/gradient-icon/gradient-icon';
import './live-filter-mobile.scss';
import { ISportItem } from '../../model/line';
import Loader from '@/shared/components/loader/loader';

const b = bemCn('live-filter-mobile');

type LiveFilterMobileProps = {
  className?: string;
  items: ISportItem[];
  activeItem?: string;
  onChange?: (id: string) => void;
  loading?: boolean;
};

const LiveFilterMobile = (props: LiveFilterMobileProps) => {
  const { className, items, activeItem, onChange, loading } = props;
  const [activeId, setActiveId] = useState<string | null>(activeItem ?? items[0]?.id ?? null);

  useEffect(() => {
    if (activeId) {
      onChange?.(activeId);
    }
  }, [activeId]);

  return (
    <div className={b(null, className)}>
      <div className={b('list', { loading })}>
        {loading && <Loader className={b('spinner')} />}
        {items.map(({ id, name, counter, icon }, i) => (
          <button className={b('item', { active: activeId === id })}
            key={id}
            type="button"
            onClick={() => setActiveId(id)}
          >
            <GradientIcon className={b('icon-wrapper')}
              iconName={icon}
              color={GRADIENT_COLORS[i % GRADIENT_COLORS.length]}
            />
            <span className={b('name')}>{name}</span>
            <span className={b('counter')}>{counter}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default LiveFilterMobile;
