import { getToken } from '../utils/helpers/token';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// !! getToken() импортируется из слоя выше

const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  prepareHeaders: (headers) => {
    const token = getToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery,
  tagTypes: [
    'Auth',
    'basket',
    'partner-projects',
    'partner-links',
    'partner-promocodes',
    'settings',
    'wallets',
    'withdrawals',
    'favorite-games',
    'favorite-matches',
    'notifications',
  ],
  endpoints: () => ({}),
});
