import { useOnClickOutside, useToggle } from 'usehooks-ts';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useEffect, useRef } from 'react';
import './dropdown.scss';

type RenderParams = {
  toggel: () => void;
  open: () => void;
  close: () => void;
  isOpened?: boolean;
}

type DropdownProps = {
  className?: string;
  classNameContent?: string;
  renderTriger: (params: RenderParams) => JSX.Element;
  renderContent: (params: RenderParams) => JSX.Element | null;
  open?: boolean;
}

const Dropdown = ({
  className,
  classNameContent,
  renderTriger,
  renderContent,
  open = false,
}: DropdownProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [opened, toggleOpened, setOpened] = useToggle(open);

  useOnClickOutside(ref, () => setOpened(false));

  useEffect(() => {
    if (open !== opened) {
      setOpened(open);
    }
  }, [open]);

  // TODO: Добавить закрытие попапа по ESC

  const b = bemCn('dropdown');

  return (
    <div className={b({ opened }, className)} ref={ref}>
      {renderTriger({
        toggel: () => toggleOpened(),
        open: () => setOpened(true),
        close: () => setOpened(false),
      })}

      <div className={b('content', classNameContent)}>
        {renderContent({
          toggel: () => toggleOpened(),
          open: () => setOpened(true),
          close: () => setOpened(false),
          isOpened: opened,
        })}
      </div>
    </div>
  );
};

export default Dropdown;
