
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg, { AvailableIcon } from '@/shared/components/icon-svg/icon-svg';
import { CouponStatus } from '../../model/types';
import './icon-status.scss';

type IconStatusProps = {
  className?: string;
  status: CouponStatus;
}

const statusIconName: Record<CouponStatus, AvailableIcon> = {
  win: 'check',
  loss: 'close',
  placed: 'clock',
  refund: 'refresh',
  recalc: 'refresh',
};

const b = bemCn('icon-status');

const IconStatus = ({ status, className }: IconStatusProps) => (
  <span className={b({ status }, className)}>
    <IconSvg className={b('img')} name={statusIconName[status]} />
  </span>
);


export default IconStatus;
