import { DEFAULT_TIMEZONE } from '@/app-config';

const TIMEZONE_STORAGE_KEY = 'timezone';

export const saveTimezone = (value: string) => {
  localStorage.setItem(TIMEZONE_STORAGE_KEY, value);
};

export const getTimezone = () =>
  localStorage.getItem(TIMEZONE_STORAGE_KEY) ?? String(DEFAULT_TIMEZONE);

export const getTimezoneNumber = () => Number(getTimezone());
