import { PropsWithCN } from '@shared/types/common';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import './casino-filter-provider.scss';

const b = bemCn('casino-filter-provider');

type CasinoFilterProviderProps = PropsWithCN & {
  onClick: () => void;
  icon?: string;
  name: string;
  counter: number;
  active?: boolean;
}

const CasinoFilterProvider = ({
  className,
  icon,
  name,
  counter,
  active,
  onClick
}: CasinoFilterProviderProps) => (
  <button className={b({ active }, className)} onClick={onClick} type='button'>
    {icon && (<img className={b('icon')} src={icon} />)}
    <span className={b('name')}>{name}</span>
    <span className={b('counter')}>{counter}</span>
  </button>
);

export default CasinoFilterProvider;
