import { useTranslation } from 'react-i18next';
import { PartnerLink } from '../../../model/types';
import PPTable from '../../core/pp-table/pp-table';
import PartnerLinkStatsBtn from '../partner-link-stats-btn/partner-link-stats-btn';
import ManagerUserLinkStatsBtn from '../manager-user-link-stats-btn/manager-user-link-stats-btn';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './partner-links-table.scss';

const b = bemCn('partner-promocodes-table');

type ParnterLinksTableProps = {
  links: PartnerLink[];
  role?: 'partner' | 'manager';
}

const ParnterLinksTable = ({ links, role = 'partner' }: ParnterLinksTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const getStatsBtns = (link: PartnerLink): React.ReactNode => {
    if (role === 'partner') {
      return (
        <PartnerLinkStatsBtn
          className={b('controls-btn')}
          link={link}
        />
      );
    } else if (role === 'manager') {
      return (
        <ManagerUserLinkStatsBtn
          className={b('controls-btn')}
          link={link}
        />
      );
    }
  };

  return (
    <PPTable<PartnerLink>
      dataSource={links}
      className={b()}
    >
      <PPTable.Column<PartnerLink>
        dataIndex=""
        title=""
        render={(_, link) => getStatsBtns(link)}
      />
      <PPTable.Column<PartnerLink>
        dataIndex="projectName"
        title={t('project', 'Проект')}
      />
      <PPTable.Column<PartnerLink>
        dataIndex="link"
        title={t('link', 'Ссылка')}
      />
      <PPTable.Column<PartnerLink>
        dataIndex=""
        title={t('created', 'Создан')}
        render={(_, { created }) => dayjsCustom(created).format('DD:MM:YYYY')}
      />
    </PPTable>
  );
};

export default ParnterLinksTable;
