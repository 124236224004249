import { SubmitHandler } from 'react-hook-form';
import i18n from '@/shared/i18n/i18n';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Button from '@shared/components/button/button';
import * as yup from 'yup';
import Form from '@shared/components/form/form';
import { useTranslation } from 'react-i18next';
import { SUPPORT_LINK } from '@/app-config';

import './password-reset-form.scss';

const b = bemCn('password-reset-form');

export type PasswordResetFormData = {
  email: string;
};

const schema = yup
  .object({
    email: yup.string()
      .email(i18n.t('reset-pass.errors.wrong-email', 'Введите корректный E-mail'))
      .required(i18n.t('reset-pass.errors.empty-email', 'Введите E-mail')),
  })
  .required();

const defaultValues: PasswordResetFormData = {
  email: '',
};

type PasswordResetFormProps = {
  className?: string;
  onFormSubmit?: SubmitHandler<PasswordResetFormData>;
  loading?: boolean;
};

const PasswordResetForm = (props: PasswordResetFormProps) => {
  const {
    className,
    onFormSubmit,
    loading,
  } = props;

  const { t } = useTranslation();

  return (
    <Form<PasswordResetFormData>
      className={b(null, className)}
      onSubmit={onFormSubmit}
      form={{
        disabled: loading,
        defaultValues,
        schema,
      }}
    >
      <Form.TextField<PasswordResetFormData>
        className={b('field')}
        name="email"
        placeholder="E-mail"
        disabled={loading}
      />

      <Button className={b('button', { forget: true })}
        variant="text"
        disabled={loading}
      >
        <a
          href={SUPPORT_LINK}
          target="_blank"
          rel="noreferrer"
        >
          {t('reset-pass.forgot-email', 'Не помню e-mail')}
        </a>
      </Button>

      <Button className={b('button')}
        type="submit"
        disabled={loading}
      >
        {t('reset-pass.send-request', 'Отправить')}
      </Button>
    </Form>
  );
};

export default PasswordResetForm;
