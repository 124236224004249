import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { ImgHTMLAttributes } from 'react';


type TImageSmart = ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  srcSet?: string;
  variants?: {
    type?: 'image/webp';
    srcSet: string;
    media?: string;
  }[];
}

type ImgSmartPorps = {
  image: TImageSmart;
  className?: string;
}

const b = bemCn('img-smart');

const ImgSmart = ({ image, className }: ImgSmartPorps) => {
  const { variants, ...atr } = image;

  return (
    <picture>
      {variants?.map((variant) => (
        <source {...variant} key={variant.srcSet} />
      ))}

      <img {...atr} className={b(null, className)} />
    </picture>
  );
};

export default ImgSmart;
