import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import CasinoProviderCard from '../casino-provider-card/casino-provider-card';
import IconSvg from '../../../../shared/components/icon-svg/icon-svg';
import { useTranslation } from 'react-i18next';

import pragmaticLogo from './assets/pragmatic-play-logo.png';
import smartsoftLogo from './assets/smartsoft-logo.png';
import mascotLogo from './assets/mascot-logo.png';
import tvbetLogo from './assets/tvbet_logo.png';
import betgamesLogo from './assets/betgames-logo.png';
import agtLogo from './assets/agt-logo.png';
import twainLogo from './assets/twain-small.png';
import elcasinoLogo from './assets/el-casino-logo.svg';
import fastSport from './assets/fastsport-logo.png';
import aviatrixLogo from './assets/aviatrix-logo-flat.svg';
import inbetLogo from './assets/inbet-light.png';
import fivemenLogo from './assets/fivemen-light.png';
import endorphinaLogo from './assets/endorphina-logo.png';

import './casino-providers-section.scss';

const providersList = [
  {
    name: 'pragmatic play',
    logoUrl: pragmaticLogo,
    link: '/games?category=all-providers&provider=pragmatic',
  },
  {
    name: 'Stmartsoft gaming',
    logoUrl: smartsoftLogo,
    link: '/games?category=all-providers&provider=SmartSoft',
  },
  {
    name: 'Endorphina',
    logoUrl: endorphinaLogo,
    link: '/games?category=all-providers&provider=endorphina',
  },
  {
    name: 'Mascot gaming',
    logoUrl: mascotLogo,
    link: '/games?category=all-providers&provider=mascot',
  },
  {
    name: 'Aviatrix',
    logoUrl: aviatrixLogo,
    link: '/games?category=all-providers&provider=aviatrix',
  },
  {
    name: 'tvbet',
    logoUrl: tvbetLogo,
    link: '/games?category=all-providers&provider=tvbet',
  },
  {
    name: 'agt',
    logoUrl: agtLogo,
    link: '/games?category=all-providers&provider=agt',
  },
  {
    name: 'fastSport',
    logoUrl: fastSport,
    link: '/games?category=all-providers&provider=fastsport',
  },
  {
    name: 'elcasinoLogo',
    logoUrl: elcasinoLogo,
    link: '/games?category=all-providers&provider=El+Casino',
  },
  {
    name: 'Inbet',
    logoUrl: inbetLogo,
    link: '/games?category=all-providers&provider=inbetgames',
  },
  {
    name: '5men',
    logoUrl: fivemenLogo,
    link: '/games?category=all-providers&provider=5Men',
  },
  {
    name: 'betgames',
    logoUrl: betgamesLogo,
    link: '/games?category=all-providers&provider=betgames',
  },
  {
    name: 'twain-sport',
    logoUrl: twainLogo,
    link: '/games?category=all-providers&provider=Twain+sport',
  },
];

const b = bemCn('casino-providers-section');

type CasinoProvidersSectionProps = {
  className?: string;
  title?: string;
  linkTitle?: string;
  link?: string;
  bg?: boolean;
}

const CasinoProvidersSection = (props: CasinoProvidersSectionProps) => {
  const { t } = useTranslation();
  const {
    className,
    title = t('casino-widget.providers', 'Провайдеры'),
    linkTitle = t('casino-widget.all', 'Все'),
    link = '#',
    bg
  } = props;

  return (
    <section className={b({ bg }, className)}>
      <div className={b('wrapper')}>
        <div className={b('head')}>
          <h2 className={b('title')}>{title}</h2>
          <Link className={b('link-more')} to={link} state={{ scrollToView: true }}>
            {linkTitle}
            <IconSvg className={b('link-more-icon')} name="arrow-right" />
          </Link>
        </div>
        <div className={b('list', 'no-scrollbar')}>
          {providersList.map((item) => (
            <CasinoProviderCard className={b('item')}
              key={item.name}
              logoUrl={item.logoUrl}
              name={item.name}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CasinoProvidersSection;
