import { useTranslation } from 'react-i18next';
import { PartnerProject } from '@/modules/partner-program/model/types';
import PPCard from '../../core/pp-card/pp-card';
import ProjectStatsBtn from '../project-stats-btn/project-stats-btn';
import ProjectCreateLinkBtn from '../project-create-link-btn/project-create-link-btn';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './partner-project-card.scss';

const b = bemCn('partner-project-card');

type PartnerProjectCardProps = {
  project: PartnerProject;
}

const PartnerProjectCard = ({
  project,
}: PartnerProjectCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const { id, name, created } = project;

  const items = [
    {
      label: 'ID',
      value: id
    },
    {
      label: `${t('project-title', 'Название проекта')}`,
      value: name
    },
    {
      label: `${t('created', 'Создан')}`,
      value: dayjsCustom(created).format('DD.MM.YYYY')
    },
  ];

  return (
    <PPCard
      items={items}
      contorls={(
        <>
          <ProjectStatsBtn
            className={b('controls-btn')}
            project={project}
          />
          <ProjectCreateLinkBtn
            className={b('controls-btn')}
            project={project}
          />
        </>
      )}
    />
  );
};

export default PartnerProjectCard;
