import { PPNavigation, PPNavigationItem, PPWalletControls } from '@/modules/partner-program';
import Container from '@shared/components/container/container';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { Outlet } from 'react-router-dom';
import PartnerNavigationMobile from '../pp-navigation/partner-navigation-mobile/partner-navigation-mobile';
import { useResponsive } from '@/shared/hooks/use-responsive';
import './partner-program-layout.scss';

type PartnerProgramLayoutProps = {
  navItems: PPNavigationItem[];
}

const PartnerProgramLayout = ({ navItems }: PartnerProgramLayoutProps) => {
  const b = bemCn('partner-program-layout');
  const { isMobile } = useResponsive();

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <div className={b('navigation')}>
          <div className={b('top-controls')}>
            <PPWalletControls />
          </div>
          <div className={b('navigation-list')}>
            {isMobile
              ? <PartnerNavigationMobile items={navItems} />
              : <PPNavigation items={navItems} />}
          </div>
        </div>
        <div className={b('content')}>
          <Outlet />
        </div>
      </Container>
    </div>
  );
};

export { PartnerProgramLayout };
