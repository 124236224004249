import { bemCn } from '@/shared/utils/helpers/bem-cn';
import IconSvg from '../icon-svg/icon-svg';
import { useCallback, useEffect, useState } from 'react';
import './button-to-top.scss';
import { scrollToTop } from '@/shared/utils/helpers/common';

const b = bemCn('button-to-top');
const ButtonToTop = () => {
  const [isShowButton, setShowButton] = useState(false);

  const handleWindowScroll = useCallback(() => {
    if (window.pageYOffset > 600) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, []);


  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, []);

  const handleOnClick = useCallback(() => {
    scrollToTop(true);
  }, []);

  if (!isShowButton) {
    return null;
  }

  return (
    <button className={b()} type="button" onClick={handleOnClick}>
      <IconSvg className={b('icon')} name="chevron-down" />
    </button>
  );
};

export default ButtonToTop;
