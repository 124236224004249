import React from 'react';
import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg, { AvailableIcon } from '../icon-svg/icon-svg';
import './button.scss';

export interface IButtonProps {
  children?: React.ReactNode;
  onClick?: (evt: React.MouseEvent) => void;
  className?: string;
  variant?: 'primary' | 'secondary' | 'bordered' | 'text';
  loading?: boolean;
  loadingType?: 'dots';
  to?: string;
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  leftIcon?: AvailableIcon;
  rightIcon?: AvailableIcon;
  ariaLabel?: string;
  disabled?: boolean;
}

const b = bemCn('button');

const Button = (props: IButtonProps) => {
  const {
    children,
    onClick,
    className,
    variant = 'primary',
    loading = false,
    loadingType,
    to,
    href,
    type = 'button',
    leftIcon,
    rightIcon,
    ariaLabel,
    disabled,
  } = props;

  const buttonClasses = b({
    primary: variant === 'primary',
    secondary: variant === 'secondary',
    bordered: variant === 'bordered',
    text: variant === 'text',
    loading,
    disabled
  }, className);

  const handleClick = (evt: React.MouseEvent) => {
    if (loading || disabled) {
      evt.preventDefault();
    } else {
      onClick?.(evt);
    }
  };

  if (href) {
    return (
      <a className={buttonClasses}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        aria-label={ariaLabel}
      >
        {leftIcon && <IconSvg className={b('icon', { left: true })} name={leftIcon} />}
        {children}
        {rightIcon && <IconSvg className={b('icon', { right: true })} name={rightIcon} />}
      </a>
    );
  }

  if (to) {
    return (
      <Link className={buttonClasses}
        to={loading ? '#' : to}
        onClick={handleClick}
        aria-label={ariaLabel}
      >
        {leftIcon && <IconSvg className={b('icon', { left: true })} name={leftIcon} />}
        {children}
        {rightIcon && <IconSvg className={b('icon', { right: true })} name={rightIcon} />}
      </Link>
    );
  }

  return (
    <button className={buttonClasses}
      type={type}
      onClick={handleClick}
      aria-label={ariaLabel}
      disabled={loading || disabled}
    >
      {leftIcon && <IconSvg className={b('icon', { left: true })} name={leftIcon} />}
      {loading && loadingType === 'dots' ? (
        <div className={b('loading-dots')} >
          <span className={b('loading-dots-placeholder')}>_</span>
        </div>
      ) : children}
      {rightIcon && <IconSvg className={b('icon', { right: true })} name={rightIcon} />}
    </button>
  );
};

export default React.memo(Button);
