import { bemCn } from '@shared/utils/helpers/bem-cn';
import './container.scss';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
}

const b = bemCn('container');

const Container = ({ children, className }: ContainerProps) => (
  <div className={b(null, className)}>
    {children}
  </div>
);

export default Container;
