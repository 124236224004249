import { createSelector } from '@reduxjs/toolkit';
import { CasinoGame } from './types';
import casinoApi from '../api/casino-api';
import { NameSpace } from '@/shared/utils/constants/common';
import { getProviderIconUrl } from '@/modules/casino';
import { selectAppSectionStatus } from '@/shared/store/app/selectors';
import i18n from '@/shared/i18n/i18n';
import { AppFeature, EXCLUDE_CASINO_CATEGORY } from '@/app-config';

import type { CasinoSortType, GetPragmaticLobbyGames } from '@/modules/casino/model/types';
import { getFavoritesGames } from '@/modules/user/store/selectors';
import { getStoredCasinoGames } from '../utils/casino-localstorage';
import { getCasinoGameLink } from '../utils/get-casino-game-link';

export type ProviderItem = {
  id: string;
  iconUrl?: string;
  name: string;
  counter: number;
}

export type CasinoCategoryItem = {
  id: string;
  name: string;
}

type FilterParams = {
  provider: string;
  category: string;
  search: string;
  sort?: CasinoSortType;
  favorites?: boolean;
}

type UniqId = string;

const isShowAllGamesByFilter = (filter: FilterParams) => (filter.category === 'all' || filter.category === 'all-providers')
  && !filter.search
  && filter.provider === 'all'
  && !filter.favorites;

export const selectInbetGamesResult = casinoApi.endpoints.getInbetGames.select();
export const selectRplGamesResult = casinoApi.endpoints.getRplGames.select();
export const selectVegasGamesResult = casinoApi.endpoints.getVegasGames.select();
export const selectMascotGamesResult = casinoApi.endpoints.getMascotGames.select();
export const selectDigitainGamesResult = casinoApi.endpoints.getDigitainGames.select();
export const selectSmartsoftGamesResult = casinoApi.endpoints.getSmartsoftGames.select();
export const selectAgtGamesResult = casinoApi.endpoints.getAgtGames.select();
export const selectFivemenGamesResult = casinoApi.endpoints.getFivemenGames.select();
export const selectPragmaticGamesResult = casinoApi.endpoints.getPragmaticGames.select();
export const selectPragmaticLobbyGamesResult = (state: RootState, params: GetPragmaticLobbyGames) =>
  casinoApi.endpoints.getPragmaticLobbyGames.select(params)(state);
export const selectEndorphinaGamesResult = casinoApi.endpoints.getEndorphinaGames.select();
export const selectLastwinsResult = casinoApi.endpoints.getLastwins.select();

export const getInbetGames = createSelector(
  selectInbetGamesResult,
  (state: RootState) => selectAppSectionStatus(state, 'inbet'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('inbet/games');

    return storedGames ?? [];
  },
);

export const getRplGames = createSelector(
  selectRplGamesResult,
  (state: RootState) => selectAppSectionStatus(state, 'inbet'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('rpl/games');

    return storedGames ?? [];
  },
);

export const getVegasGames = createSelector(
  selectVegasGamesResult,
  (state: RootState) => selectAppSectionStatus(state, 'vegas'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('vegas/games');

    return storedGames ?? [];
  },
);

export const getMascotGames = createSelector(
  selectMascotGamesResult,
  (state: RootState) => selectAppSectionStatus(state, 'mascot'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('mascot/games');

    return storedGames ?? [];
  },
);

export const getDigitainGames = createSelector(
  selectDigitainGamesResult,
  // (state: RootState) => selectAppSectionStatus(state, 'digitain'),
  // ({ data = [] }, isEnabled) => isEnabled ? data : [],
  ({ data = [] }) => AppFeature.DIGITAIN_GAMES ? data : [],
);

export const getSmartsoftGames = createSelector(
  selectSmartsoftGamesResult,
  (state: RootState) => selectAppSectionStatus(state, 'smartsoft'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('smartsoft/games');

    return storedGames ?? [];
  },
);

export const getAgtGames = createSelector(
  selectAgtGamesResult,
  (state: RootState) => selectAppSectionStatus(state, 'agt'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('agt/games');

    return storedGames ?? [];
  },
);

export const getFivemenGames = createSelector(
  selectFivemenGamesResult,
  (state: RootState) => selectAppSectionStatus(state, 'fivemen'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('fivemen/games');

    return storedGames ?? [];
  },
);

export const getPragmaticGames = createSelector(
  selectPragmaticGamesResult,
  (state: RootState) => selectAppSectionStatus(state, 'pragmatic'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('pragmatic/games');

    return storedGames ?? [];
  },
);

export const getPragmaticAllLobbyGames = createSelector(
  (state: RootState) => selectPragmaticLobbyGamesResult(state, 'all'),
  (state: RootState) => selectAppSectionStatus(state, 'pragmatic'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('pragmatic/lobby_games/all');

    return storedGames ?? [];
  },
);

export const getPragmaticNewLobbyGames = createSelector(
  (state: RootState) => selectPragmaticLobbyGamesResult(state, 'new'),
  (state: RootState) => selectAppSectionStatus(state, 'pragmatic'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('pragmatic/lobby_games/new');

    return storedGames ?? [];
  },
);

export const getPragmaticHotLobbyGames = createSelector(
  (state: RootState) => selectPragmaticLobbyGamesResult(state, 'hot'),
  (state: RootState) => selectAppSectionStatus(state, 'pragmatic'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('pragmatic/lobby_games/hot');

    return storedGames ?? [];
  },
);

export const getPragmaticLobbyGames = createSelector(
  getPragmaticGames,
  // getPragmaticAllLobbyGames,
  getPragmaticNewLobbyGames,
  getPragmaticHotLobbyGames,
  (state: RootState) => selectAppSectionStatus(state, 'pragmatic'),
  (totalGames, newGames, hotGames, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    return totalGames.map((game) => {
      const newGame = newGames.find(({ id }) => id === game.id);
      const hotGame = hotGames.find(({ id }) => id === game.id);
      return {
        ...game,
        category: [
          newGame ? 'new' : '',
          hotGame ? 'hot' : ''
        ]
      };
    });
  }
);

export const getBetgamesList = createSelector(
  (state: RootState) => state[NameSpace.Casino].betgamesList,
  (state: RootState) => selectAppSectionStatus(state, 'betgames'),
  (data, isEnabled) => isEnabled ? data : [],
);

export const getTwainSportList = createSelector(
  (state: RootState) => state[NameSpace.Casino].twainSportList,
  (state: RootState) => selectAppSectionStatus(state, 'betgames'),
  (data, isEnabled) => isEnabled ? data : [],
);

export const getTvbetList = createSelector(
  (state: RootState) => state[NameSpace.Casino].tvbetList,
  (state: RootState) => selectAppSectionStatus(state, 'tvbet'),
  (data, isEnabled) => isEnabled ? data : [],
);

export const getElCasinotList = createSelector(
  (state: RootState) => state[NameSpace.Casino].elCasinoList,
  (state: RootState) => selectAppSectionStatus(state, 'tvbet'),
  (data, isEnabled) => isEnabled ? data : [],
);

export const getAviatrixList = createSelector(
  (state: RootState) => state[NameSpace.Casino].aviatrix,
  (state: RootState) => selectAppSectionStatus(state, 'aviatrix'),
  (data, isEnabled) => isEnabled ? data : [],
);

export const getFastSportList = createSelector(
  (state: RootState) => state[NameSpace.Casino].fastSportList,
  (state: RootState) => selectAppSectionStatus(state, 'tvbet'),
  (data, isEnabled) => isEnabled ? data : [],
);

export const getEndorphinaGames = createSelector(
  selectEndorphinaGamesResult,
  (state: RootState) => selectAppSectionStatus(state, 'endorphina'),
  ({ data = [], isSuccess, isError }, isEnabled) => {
    if (!isEnabled) {
      return [];
    }

    if (isSuccess || isError) {
      return data;
    }

    const storedGames = getStoredCasinoGames('endorphina/games');

    return storedGames ?? [];
  },
);

export const getTotalGamesList = createSelector(
  getFavoritesGames,
  getInbetGames,
  getRplGames,
  getMascotGames,
  getSmartsoftGames,
  getAgtGames,
  getFivemenGames,
  getDigitainGames,
  getPragmaticLobbyGames,
  getEndorphinaGames,
  getBetgamesList,
  getTwainSportList,
  getTvbetList,
  getFastSportList,
  getElCasinotList,
  getAviatrixList,
  (favoriteGames, ...games) => {

    const gamesById: Record<UniqId, CasinoGame> = {};
    const gamesByCategory: Record<string, UniqId[]> = {};
    const gamesByProvider: Record<string, UniqId[]> = {};
    const gamesByMainProvider: Record<string, UniqId[]> = {};

    const favoriteMap = new Map(
      favoriteGames.map((favorite) =>
        [`${favorite.gameId}|${favorite.gameName}|${favorite.provider}|${favorite.mainProvider}`, favorite]
      )
    );

    games.forEach((providerGames) => providerGames.forEach((game) => {
      const favoriteKey = `${game.id}|${game.name}|${game.provaider}|${game.mainProvider}`;
      const favorite = favoriteMap.get(favoriteKey);
      const isFavorite = !!favorite;
      const favoriteId = favorite?.id;
      const uniqId: UniqId = `${game.mainProvider}|${game.id}`;

      gamesById[uniqId] = {
        ...game,
        isFavorite,
        favoriteId
      };

      // Индекс по provider
      if (!gamesByProvider[game.provaider]) {
        gamesByProvider[game.provaider] = [];
      }
      gamesByProvider[game.provaider].push(uniqId);

      // Индекс по mainProvider
      if (!gamesByMainProvider[game.mainProvider]) {
        gamesByMainProvider[game.mainProvider] = [];
      }
      gamesByMainProvider[game.mainProvider].push(uniqId);

      // Индекс по категориям
      [...game.category, ...game.type].forEach((category) => {
        if (!gamesByCategory[category.toLowerCase()]) {
          gamesByCategory[category.toLowerCase()] = [];
        }
        gamesByCategory[category.toLowerCase()].push(uniqId);
      });
    }));


    return {
      gamesById,
      gamesByCategory,
      gamesByProvider,
      gamesByMainProvider,
    };
  }
);

export const getCasinoGamesByCategory = createSelector(
  getTotalGamesList,
  (_: RootState, category: string) => category.toLowerCase(),
  ({ gamesById, gamesByCategory }, category): CasinoGame[] => {

    if (category !== 'favorites') {
      const gameIds = gamesByCategory[category] || [];
      return gameIds.map((id) => gamesById[id]);
    }
    if (category === 'favorites') {
      return Object.values(gamesById).filter((item) => item.isFavorite);
    }
    return [];
  }
);

export const getCasinoGamesByProvider = createSelector(
  getTotalGamesList,
  (_: RootState, provider: string) => provider.toLowerCase(),
  ({ gamesById, gamesByProvider }, provider): CasinoGame[] => {
    const gameIds = gamesByProvider[provider] || [];
    return gameIds.map((id) => gamesById[id]);
  }
);

export const getGamesList = createSelector(
  getTotalGamesList,
  ({ gamesById }) => Object.values(gamesById)
);

export const getCasinoGamesByFilter = createSelector(
  getGamesList,
  (_: RootState, filter: FilterParams) => filter,
  (gamesList, filter): CasinoGame[] => {
    const sortedGamesList = gamesList
      .sort(({ name: nameA }, { name: nameB }) =>
        filter.sort === 'descending'
          ? nameB.localeCompare(nameA)
          : nameA.localeCompare(nameB)
      );

    if (isShowAllGamesByFilter(filter)) {
      return sortedGamesList;
    }

    return sortedGamesList.filter((game) => {
      const isCategory = filter.category !== 'all' && filter.category !== 'all-providers'
        ? game.type.some((item) => item.toLowerCase() === filter.category.toLowerCase())
        || game.category.some((item) => item.toLowerCase() === filter.category.toLowerCase())
        : true;
      const isProvider = filter.provider !== 'all' ? game.provaider === filter.provider : true;
      const isSearch = filter.search ? game.name.toLowerCase().includes(filter.search.toLowerCase()) : true;
      const isFavorite = filter.favorites ? game.isFavorite : true;

      return isCategory && isProvider && isFavorite && isSearch;
    });
  }
);

export const getTotalGamesCount = createSelector(
  getGamesList,
  (list) => list.length,
);

export const getProvidersList = createSelector(
  getTotalGamesList,
  getTotalGamesCount,
  ({ gamesByProvider }, totalCount) => {

    const providersList: ProviderItem[] = Object.keys(gamesByProvider)
      .sort((a, b) => a.localeCompare(b))
      .map((provider) => ({
        id: provider,
        name: provider,
        iconUrl: getProviderIconUrl(provider),
        counter: gamesByProvider[provider].length,
      }));

    return [
      {
        id: 'all',
        name: i18n.t('common.all', 'Все'),
        counter: totalCount,
      },
      ...providersList,
    ];
  }
);

export const getCasinoCategories = createSelector(
  getTotalGamesList,
  ({ gamesByCategory }): CasinoCategoryItem[] => {
    const categories: CasinoCategoryItem[] = [
      {
        id: 'all',
        name: i18n.t('casino-widget.all-games', 'Все игры')
      },
      {
        id: 'all-providers',
        name: i18n.t('casino-widget.all-providers', 'Все провайдеры')
      },
    ];

    for (const category in gamesByCategory) {
      if (!!category && !EXCLUDE_CASINO_CATEGORY.includes(category.toLocaleLowerCase())) {
        categories.push({ id: category, name: category });
      }
    }

    return categories;
  }
);

export const getLastWins = createSelector(
  selectLastwinsResult,
  getSmartsoftGames,
  ({ data: lastWins = [] }, smartsoftGames) =>
    lastWins.map((item) => {
      const gameLink = item.provider === 'smartsoft'
        ? smartsoftGames.find((smartsoftGame) => smartsoftGame.gameId === item.gameId)?.link ?? ''
        : getCasinoGameLink(item.provider, item.gameId);

      return {
        ...item,
        link: gameLink
      };
    })
);
