import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import SidebarGamesItem from './sidebar-games-item';

import type { CasinoGame } from '@/modules/casino';

import './sidebar-games.scss';

type Props = {
  items: CasinoGame[];
  className?: string;
};

const b = bemCn('sidebar-games');

const SidebarGames = ({ items, className }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={b(null, className)}>
      <div className={b('head')}>
        <h2 className={b('title')}>{t('main-nav.casino', 'Казино')}</h2>
        <Link className={b('link-more')} to='/games'>
          {t('all', 'Все')}
          <IconSvg className={b('link-more-icon')} name="arrow-right" />
        </Link>
      </div>

      <div className={b('list')}>
        {items.slice(0, 10).map((game) => (
          <SidebarGamesItem
            className={b('item')}
            key={game.id + game.link}
            name={game.name}
            link={game.link}
            preview={game.preview}
          />
        ))}
      </div>
    </div>
  );
};

export default SidebarGames;
