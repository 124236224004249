import BalanceInfo from '@/shared/components/balance-info/balance-info';
import Button from '@/shared/components/button/button';
import { useDispatchedActions } from '@/shared/hooks';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { getAmountString, roundNumber } from '@/shared/utils/helpers/common';
import './main-wallet-info.scss';
import { useTranslation } from 'react-i18next';

const b = bemCn('main-wallet-info');


type MainWalletInfoProps = {
  className?: string;
  mainAmount: number;
  bonusAmount: number;
  cashbackLine: number;
  cashbackCasino: number;
  currency: string;
  style?: 'dark' | 'light';
}

const MainWalletInfo = ({
  className,
  mainAmount,
  bonusAmount,
  cashbackLine,
  cashbackCasino,
  currency,
  style
}: MainWalletInfoProps) => {
  const { t } = useTranslation();
  const { changeAppModal } = useDispatchedActions();
  const handleTopupClick = () => changeAppModal('topup');
  const handleWithdrawalClick = () => changeAppModal('withdrawal');

  const mainBalance = getAmountString(roundNumber(mainAmount).toFixed(2), currency);
  const bonusBalance = getAmountString(roundNumber(bonusAmount).toFixed(2), currency);
  const cashbackLineBalance = getAmountString(roundNumber(cashbackLine).toFixed(2), currency);
  const cashbackCasinoBalance = getAmountString(roundNumber(cashbackCasino).toFixed(2), currency);

  return (
    <div className={b(null, className)}>
      <BalanceInfo
        className={b('balance', b('main-balance'))}
        title={`${t('wallets.main-wallet', 'Основной счет')}:`}
        value={mainBalance}
        icon='coin-colored'
        iconColor='purple'
        style={style}
      />
      <div className={b('controls')}>
        <Button className={b('controls-btn')}
          variant="bordered"
          onClick={handleWithdrawalClick}
        >
          {t('wallets.withdrawal', 'Вывод')}
        </Button>
        <Button className={b('controls-btn')}
          onClick={handleTopupClick}
        >
          {t('wallets.topup', 'Пополнить')}
        </Button>
      </div>
      <div className={b('extra-balances')}>
        <BalanceInfo
          className={b('balance')}
          title={t('wallets.bonus-wallet', 'Бонусный счет')}
          value={bonusBalance}
          icon='piggy-bank'
          iconColor='yellow'
          style={style}
        />
        <BalanceInfo
          className={b('balance')}
          title={t('wallets.casino-bonuses', 'Кэшбэк казино')}
          value={cashbackCasinoBalance}
          icon='coin'
          iconColor='pink'
          style={style}
        />
        <BalanceInfo
          className={b('balance')}
          title={t('wallets.sport-bonuses', 'Кэшбэк ставки')}
          value={cashbackLineBalance}
          icon='cup'
          iconColor='green'
          style={style}
        />
      </div>
    </div>
  );
};

export default MainWalletInfo;
