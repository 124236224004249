import { useResponsive } from '@/shared/hooks/use-responsive';
import { scrollToTop } from '@/shared/utils/helpers/common';
import PageHeader from '@/widgets/page-header/components/page-header/page-header';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';


const GameScreenLayout = () => {
  const { isMobile } = useResponsive();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <PageHeader
        hideNav={isMobile}
        showRetornButton
      />
      <Outlet />
    </>
  );
};

export default GameScreenLayout;
