import { SubmitHandler } from 'react-hook-form';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Button from '@shared/components/button/button';
import { useAppSelector } from '../../../../shared/hooks';
import { getCurrenciesOptions } from '@shared/store/app/selectors';
import { PropsWithChildren } from 'react';
import * as yup from 'yup';
import Form from '@shared/components/form/form';
import { UseYupFormProps } from '@/shared/hooks/useForm';
import { RegisterFormContext, useRegisterFormContext } from './register-form-context';
import './register-form.scss';
import CurrencyOption from '@/shared/components/currency-option/currency-option';
import RegisterPromocode from './register-promocode/register-promocode';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type RegisterFormError<T extends object = RegisterFormData> = UseYupFormProps<T>['error'];
export type RegisterFormData<T extends object = object> = Omit<{
  password: string;
  currency: string;
  confirmPassword: string;
  agree: boolean;
  promocode?: string;
}, keyof T> & T;

export const baseSchema: yup.ObjectSchema<RegisterFormData> = yup.object({
  currency: yup.string().required('Выберите валюту'),
  // name: yup.string()
  //   .required('Введите имя')
  //   .test(
  //     'is-two-words',
  //     'Поле должно состоять как минимум из двух слов',
  //     (value) => value.trim().split(/\s+/).length >= 2
  //   ),
  password: yup.string()
    .min(8, 'Пароль должен содержать 8 символов')
    .required('Введите пароль'),
  confirmPassword: yup.string()
    .min(8, 'Пароль должен содержать 8 символов')
    .required('Введите пароль')
    .oneOf([yup.ref('password')], 'Пароли должны совпадать'),
  agree: yup.bool().required().oneOf([true], 'Необходимо согласие'),
  promocode: yup.string(),
}).required();


// const defaultValues: RegisterFormData = {
//   password: '',
//   confirmPassword: '',
//   currency: '',
//   agree: false,
// };

export type RegisterFormProps<T extends object> = PropsWithChildren & {
  className?: string;
  form?: UseYupFormProps<T>;
  loading?: boolean;
  error?: UseYupFormProps<T>['error'];
  onFormSubmit?: SubmitHandler<T>;
  onLoginClick?: () => void;
}

const b = bemCn('register-form');

const RegisterForm = <T extends object>({
  className,
  loading,
  error,
  onFormSubmit = () => undefined,
  onLoginClick,
  children,
  form,
}: RegisterFormProps<T>) => {
  const { t } = useTranslation();
  const excludedCurrencies = ['FUN', 'DEMO', 'BUSD', 'TZS', 'KES'];
  const currenciesOptions = useAppSelector(getCurrenciesOptions).filter((item) => !excludedCurrencies.includes(item.value));

  return (
    <RegisterFormContext.Provider value={{ loading }}>
      <Form<T> className={b(null, className)}
        onSubmit={onFormSubmit}
        form={{
          disabled: loading,
          error,
          ...form,
        }}
      >
        <Form.RootError className={b('error')} />
        <Form.Select
          className={b('field')}
          name="currency"
          placeholder={t('reg.modal.choose-currency', 'Выберите валюту')}
          disabled={loading}
          options={currenciesOptions}
          renderOption={(option) => (
            <CurrencyOption currency={option.value} label={option.label} />
          )}
        />
        {children}
        <Form.TextField
          className={b('field')}
          type='password'
          name="password"
          // placeholder="Ваш пароль"
          placeholder={t('reg.modal.password', 'Пароль')}
          disabled={loading}
        />
        <Form.TextField
          className={b('field')}
          type='password'
          name="confirmPassword"
          placeholder={t('reg.modal.repeat-password', 'Повторите пароль')}
          disabled={loading}
        />
        <RegisterPromocode
          className={b('promocode')}
        />
        <Form.Checkbox
          className={b('field')}
          name="agree"
          label={
            <Trans i18nKey="reg.modal.agree-prvivacy">
              Я согласен на обработку персональных данных и согласен с
              <Link to="/rules/privacy-policy" className={b('link')}>
                политикой конфиденциальности
              </Link>
            </Trans>
          }
          disabled={loading}
        />
        <Button className={b('button')}
          type="submit"
          disabled={loading}
        >
          {t('reg.modal.submit-btn', 'Зарегистрироваться')}
        </Button>
        <Button className={b('button')}
          variant="bordered"
          onClick={onLoginClick}
          disabled={loading}
        >
          {t('reg.modal.sign-in', 'Войти')}
        </Button>
      </Form>
    </RegisterFormContext.Provider>
  );
};

const Currency = () => {
  const currenciesOptions = useAppSelector(getCurrenciesOptions);
  const { loading } = useRegisterFormContext();

  return (
    <Form.Select
      className={b('field')}
      name="currency"
      placeholder='Валюта'
      disabled={loading}
      options={currenciesOptions}
      renderOption={(option) => (
        <CurrencyOption currency={option.value} label={option.label} />
      )}
    />
  );
};

const Email = () => {
  const { loading } = useRegisterFormContext();
  return (
    <Form.TextField
      className={b('field')}
      type='email'
      name="email"
      placeholder="E-mail"
      disabled={loading}
    />
  );
};

const Telegram = () => {
  const { t } = useTranslation();
  const { loading } = useRegisterFormContext();
  return (
    <Form.TextField
      className={b('field')}
      type='text'
      name="telegram"
      placeholder={t('reg.modal.telegram-nickname')}
      disabled={loading}
    />
  );
};

const Name = () => {
  const { t } = useTranslation();
  const { loading } = useRegisterFormContext();
  return (
    <Form.TextField
      className={b('field')}
      type='text'
      name="name"
      placeholder={t('reg.modal.user-name', 'ФИО')}
      disabled={loading}
    />
  );
};

const Password = () => {
  const { t } = useTranslation();
  const { loading } = useRegisterFormContext();
  return (
    <Form.TextField
      className={b('field')}
      type='password'
      name="password"
      placeholder={t('reg.modal.password', 'Пароль')}
      disabled={loading}
    />
  );
};

const ConfirmPassword = () => {
  const { loading } = useRegisterFormContext();
  return (
    <Form.TextField
      className={b('field')}
      type='password'
      name="confirmPassword"
      placeholder="Повторите пароль"
      disabled={loading}
    />
  );
};

const Agree = () => {
  const { loading } = useRegisterFormContext();
  return (
    <Form.Checkbox
      className={b('field')}
      name="agree"
      label='Я согласен на обработку персональных данных и согласен с политикой конфиденциальности'
      disabled={loading}
    />
  );
};

RegisterForm.Currency = Currency;
RegisterForm.Email = Email;
RegisterForm.Telegram = Telegram;
RegisterForm.Name = Name;
RegisterForm.Password = Password;
RegisterForm.ConfirmPassword = ConfirmPassword;
RegisterForm.Agree = Agree;

export default RegisterForm;
