import { bemCn } from '@shared/utils/helpers/bem-cn';
import Button from '@shared/components/button/button';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import { useCopyToClipboard } from 'usehooks-ts';

import { useAppSelector, useDispatchedActions } from '@shared/hooks';
import { getUserData } from '@/modules/user';
import { getAmountString, roundNumber } from '@shared/utils/helpers/common';
import { useGetUserWalletsQuery } from '@/modules/user';
import UserMenuDropdown from '../user-menu-dropdown/user-menu-dropdown';
import ButtonMenu from '@shared/components/button-menu/button-menu';
import Mobile from '@/shared/components/responsive/mobile';
import Desktop from '@/shared/components/responsive/desktop';
import LangSelector from '@/widgets/core/components/lang-selector/lang-selector';
import { UserWalletDropdown } from '@/modules/user-wallet';
import NotificationsDropdown from '../notifications-dropdown/notifications-dropdown';
import { getIsAuth } from '@/modules/auth';
import { getBalanceHidden } from '@/shared/store/app/selectors';
import { useTranslation } from 'react-i18next';
import './user-nav.scss';
import { SUPPORT_LINK } from '@/app-config';

const b = bemCn('user-nav');

type AuthButtonsProps = {
  onLoginClick: () => void;
  onRegisterClick: () => void;
}

const AuthButtons = ({ onLoginClick, onRegisterClick }: AuthButtonsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Button className={b('button')} variant="secondary" onClick={onLoginClick}>
        {t('user-nav.login', 'Вход')}
      </Button>
      <Button className={b('button')} onClick={onRegisterClick}>
        {t('user-nav.registration', 'Регистрация')}
      </Button>
    </>
  );
};

type UserNavProps = {
  className?: string;
}

const UserNav = (props: UserNavProps) => {
  const { className } = props;
  const { t } = useTranslation();
  const isAuth = useAppSelector(getIsAuth);
  const [, copyID] = useCopyToClipboard();
  // const { isLoading: isAuthLoading } = useCheckAuthQuery();
  const { isLoading: isWalletsLoading } = useGetUserWalletsQuery(undefined, { skip: !isAuth });
  const { changeAuthModal: chengeAuthModal, changeAppModal } = useDispatchedActions();
  const isBalanceHidden = useAppSelector(getBalanceHidden);
  const {
    id: userId,
    wallet: {
      amount: userBalance,
      currency,
    },
  } = useAppSelector(getUserData);
  const handleIdClick = () => {
    copyID(`ID: ${userId}`);
  };


  const handleLoginClick = () => chengeAuthModal('login');
  const handleRegisterClick = () => chengeAuthModal('registration');
  const handleOpenTopupClick = () => changeAppModal('topup');
  const handleBalanceClickMobile = () => changeAppModal('wallet-manage');

  return (
    <div className={b(null, className)}>
      <Mobile>
        {isAuth && !(isWalletsLoading) ? (
          <>
            <Button className={b('button', { id: true })}
              variant="secondary"
              onClick={handleIdClick}
              to="/profile"
            >
              ID: {userId}
            </Button>
            <Button className={b('button', { wallet: true })}
              variant="secondary"
              onClick={handleBalanceClickMobile}
            >
              {getAmountString(roundNumber(userBalance), currency, isBalanceHidden)}
            </Button>
            <ButtonIcon className={b('button', { topup: true })}
              icon="piggy-bank"
              onClick={handleOpenTopupClick}
            />
            <ButtonMenu className={b('button')} />
          </>
        ) : (
          <AuthButtons
            onLoginClick={handleLoginClick}
            onRegisterClick={handleRegisterClick}
          />
        )}
      </Mobile>
      <Desktop>
        <ButtonIcon className={b('button')}
          icon="support"
          variant="secondary"
          href={SUPPORT_LINK}
        />
        <LangSelector className={b('button')} />

        {isAuth ? (
          <>
            <Button className={b('button', { id: true })}
              variant="secondary"
              onClick={handleIdClick}
            >
              ID: {userId}
            </Button>

            <UserWalletDropdown className={b('button', { wallet: true })} />

            <NotificationsDropdown className={b('button', { notification: true })} />

            <UserMenuDropdown className={b('button', { user: true })} />

            <Button className={b('button')} onClick={handleOpenTopupClick}>
              {t('user-nav.deposit', 'Пополнить')}
            </Button>
          </>
        ) : (
          <AuthButtons
            onLoginClick={handleLoginClick}
            onRegisterClick={handleRegisterClick}
          />
        )}
      </Desktop>
    </div>
  );
};


export default UserNav;
