import { useTranslation } from 'react-i18next';
import ResultModal from '@/shared/components/result-modal/result-modal';

type PasswordResetErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const PasswordResetErrorModal = ({
  isOpen,
  onClose,
}: PasswordResetErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.confirmation.reset-error.title', 'Ошибка смены пароля')}
      description={t('result-modal.confirmation.error.description', 'Обратитесь в службу поддержки')}
      status='error'
    />
  );
};

export default PasswordResetErrorModal;
