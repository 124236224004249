import { useResponsive } from '@/shared/hooks/use-responsive';
import { FC, PropsWithChildren } from 'react';

type DesktopProps = PropsWithChildren;

const Desktop: FC<DesktopProps> = ({ children }) => {
  const { isDesktop } = useResponsive();

  return isDesktop ? children : null;
};

export default Desktop;
