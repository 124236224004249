export type ClickId = string | null | undefined;

export const saveClickId = (clickId: ClickId) => {
  if (clickId) {
    localStorage.setItem('clickId', clickId);
  }
};
export const getClickId = (): ClickId =>
  localStorage.getItem('clickId') ?? undefined;

export const dropClickId = () =>
  localStorage.removeItem('clickId');
