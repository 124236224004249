import { useTranslation } from 'react-i18next';
import ButtonIcon, { IButtonIconProps } from '@/shared/components/button-icon/button-icon';
import PartnerStatsDrawer from '../../core/partner-stats-drawer/partner-stats-drawer';
import { PartnerPromocode } from '@/modules/partner-program/model/types';
import {
  useGetSubPartnerPromocodeStatsQuery,
  useGetSubPartnerPromocodePlayersQuery
} from '@/modules/partner-program/api/managerApi';
import { useState } from 'react';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { usePPFilter } from '@/modules/partner-program/hooks/usePPFilter';
import { usePPPagination } from '@/modules/partner-program/hooks/usePPPagination';
import { useAppSelector } from '@/shared/hooks';
import { selectPPSelectedCurrency } from '@/modules/partner-program/model/selectors';

type PromocodeStatsBtnProps = IButtonIconProps & {
  promocode: PartnerPromocode;
}

const ManagerUserPromocodeStatsBtn = ({
  className,
  promocode,
}: PromocodeStatsBtnProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [opened, setOpened] = useState(false);

  const currency = useAppSelector(selectPPSelectedCurrency);

  const initParams = {
    promocodeId: promocode.id,
  };

  const {
    filterParams,
    handleFilterChange,
  } = usePPFilter(initParams);

  const {
    params: paginationParams,
    handlePageChange,
  } = usePPPagination();

  const {
    data: promocodeStatsData,
    isLoading: isStatsLoading
  } = useGetSubPartnerPromocodeStatsQuery({ ...filterParams, currency }, { skip: !opened || !currency });

  const {
    data: promocodePlayers,
    isLoading: isPlayersLoading
  } = useGetSubPartnerPromocodePlayersQuery({ ...filterParams, ...paginationParams, currency }, { skip: !opened || !currency });
  const totalPlayersCount = promocodePlayers?.count ?? 0;

  return (
    <>
      <ButtonIcon className={className}
        icon='stats'
        variant='bordered'
        onClick={() => setOpened(true)}
      />

      <PartnerStatsDrawer
        title={t('promocode-stats', 'Статистика по промокоду')}
        isOpen={opened}
        onClose={() => setOpened(false)}
        metaInfo={
          <>
            <p>{t('promocode', 'Промокод')}: {promocode.name}</p>
            <p>{t('created', 'Создан')}: {dayjsCustom(promocode.created).format('DD.MM.YYYY')}</p>
          </>
        }
        stats={promocodeStatsData}
        players={promocodePlayers?.items}
        isStatsLoading={isStatsLoading}
        isPlayersLoading={isPlayersLoading}
        totalPlayersCount={totalPlayersCount}
        paginationParams={paginationParams}
        handleFilterChange={handleFilterChange}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default ManagerUserPromocodeStatsBtn;
