import { useTranslation } from 'react-i18next';
import { useGetSubPartnerPlayersQuery, useGetSubPartnerStatsQuery } from '@/modules/partner-program/api/managerApi';
import ButtonIcon from '@/shared/components/button-icon/button-icon';
import { useState } from 'react';
import PartnerStatsDrawer from '../../core/partner-stats-drawer/partner-stats-drawer';
import { Subpartner } from '@/modules/partner-program/model/types';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { usePPFilter } from '@/modules/partner-program/hooks/usePPFilter';
import { usePPPagination } from '@/modules/partner-program/hooks/usePPPagination';
import { useAppSelector } from '@/shared/hooks';
import { selectPPSelectedCurrency } from '@/modules/partner-program/model/selectors';

type SubpartnerStatsBtnProps = {
  className?: string;
  subpartner: Subpartner;
}

const SubpartnerStatsBtn = ({ className, subpartner, }: SubpartnerStatsBtnProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [opened, setOpened] = useState(false);

  const currency = useAppSelector(selectPPSelectedCurrency);

  const initParams = {
    partnerId: subpartner.id,
  };

  const {
    filterParams,
    handleFilterChange,
  } = usePPFilter(initParams);
  const {
    params: paginationParams,
    handlePageChange,
  } = usePPPagination();

  const {
    data: subpartnerStats,
    isLoading: isStatsLoading
  } = useGetSubPartnerStatsQuery({ ...filterParams, currency }, { skip: !opened || !currency });
  const {
    data: subpartnerPlayers,
    isLoading: isPlayersLoading
  } = useGetSubPartnerPlayersQuery({ ...filterParams, ...paginationParams, currency }, { skip: !opened || !currency });
  const totalPlayersCount = subpartnerPlayers?.count ?? 0;

  return (
    <>
      <ButtonIcon
        className={className}
        icon='stats'
        variant='bordered'
        onClick={() => setOpened(true)}
      />
      <PartnerStatsDrawer
        title={t('partner-stats', 'Статистика по партнёру')}
        isOpen={opened}
        onClose={() => setOpened(false)}
        metaInfo={
          <>
            <p>{t('partner-id', 'ID партнёра')}: {subpartner.id}</p>
            <p>{t('name', 'Имя')}: {subpartner.name}</p>
            <p>{t('login', 'Логин')}: {subpartner.login}</p>
            <p>Email: {subpartner.email}</p>
            <p>{t('phone', 'Телефон')}: {subpartner.phone}</p>
            <p>{t('registered', 'Зарегистрирован')}: {dayjsCustom(subpartner.created).format('DD.MM.YYYY')}</p>
          </>
        }
        stats={subpartnerStats}
        players={subpartnerPlayers?.items}
        isStatsLoading={isStatsLoading}
        isPlayersLoading={isPlayersLoading}
        totalPlayersCount={totalPlayersCount}
        paginationParams={paginationParams}
        handleFilterChange={handleFilterChange}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default SubpartnerStatsBtn;
