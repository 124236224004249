import { useTranslation } from 'react-i18next';
import ResultModal from '@/shared/components/result-modal/result-modal';


type WithdrawalSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const WithdrawalSuccessModal = ({
  isOpen,
  onClose,
}: WithdrawalSuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.withdrawal.success.title', 'Заявка на вывод успешно создана')}
      status='success'
    />
  );
};

export default WithdrawalSuccessModal;
