import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '../icon-svg/icon-svg';
import './loader.scss';

const b = bemCn('loader');

type LoaderProps = {
  className?: string;
}

const Loader = ({ className }: LoaderProps) => (
  <IconSvg className={b(null, className)} name="football" />
);

export default Loader;
