import { useTranslation } from 'react-i18next';
import ResultModal from '@/shared/components/result-modal/result-modal';

type PasswordChangeSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const PasswordChangeSuccessModal = ({
  isOpen,
  onClose,
}: PasswordChangeSuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.change-password.title', 'Пароль изменён')}
      description={t('result-modal.change-password.description', 'Ваш пароль успешно изменен')}
      status='success'
    />
  );
};

export default PasswordChangeSuccessModal;
