import { bemCn } from '@/shared/utils/helpers/bem-cn';
import type { WithdrawalsItem, WithdrawalStatus } from '@/modules/user';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { getAmountString } from '@/shared/utils/helpers/common';
import i18n from '@/shared/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { currenciesTitles } from '@/shared/utils/constants/currency';
import ButtonIcon from '@/shared/components/button-icon/button-icon';
import { useCancelWithdrawalMutation } from '@/modules/user';
import './withdrawal-card.scss';

const withdrawalStatus: Record<WithdrawalStatus, string> = {
  created: i18n.t('transactions.withdrawal-status.created', 'Создан'),
  approved: i18n.t('transactions.withdrawal-status.approved', 'Одобрен'),
  canceled: i18n.t('transactions.withdrawal-status.canceled', 'Отменен'),
  declined: i18n.t('transactions.withdrawal-status.declined', 'Отклонено'),
};

const b = bemCn('withdrawal-card');

type WithdrawalCardProps = {
  className?: string;
  item: WithdrawalsItem;
  inverted?: boolean;
}

const WithdrawalCard = ({ className, item, inverted }: WithdrawalCardProps) => {
  const { t } = useTranslation();
  const [cancelWithdrawal, { isLoading }] = useCancelWithdrawalMutation();

  const {
    id,
    created,
    status,
    amount,
    currency,
    commentary
  } = item;
  const isCancelable = status === 'created';

  return (
    <div className={b({ status, inverted }, className)}>
      <div className={b('head')}>
        <h2 className={b('title')}>{withdrawalStatus[status] ?? status}</h2>
        <p className={b('description')}>
          {t('transactions.account', 'Счет')}: {currenciesTitles[currency].title ?? currency}
        </p>
        {isCancelable && (
          <ButtonIcon
            className={b('cancel-btn')}
            variant="secondary"
            icon='close'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => cancelWithdrawal(id)}
          />
        )}
      </div>
      <div className={b('payload')}>
        {!!amount && !!currency && (
          <p className={b('amount')}>
            {/* {type === 'error' && '- '}
            {type === 'success' && '+ '} */}
            {getAmountString(amount, currency)}
          </p>
        )}
        <p className={b('date')}>
          {dayjsCustom(created).format('DD.MM.YYYY / HH:mm')}
        </p>
        {!!commentary && <p className={b('message')}>{commentary}</p>}
      </div>
    </div>
  );

};

export default WithdrawalCard;
