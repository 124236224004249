import { RefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type LocationState = {
  scrollToView?: boolean;
}

const defaultScrollOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'center',
  inline: 'center',
};

export const useScrollToView = <T extends HTMLElement,>(
  ref?: RefObject<T>,
  scrollOptions?: boolean | ScrollIntoViewOptions
) => {
  const location = useLocation();
  const { scrollToView } = location?.state as LocationState || { scrollToView: false };

  useEffect(() => {
    if (scrollToView) {
      ref?.current?.scrollIntoView(scrollOptions ?? defaultScrollOptions);
    }
  }, [scrollToView, ref, scrollOptions]);

  return !!scrollToView;
};
