import { NotificationItem, Notifications, NotificationServer, NotificationsResponse } from './types';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import i18n from '@/shared/i18n/i18n';

const adapteNotification = (notification: NotificationServer): NotificationItem => ({
  id: `${notification.id}`,
  title: i18n.t('notifications.type.administration', 'Notification'),
  description: '',
  message: notification.content,
  date: dayjsCustom(notification.created).format('DD.MM.YYYY, HH:mm'),
  amount: 0,
  currency: '',
  type: 'info',
  read: notification.read,
});

export const adapteNotifications = (res: NotificationsResponse): Notifications => ({
  count: res.count,
  items: res.items.map(adapteNotification),
});
