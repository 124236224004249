import { bemCn } from '@/shared/utils/helpers/bem-cn';
import {
  SiteSectionCardLine,
  SiteSectionCardLive,
  // SiteSectionCardStream,
  // SiteSectionCardResult
} from '../site-section-card/site-section-card';
import Container from '@/shared/components/container/container';
// import { Link } from 'react-router-dom';
// import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './site-sections-widget.scss';

type SiteSectionsWidgetProps = {
  className?: string;
}

const SiteSectionsWidget: FC<SiteSectionsWidgetProps> = ({ className }) => {
  const { t } = useTranslation();
  const b = bemCn('site-sections-widget');

  return (
    <div className={b(null, className)}>
      <Container className={b('container')}>
        <div className={b('head')}>
          <h2 className={b('title')}>
            {t('common.sport', 'Спорт')}
          </h2>
          {/* <Link className={b('link')} to="#">
            Все разделы
            <IconSvg className={b('link-icon')} name='arrow-right' />
          </Link> */}
        </div>
        <div className={b('list')}>
          <SiteSectionCardLine />
          <SiteSectionCardLive />
          {/* <SiteSectionCardStream />
          <SiteSectionCardResult /> */}
        </div>
      </Container>
    </div>
  );
};

export default SiteSectionsWidget;
