import { bemCn } from '@shared/utils/helpers/bem-cn';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import { useAppSelector } from '@/shared/hooks';
import { getSocialLinks } from '@/shared/store/app/selectors';
import './social-links.scss';

const b = bemCn('social-links');

type SocialLinksProps = {
  className?: string;
}

const SocialLinks = ({ className }: SocialLinksProps) => {
  const items = useAppSelector(getSocialLinks);

  return (
    <ul className={b(null, className)}>
      {items.map(({ id, title, link, icon }) => (
        <li className={b('item')} key={id}>
          <ButtonIcon className={b('link')}
            href={link}
            variant="secondary"
            icon={icon}
            ariaLabel={title}
          />
        </li>
      ))}
    </ul>
  );
};

export default SocialLinks;
