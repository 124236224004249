import { useParams } from 'react-router-dom';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { useAppSelector } from '@/shared/hooks';
import { getUserCurrency } from '@/modules/user';
import { useGetBetgamesTokenQuery } from '@/modules/casino';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { SiteMaintenance, Maintenance } from '@/widgets/site-maintenance';

const clientUrl = 'https://webiframe.betgamestv.eu';
const apiUrl = 'https://game3.betgamestv.eu';

type StartGameParams = {
  containerId?: string;
  clientUrl?: string;
  apiUrl?: string;
  partnerCode?: string;
  partnerToken?: string;
  language?: string;
  timezone?: string;
  defaultGame?: string;
  isMobile?: number;
}

const startGame = ({
  containerId = 'betgames-container',
  partnerCode,
  partnerToken,
  language,
  timezone,
  defaultGame,
  isMobile,
}: StartGameParams) => {
  // @ts-ignore
  if (window.BetGames) {
    // @ts-ignore
    window.BetGames.setup({
      containerId,
      clientUrl,
      apiUrl,
      partnerCode,
      partnerToken,
      language,
      timezone,
      defaultGame,
      isMobile,
    });
  }

};

const loadScript = (onLoad: () => void) => {
  const betgamesScript = document.getElementById('betgames-script');
  if (!betgamesScript) {
    const script = document.createElement('script');

    script.onload = onLoad;
    script.type = 'text/javascript';
    script.id = 'betgames-script';
    script.src = `${clientUrl}/public/betgames.js?${Date.now()}`;
    document.head.appendChild(script);
  } else {
    onLoad();
  }
};

const removeScript = () => {
  // @ts-ignore
  if (window.BetGames) {
    // @ts-ignore
    window.BetGames = undefined;
  }

  document.getElementById('betgames-script')?.remove();
};

const Betgames = () => {
  const { data: appStatuses } = useGetStatusQuery();
  const isBetgamesActive = appStatuses?.betgames ?? false;
  const { isMobile } = useResponsive();
  const { gameId } = useParams();
  const currency = useAppSelector(getUserCurrency);
  const { data, isSuccess } = useGetBetgamesTokenQuery(
    isBetgamesActive && currency ? { currency } : skipToken
  );

  useEffect(() => {
    if (isBetgamesActive && data?.token && data?.partnerCodeBetgames && isSuccess) {
      loadScript(() => startGame({
        containerId: 'betgames-container',
        clientUrl,
        apiUrl,
        partnerCode: data.partnerCodeBetgames,
        partnerToken: data.token,
        language: 'ru',
        timezone: '3',
        defaultGame: gameId,
        isMobile: isMobile ? 1 : 0,
      }));
    }

    return removeScript;
  }, [data]);

  if (!isBetgamesActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <div>
      <div id="betgames-container"></div>
    </div>
  );
};

export default Betgames;
