export const getCasinoGameImgByProvider = (provider: string, gameId: string) => {
  switch (provider) {
    case 'pragmatic':
      return `https://common-static.ppgames.net/game_pic/rec/325/${gameId}.png`;
    case 'smartsoft':
      return `/img/games/smartsoft/${gameId}.jpg`;
    case 'mascot':
      return `/img/games/mascot/${gameId}.png`;
    case 'vegas':
      return `/img/games/vegas/${gameId}.jpg`;
    case 'inbet':
      return `https://flashslots.s3.amazonaws.com/thumb/300x188/${gameId}.jpeg`;
    case 'tvbet':
      return `/img/games/tvbet/${gameId}.jpg`;
    case 'betgames':
      return `/img/games/betgames/${gameId}.jpg`;
    case 'aviatrix':
      return `/img/games/vegas/${gameId}.jpg`;
    default:
      return '';
  }
};
