import { useAppSelector, useDispatchedActions } from '../../hooks';
import { getIsMenuOpened } from '@shared/store/app/selectors';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '../icon-svg/icon-svg';
import MenuIcon from './Menu.svg?react';
import { getNewNotificationsCount } from '@/modules/notifications';
import './button-menu.scss';

const b = bemCn('button-menu');

type ButtonMenuProps = {
  className?: string;
}

const ButtonMenu = ({ className, }: ButtonMenuProps) => {
  const isActive = useAppSelector(getIsMenuOpened);
  const { toggleMenu } = useDispatchedActions();
  const newNotificationsCount = useAppSelector(getNewNotificationsCount);
  return (
    <button className={b({ active: isActive }, className)} type='button' onClick={() => toggleMenu()}>
      <IconSvg className={b('icon', { close: true })} name='close' />
      <MenuIcon className={b('icon', { burder: true })} />
      {newNotificationsCount ? (
        <span className={b('notification-counter')}>
          {newNotificationsCount}
        </span>
      ) : null}
    </button>
  );
};


export default ButtonMenu;
