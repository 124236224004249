import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { PPTotalStat } from '@/modules/partner-program';
import Mobile from '@/shared/components/responsive/mobile';
import Desktop from '@/shared/components/responsive/desktop';
import PPTotalStatDesktop from '@/modules/partner-program/ui/pp-total-stat-desktop/pp-total-stat-desktop';
import LoaderCenter from '@/shared/components/loader-center/loader-center';
import { useGetPartnerStatsQuery } from '@/modules/partner-program/api/partnerApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAppSelector } from '@/shared/hooks';
import { selectPPSelectedCurrency } from '@/modules/partner-program/model/selectors';
import { usePPFilter } from '@/modules/partner-program/hooks/usePPFilter';
import { PPFilter } from '@/modules/partner-program';
import './partner-stats-page.scss';

const b = bemCn('partner-stats-page');

const PartnerStatsPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const currency = useAppSelector(selectPPSelectedCurrency);

  const {
    filterParams,
    handleFilterChange,
  } = usePPFilter({});

  const {
    data,
    isLoading: isPartnerStatsLoading,
  } = useGetPartnerStatsQuery(currency ? { ...filterParams, currency } : skipToken);

  return (
    <div className={b()}>
      <div className={b('head')}>
        <h1 className={b('title')}>{t('your-stats', 'Ваша статистика')}</h1>

        <PPFilter
          className={b()}
          handleFilterChange={handleFilterChange}
        />
      </div>

      {isPartnerStatsLoading && <LoaderCenter />}
      <Mobile>
        {data && <PPTotalStat stats={data} />}
      </Mobile>
      <Desktop>
        {data && <PPTotalStatDesktop stats={data} />}
      </Desktop>
    </div>
  );
};

export { PartnerStatsPage };
