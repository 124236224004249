

import { FieldPath, FieldValues, Resolver, useForm, UseFormProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';


export type UseYupFormProps<T extends FieldValues> = Omit<UseFormProps<T>, 'resolver'> & {
  schema?: yup.ObjectSchema<T>;
  error?: Partial<Record<keyof T, string>> & {
    root?: string;
  };
}

export const useYupForm = <T extends FieldValues>({ schema, error, ...formConfig }: UseYupFormProps<T>) => {
  const form = useForm({
    ...formConfig,
    resolver: schema && yupResolver(schema) as unknown as Resolver<T>,
  });


  useEffect(() => {
    if (error) {
      const fields: (keyof (typeof error))[] = Object.keys(error);
      fields.forEach((field) => {
        form.setError(field as FieldPath<T>, { type: 'manual', message: error[field] });
      });
    }
  }, [error]);

  return form;
};
