
import { TEventsFilter } from './types';
import { createContext } from 'react';

const initialFilter: TEventsFilter = {
  sport: '0',
  country: '0',
  tournament: '0'
};

interface IEventsFilterContext {
  filter: TEventsFilter;
  setFilter: (filter: TEventsFilter) => void;
  resetFilter: () => void;
}

const initValue: IEventsFilterContext = {
  filter: initialFilter,
  setFilter: () => null,
  resetFilter: () => null
};

const EventsFilterContext = createContext<IEventsFilterContext>(initValue);
export default EventsFilterContext;
