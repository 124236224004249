import { bemCn } from '@/shared/utils/helpers/bem-cn';
import AppModal from '@/shared/components/app-modal/app-modal';
import { GetTransactionsParams } from '../../store/types';
import { TRANSACTIONS_PER_PAGE } from '../../utils/transactions';
import { useState } from 'react';
import { useGetTransactionsQuery } from '../../store/user-api';
import TransactionsList from '../transactions-list/transactions-list';
import './transactions-modal.scss';
import Pagination from '@/shared/components/pagination/pagination';
import i18n from '@/shared/i18n/i18n';
import { useTranslation } from 'react-i18next';

const b = bemCn('transactions-modal');


type ITab = {
  name: string;
  id: GetTransactionsParams['type'] | 'all';
};

const tabs: ITab[] = [
  {
    id: 'all',
    name: i18n.t('transactions.all', 'Все'),
  },
  {
    id: 'deposits',
    name: i18n.t('transactions.deposits', 'Пополнения'),
  },
  {
    id: 'withdrawals',
    name: i18n.t('transactions.withdrawal', 'Вывод'),
  },
  {
    id: 'bets',
    name: i18n.t('transactions.bets', 'Ставки'),
  },
  {
    id: 'transfers',
    name: i18n.t('transactions.transfer', 'Переводы'),
  },
];

const initParams: GetTransactionsParams = {
  page: 1,
  pageSize: TRANSACTIONS_PER_PAGE,
  type: undefined,
};

type TransactionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const TransactionsModal = ({ isOpen, onClose }: TransactionsModalProps) => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);
  const { data: transactions, isFetching } = useGetTransactionsQuery(
    params, { skip: !isOpen }
  );

  const items = transactions?.items ?? [];
  const itemsCount = transactions?.count ?? 0;
  const isEmpty = !isFetching && itemsCount === 0;

  const changeParams = (newParams: Partial<GetTransactionsParams>) => {
    setParams((prev) => ({
      ...prev,
      ...newParams,
    }));
  };

  const changeTab = (newTab: ITab['id']) => {
    setParams((prev) => ({
      ...prev,
      page: 1,
      type: newTab !== 'all' ? newTab : undefined,
    }));
  };

  return (
    <AppModal className={b()}
      isOpen={isOpen}
      onClose={onClose}
      title={t('transactions.title', 'Детализация')}
    >
      <div className={b('tabs')}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={b('tab-item', {
              active: tab.id === params.type
                || (tab.id === 'all' && !params.type)
            })}
            type='button'
            onClick={() => changeTab(tab.id)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      {isEmpty && <p className={b('empty-message')}>{t('empty', 'Список пуст')}</p>}
      <TransactionsList
        className={b('list')}
        transactions={items}
        loading={isFetching}
        inverted
      />
      <Pagination
        page={params.page}
        pageSize={TRANSACTIONS_PER_PAGE}
        totalItems={itemsCount}
        onChange={(page) => changeParams({ page })}
      />
    </AppModal>
  );
};

export default TransactionsModal;
