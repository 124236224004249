import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

type DefaultError = {
  detail: string;
};

type ValidationError = {
  detail: {
    loc: string[];
    msg: string;
    type: string;
  }[];
};

export type BackendError = DefaultError | ValidationError;

export type HandledError = FetchBaseQueryError | SerializedError | undefined;

export const handleQueryError = (error?: HandledError): BackendError | string | undefined => {
  if (!!error && 'status' in error && error.data) {
    return error.data as BackendError;
  }
};
