import { bemCn } from '@shared/utils/helpers/bem-cn';
import EventMetaCountdown from './event-meta-countdown/event-meta-countdown';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './event-meta.scss';

type EventMetaProps = {
  className?: string;
  sportName: string;
  tournamentName: string;
  isFavorite: boolean;
  period: string;
  isLive: boolean;
  isAvailable: boolean;
  gameStart: string;
  onFavoriteClick?: (value: boolean) => void;
}

const b = bemCn('event-meta');

const EventMeta = (props: EventMetaProps) => {
  const {
    className,
    sportName,
    tournamentName,
    isFavorite,
    period,
    isLive,
    isAvailable,
    gameStart,
    onFavoriteClick,
  } = props;
  const startTime = dayjsCustom(gameStart).format('HH:mm');
  const startDate = dayjsCustom(gameStart).format('DD.MM.YYYY');

  const handleFavoriteClick = () => {
    onFavoriteClick?.(!isFavorite);
  };

  return (
    <div className={b(null, className)}>
      <div className={b('top')}>
        <p className={b('time')}>{startTime}</p>
        <p className={b('date')}>{startDate}</p>
        <button className={b('favorite-btn', { active: isFavorite })} onClick={handleFavoriteClick}>
          <IconSvg className={b('favorite-icon')}
            name="heart"
            width={15}
            height={15}
          />
        </button>
      </div>
      {isAvailable && (
        <p className={b('info')}>
          {sportName} • {tournamentName}
        </p>
      )}
      {isLive || !isAvailable ? (
        <p className={b('period')}>
          <IconSvg className={b('period-icon')}
            name="clock"
            width={16}
            height={16}
          />
          {period}
        </p>
      ) : (
        <EventMetaCountdown className={b('countdown')} date={gameStart} />
      )}
    </div>
  );
};

export default EventMeta;
