export const getCasinoGameLink = (provider: string, gameId: string) => {
  switch (provider) {
    case 'aviatrix':
      return `/games/${gameId}`;
    case 'inbet':
      return `/games/inbetgames/${gameId}`;
    default:
      return `/games/${provider}/${gameId}`;
  }
};
