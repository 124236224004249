import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useTranslation } from 'react-i18next';
import { parseTotalStats } from '@/modules/partner-program/lib/parseTotalStats';
import Drawer from '@/shared/components/drawer/drawer';
import { PartnerStats, PlayerStats } from '@/modules/partner-program/model/types';
import Descriptions from '@/shared/components/descriptions/descriptions';
import PartnerPlayersList from '../../player/partner-players-list/partner-players-list';
import Mobile from '@/shared/components/responsive/mobile';
import Desktop from '@/shared/components/responsive/desktop';
import Pagination from '@/shared/components/pagination/pagination';
import LoaderCenter from '@/shared/components/loader-center/loader-center';
import PartnerPlayersTable from '../../player/partner-players-table/partner-players-table';
import { PropsWithCN } from '@/shared/types/common';
import './partner-stats-drawer.scss';
import PartnerStatsCards from '../partner-stats-cards/partner-stats-cards';
import PPFilter from '../pp-filter/pp-filter';

import type { PPFilterData, PaginationParams } from '@/modules/partner-program/model/types';

const b = bemCn('partner-stats-drawer');

type PartnerStatsDrawerProps = PropsWithCN & {
  title?: string;
  isOpen: boolean;
  onClose?: () => void;
  stats?: PartnerStats;
  players?: PlayerStats[];
  isStatsLoading?: boolean;
  isPlayersLoading?: boolean;
  metaInfo?: React.ReactNode;
  paginationParams?: PaginationParams;
  totalPlayersCount?: number;
  handleFilterChange?: (filterData: PPFilterData) => void;
  handlePageChange?: (newPage: number) => void;
}

const PartnerStatsDrawer = ({
  className,
  title,
  isOpen,
  onClose,
  stats,
  players,
  isStatsLoading,
  isPlayersLoading,
  metaInfo,
  paginationParams,
  totalPlayersCount,
  handleFilterChange,
  handlePageChange,
}: PartnerStatsDrawerProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const { mainStats, commonStats } = parseTotalStats(stats ?? {} as PartnerStats);
  const baseStats = [...mainStats, ...commonStats];

  return (
    <Drawer
      title={title}
      className={b(null, className)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={b('wrapper')}>
        {metaInfo && (
          <div className={b('info', b('section'))}>
            {metaInfo}
          </div>
        )}

        <PPFilter
          className={b('section')}
          handleFilterChange={handleFilterChange}
        />

        {isStatsLoading && <LoaderCenter />}
        {stats && (
          <>
            <Mobile>
              <Descriptions className={b('total-stat', b('section'))} title={t('general-stats', 'Общая статистика')}>
                {baseStats.map(({ label, value }) => (
                  <Descriptions.Item key={label + value} label={label}>
                    {value}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Mobile>
            <Desktop>
              <section className={b('players', b('section'))}>
                <h3 className={b('subtitle')}>{t('general-stats', 'Общая статистика')}</h3>
                <PartnerStatsCards items={baseStats} />
              </section>
            </Desktop>
          </>
        )}

        {isPlayersLoading && <LoaderCenter />}
        {players && (
          <section className={b('players', b('section'))}>
            <h3 className={b('subtitle')}>{t('users', 'Пользователи')}</h3>
            {players.length === 0 && (
              <p className={b('no-players')}>{t('no-users', 'Нет пользователей')}</p>
            )}
            <Mobile>
              <PartnerPlayersList players={players} />
            </Mobile>
            <Desktop>
              <PartnerPlayersTable players={players} />
            </Desktop>

            <Pagination className={b('pagination')}
              totalItems={totalPlayersCount}
              pageSize={paginationParams?.pageSize}
              page={paginationParams?.page}
              onChange={handlePageChange}
            />
          </section>
        )}
      </div>
    </Drawer>
  );
};

export default PartnerStatsDrawer;
