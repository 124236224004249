import { useEffect, useState } from 'react';

type Diff = 'same' | 'increase' | 'decrease'

type UseRateChanged = (
  value: number,
  withReset?: boolean,
  delay?: number
) => [number, Diff]

export const useValueChanged: UseRateChanged = (
  value,
  withReset = true,
  delay = 1500
) => {
  const [diff, setDiff] = useState<Diff>('same');
  const [current, setCurrentRate] = useState(value);

  useEffect(() => {
    setCurrentRate((prevRate) => {
      if (value > prevRate) {
        setDiff('increase');
      } else if (value < prevRate) {
        setDiff('decrease');
      }
      return value;
    });
  }, [value]);

  useEffect(() => {
    if (withReset) {
      let timeoutId: NodeJS.Timeout;

      if (diff !== 'same') {
        timeoutId = setTimeout(() => {
          setDiff('same');
        }, delay);
      }

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [diff, delay, withReset]);

  return [current, diff];
};
