import { Navigate } from 'react-router-dom';
import NotFoundScreen from '../not-found-screen/not-found-screen';
import { useRefCode } from '@/modules/auth/hooks/use-refcode';
import { useClickId } from '@/modules/auth/hooks/use-clickid';


const AnyRoutePage = () => {
  const code = useRefCode();
  const { clickId, webId } = useClickId();

  if (code || clickId || webId) {
    return <Navigate to="/" replace />;
  }

  return <NotFoundScreen />;
};

export default AnyRoutePage;
