import { AVAILABLE_THEMES, DEFAULT_THEME } from '@/app-config';
import { useDispatchedActions } from '@/shared/hooks';
import { createContext, useContext, useMemo } from 'react';

interface IThemeContext {
  theme: string;
  setTheme: (theme: string) => void;
  availableThemes: string[];
}

const themesType: Record<string, 'light' | 'dark'> = {
  'dark-purple': 'dark',
  'blue': 'dark',
  'dark-blue': 'dark',
  'light-blue': 'light',
};

const themeStorageKey = 'theme-color';

export const saveTheme = (theme: string) => {
  localStorage.setItem(themeStorageKey, theme);
};

export const getTheme = () => {
  const theme = localStorage.getItem(themeStorageKey);
  const isThemeValid = !!theme && AVAILABLE_THEMES.includes(theme);

  if (!isThemeValid) {
    saveTheme(DEFAULT_THEME);
    return DEFAULT_THEME;
  }

  return theme;
};

const initValue = {
  theme: getTheme(),
  setTheme: (theme: string) => saveTheme(theme),
  availableThemes: AVAILABLE_THEMES,
};


export const ThemeContext = createContext<IThemeContext>(initValue);


const isLightTheme = (theme: string) => themesType[theme] === 'light';

export const useThemeContext = () => {
  const { changeLogotypesByTheme } = useDispatchedActions();
  const { theme, setTheme } = useContext(ThemeContext);

  const changeTheme = (newTheme: string) => {
    if (newTheme !== theme && AVAILABLE_THEMES.includes(newTheme)) {
      setTheme(newTheme);
      saveTheme(newTheme);
      changeLogotypesByTheme(newTheme);
    }
  };

  const isLight = useMemo(() => isLightTheme(theme), [theme]);

  return {
    theme,
    changeTheme: changeTheme,
    themesList: AVAILABLE_THEMES,
    defaultTheme: DEFAULT_THEME,
    isLight: isLight,
    isDark: !isLight
  };
};
