import { useCreateFivemenSessionQuery } from '@/modules/casino';
import GameScreen from '../game-screen';
import { useParams } from 'react-router-dom';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { SiteMaintenance, Maintenance } from '@/widgets/site-maintenance';
import { useTranslation } from 'react-i18next';

const Fivemen = () => {
  const { t } = useTranslation();
  const { data: appStatuses } = useGetStatusQuery();
  const isFivemenActive = appStatuses?.fivemen ?? false;
  const { gameId = '' } = useParams();
  const { data: sessionUrl, isLoading, isError } = useCreateFivemenSessionQuery({
    lang: 'ru',
    gameId,
    platformType: 'desktop',
    lobbyUrl: `${window.location.origin}/games?filter=5MEN`,
    depositUrl: `${window.location.origin}/deposit`,
  }, { skip: !isFivemenActive });

  if (!isFivemenActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <GameScreen
      title="Fivemen"
      src={sessionUrl}
      loading={isLoading}
      isError={isError}
      errorMessage={t('common.unknown-error', 'Неизвестная ошибка')}
    />
  );
};

export default Fivemen;
