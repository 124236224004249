import { getLineLang } from '@/modules/line';
import { baseApi } from '../../../shared/store/api';
import { adapteBasket } from '../models/adapter';
import { AddToBasketParams, BasketItem, DeleteFromBasketParams, PlaceBetParams } from '../models/types';
import { DEFAULT_LANG } from '@/app-config';


const basketApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBasket: build.query<BasketItem[], void>({
      query: () => 'v1/line/basket',
      transformResponse: adapteBasket,
      providesTags: ['basket'],
    }),

    plageBet: build.mutation<unknown, PlaceBetParams>({
      query: ({ lang = getLineLang(DEFAULT_LANG), amount, agree, balanceType = 'real' }) => ({
        url: 'v1/line/bet/place',
        method: 'POST',
        body: {
          'amount': amount,
          'agree_with_coef': agree,
          'balance_type': balanceType,
        },
        params: {
          lang: getLineLang(lang),
        }
      }),
      invalidatesTags: ['basket', 'wallets'],
    }),

    addToBasket: build.mutation<unknown, AddToBasketParams>({
      query: ({ lang = getLineLang(DEFAULT_LANG), dataType, betId }) => ({
        url: 'v1/line/basket/add',
        method: 'POST',
        body: {
          'data_type': dataType,
          'basket_id': betId,
        },
        params: {
          lang: getLineLang(lang),
        }
      }),
      invalidatesTags: ['basket'],
    }),

    deleteFromBasket: build.mutation<unknown, DeleteFromBasketParams>({
      query: ({ dataType, betId }) => ({
        url: 'v1/line/basket/delete',
        method: 'DELETE',
        body: {
          'data_type': dataType,
          'basket_id': betId,
        }
      }),
      invalidatesTags: ['basket'],
    })
  }),
  overrideExisting: true,
});


export const {
  usePlageBetMutation,
  useGetBasketQuery,
  useAddToBasketMutation,
  useDeleteFromBasketMutation,
} = basketApi;

export default basketApi;
