import { useState } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Button from '@shared/components/button/button';
import './event-stats.scss';
import { TEventStatItem } from '../../../../model/line';
import { useTranslation } from 'react-i18next';

type EventStatsProps = {
  className?: string;
  stats: TEventStatItem[];
  team1: string;
  team2: string;
}

const calcBarWidth = (stat1: string | number, stat2: string | number) => {
  let bar1 = 1;
  let bar2 = 1;
  const val1 = Number(stat1);
  const val2 = Number(stat2);

  if (!isNaN(val1) && !isNaN(val2)) {
    bar1 = (val1 / (val1 + val2)) * 100;
    bar2 = (val2 / (val1 + val2)) * 100;
  }

  return {
    bar1,
    bar2,
  };
};

const b = bemCn('event-stats');

const EventStats = ({ className, stats, team1, team2 }: EventStatsProps) => {
  const { t } = useTranslation();
  const [showItems, setShowItems] = useState(3);

  const isEmprty = !stats || stats.length === 0;

  if (isEmprty) {
    return null;
  }
  const totalItems = stats.length;

  const handleShowMore = () => {
    if (showItems < totalItems) {
      setShowItems(totalItems);
    }
  };

  const isShowMoreBtn = showItems < totalItems;

  return (
    <div className={b(null, className)}>
      <div className={b('head')}>
        <h2 className={b('title')}>
          {t('event.statistics', 'Статистика')}
        </h2>
        <div className={b('teams')}>
          <p className={b('team', { first: true })}>{team1}</p>
          <p className={b('team', { second: true })}>{team2}</p>
        </div>
      </div>
      <div className={b('list')}>
        {stats.slice(0, showItems).map((item) => {
          const { bar1, bar2 } = calcBarWidth(item.team1, item.team2);

          return (
            <div className={b('item')} key={item.name + item.team1 + item.team2}>
              <p className={b('item-name')}>{item.name}</p>
              <p className={b('item-value')}>
                <span className={b('item-bar', { first: true })}
                  style={{ width: `${bar1}%` }}
                />
                <span>{item.team1}</span>
              </p>
              <p className={b('item-value')}>
                <span className={b('item-bar', { second: true })}
                  style={{ width: `${bar2}%` }}
                />
                <span>{item.team2}</span>
              </p>
            </div>
          );
        })}
      </div>
      {isShowMoreBtn && (
        <Button className={b('show-more')} variant='secondary' onClick={handleShowMore}>
          {t('common.load-more', 'Загрузить ещё')}
        </Button>
      )}
    </div>
  );
};

export default EventStats;
