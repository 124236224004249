import { useEffect } from 'react';
// import ButtonIcon from '../../components/ui/button-icon/button-icon';
import Button from '@shared/components/button/button';
import Container from '@shared/components/container/container';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import Pagination from '@/shared/components/pagination/pagination';
import { scrollToTop } from '@shared/utils/helpers/common';
import ButtonIcon from '@shared/components/button-icon/button-icon';

import { CouponList, useCouponsFilter, useGetCouponsQuery } from '@/modules/coupons';
import { useTranslation } from 'react-i18next';
import './coupons-page.scss';

const b = bemCn('coupons-page');

/**
 * !!! При разлогинивании и входе в другой аккаунт кэш запроса с купонами не обновляется, то есть показываются купоны прошлого пользователя
 * TODO: не обновляется купоны, показываются только купоны прошлого пользователя
 */

const CouponsPage = () => {
  const { t } = useTranslation();
  const { filter, changeFilter, resetFilter } = useCouponsFilter(undefined);
  const { data, isFetching } = useGetCouponsQuery(filter);

  useEffect(() => {
    scrollToTop(true);
  }, [filter]);

  const totalCount = data?.count ?? 0;
  const isEmpty = totalCount === 0;

  return (
    <div className={b()}>
      <Container className={b('container')}>
        <Breadcrumbs className={b('breadcrumbs')} />

        <div className={b('header')}>
          <h1 className={b('title')}>
            {t('bet-history.title', 'История ставок')}
          </h1>
          <div className={b('filter')}>
            <Button className={b('filter-btn')}
              variant={!filter.type ? 'primary' : 'secondary'}
              onClick={resetFilter}
              disabled={isEmpty}
            >
              {t('bet-history.all-bets', 'Все ставки')}
            </Button>
            <Button className={b('filter-btn')}
              variant={filter.type === 'ordinar' ? 'primary' : 'secondary'}
              onClick={() => changeFilter({ type: 'ordinar', page: 1 })}
              disabled={isEmpty}
            >
              {t('bet-history.single', 'Ординар')}
            </Button>
            <Button className={b('filter-btn')}
              variant={filter.type === 'express' ? 'primary' : 'secondary'}
              onClick={() => changeFilter({ type: 'express', page: 1 })}
              disabled={isEmpty}
            >
              {t('bet-history.express', 'Экспресс')}
            </Button>
            <ButtonIcon className={b('filter-btn', { calendar: true })}
              variant="secondary"
              icon='calendar'
            />
          </div>
        </div>

        <CouponList coupons={data?.items} loading={isFetching} />

        <Pagination className={b('pagination')}
          page={filter.page}
          pageSize={filter.pageSize}
          totalItems={totalCount}
          onChange={(page) => changeFilter({ page })}
        />
      </Container>
    </div>
  );
};

export { CouponsPage };
