
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { Maintenance, MaintenanceMessage } from '../utils/const';
import './site-maintenance.scss';

const b = bemCn('site-maintenance');

type Props = {
  message?: MaintenanceMessage;
}

const SiteMaintenance = ({ message = Maintenance.default }: Props) => (
  <div className={b()}>
    <div className={b('wrapper')}>
      <h1 className={b('title')}>
        {message.title}
      </h1>
      <p className={b('info')}>
        {message.description}
      </p>
    </div>
  </div>
);

export default SiteMaintenance;
