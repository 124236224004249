import React, { useEffect, useState } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import ButtonIcon from '../button-icon/button-icon';
import TextField from '../text-field/text-field';
import { useTranslation } from 'react-i18next';
import './search-filter.scss';

type SearchFilterProps = {
  className?: string;
  search?: string;
  onChange?: (value: string) => void;
  onInput?: (value: string) => void;
  clearable?: boolean;
  inverted?: boolean;
}

const SearchFilter = ({
  className,
  onChange,
  onInput,
  search = '',
  clearable,
  inverted = true
}: SearchFilterProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(search);

  useEffect(() => {
    if (search !== searchValue) {
      setSearchValue(search);
    }
  }, [search]);

  const handleSearchClick = (e: React.FormEvent) => {
    e.preventDefault();
    onChange?.(searchValue);
  };

  const onClearClick = () => {
    setSearchValue('');
    onChange?.('');
    onInput?.('');
  };

  const b = bemCn('search-filter');

  return (
    <form className={b(null, className)} onSubmit={handleSearchClick}>
      <TextField
        className={b('field')}
        classNameInput={b('field-input')}
        type='search'
        name="search"
        placeholder={`${t('search', 'Поиск')}...`}
        inverted={inverted}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          onInput?.(e.target.value);
        }}
      />
      {searchValue !== '' && clearable ? (
        <ButtonIcon className={b('btn')}
          onClick={onClearClick}
          type='button'
          icon="close"
          variant="text"
        />
      ) : (
        <ButtonIcon className={b('btn')}
          type='submit'
          icon="search"
          variant="secondary"
        />
      )}
    </form>
  );
};

export default React.memo(SearchFilter);
