import { NotificationCard, useNotifications } from '@/modules/notifications';
import IconSvg from '../../shared/components/icon-svg/icon-svg';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { memo, useMemo } from 'react';
import Button from '@shared/components/button/button';
import Container from '@shared/components/container/container';
import Loader from '@/shared/components/loader/loader';
import { useScrollToTop } from '@shared/hooks/use-scrolltotop';
import { useTranslation } from 'react-i18next';

import './notifications-screen.scss';

const MemoizedNotificationCard = memo(NotificationCard);

const b = bemCn('notifications-screen');

const NotificationsScreen = () => {
  const { t } = useTranslation();
  useScrollToTop();

  const {
    notifications,
    isNotificationLoading,
    isNotificationsFetching,
    isShowMoreBtn,
    newNotificationsCount,
    handleShowMoreClick,
  } = useNotifications();

  const memoizedNotifications = useMemo(() => notifications.map((item) => (
    <MemoizedNotificationCard
      className={b('item')}
      key={item.id}
      item={item}
    />
  )), [notifications]);

  return (
    <div className={b()}>
      <Container className={b('container')}>
        <div className={b('head')}>
          <h1 className={b('title')}>
            {t('user-nav.notifications', 'Уведомления')}
          </h1>
          <div className={b('counter')}>
            <IconSvg className={b('counter-icon')} name='bell' />
            {!!newNotificationsCount && (
              <span className={b('counter-number')}>{newNotificationsCount}</span>
            )}
          </div>
        </div>
        <div className={b('list')}>
          {isNotificationLoading && <Loader className={b('loading')} />}

          {(!isNotificationLoading && notifications.length === 0) && (
            <p className={b('empty')}>{t('empty', 'Список пуст')}</p>
          )}
          {memoizedNotifications}
        </div>

        {isShowMoreBtn && (
          <Button
            className={b('show-more')}
            variant="secondary"
            onClick={handleShowMoreClick}
            loading={isNotificationsFetching}
            loadingType='dots'
          >
            {t('common.load-more', 'Загрузить ещё')}
          </Button>
        )}

      </Container>
    </div>
  );
};

export default NotificationsScreen;
