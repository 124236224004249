import { bemCn } from '@shared/utils/helpers/bem-cn';
import Button, { IButtonProps } from '../button/button';
import IconSvg, { AvailableIcon } from '../icon-svg/icon-svg';

import './button-icon.scss';

const b = bemCn('button-icon');

export interface IButtonIconProps extends IButtonProps {
  icon?: AvailableIcon;
}

const ButtonIcon = (props: IButtonIconProps) => {
  const { icon, className, children, ...rest } = props;

  return (
    <Button {...rest} className={b(null, className)}>
      <IconSvg className={b('icon')} name={icon} />
      {children}
    </Button>
  );
};

export default ButtonIcon;
