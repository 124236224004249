import { AuthModal } from './types';
import { AuthorizationStatus, NameSpace } from '../../../shared/utils/constants/common';

export const getAuthorizationStatus = (state: RootState): AuthorizationStatus =>
  state[NameSpace.Auth].authorizationStatus;

export const getAuthModal = (state: RootState): AuthModal =>
  state[NameSpace.Auth].activeModal;

export const getIsAuth = (state: RootState): boolean =>
  state[NameSpace.Auth].authorizationStatus === AuthorizationStatus.Auth;

export const getPasswordResetId = (state: RootState): string | null =>
  state[NameSpace.Auth].passwordResetId;
