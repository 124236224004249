import { bemCn } from '@shared/utils/helpers/bem-cn';
import { TTournamentEvents } from '../../model/line';
import TournamentEvents from '../tournament-events/tournament-events';
import { memo, useMemo, } from 'react';
import './tournament-events-list.scss';
import Loader from '@/shared/components/loader/loader';

const MemoizedTournament = memo(TournamentEvents);

const b = bemCn('tournament-events-list');

type TournamentEventsListProps = {
  className?: string;
  events: TTournamentEvents[];
  closedItems?: string[];
  onToggle?: (itemId: string) => void;
  loading?: boolean;
}

const TournamentEventsList = ({
  className,
  events,
  closedItems,
  onToggle,
  loading,
}: TournamentEventsListProps) => {

  const memoizedTournaments = useMemo(
    () => events.map((item) => (
      <MemoizedTournament
        className={b('item')}
        key={item.id}
        tournament={item}
        onCloseToggle={() => onToggle?.(item.id)}
        closed={closedItems && closedItems.indexOf(item.id) >= 0}
      />
    )),
    [events, closedItems, onToggle]
  );

  return (
    <div className={b({ loading }, className)}>
      {loading && <Loader className={b('spinner')} />}
      {memoizedTournaments}
    </div>
  );
};

export default TournamentEventsList;
