import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './result-modal.scss';
import AppModal from '../app-modal/app-modal';

const b = bemCn('result-modal');

type ResultModalProps = {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  status: 'success' | 'error';
  title: string;
  description?: string;
  children?: React.ReactNode;
}

const ResultModal = ({
  className,
  isOpen,
  onClose,
  status,
  title,
  description,
  children,
}: ResultModalProps) => (
  <AppModal
    className={b({ status }, className)}
    isOpen={isOpen}
    onClose={onClose}
  >
    <h2 className={b('title')}>{title}</h2>
    <p className={b('description')}>{description}</p>
    {children}
  </AppModal>
);

export default ResultModal;
