import { useState } from 'react';

import type { PaginationParams } from '../model/types';

const initParams: PaginationParams = {
  pageSize: 20,
  page: 1,
};

export const usePPPagination = (paginationParams?: PaginationParams) => {
  const [params, setParams] = useState(paginationParams ?? initParams);

  const handlePageChange = (newPage: number) => {
    setParams((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  return {
    params,
    handlePageChange,
  };
};
