import { bemCn } from '@/shared/utils/helpers/bem-cn';
import PasswordChange from '../password-change/password-change';
import HideBalance from '../hide-balance/hide-balance';
import TimezoneChange from '../timezone-change/timezone-change';
import ThemeChange from '../theme-change/theme-change';
import './user-settings.scss';

type UserSettingsProps = {
  className?: string;
  inverted?: boolean;
}

const UserSettings = ({ className, inverted }: UserSettingsProps) => {
  const b = bemCn('user-settings');

  return (
    <div className={b({ inverted }, className)}>
      <HideBalance className={b('section', { inverted })}
        inverted={inverted}
      />
      <PasswordChange className={b('section', { inverted })}
        inverted={inverted}
      />
      <TimezoneChange className={b('section', { inverted })}
        inverted={inverted}
      />
      <ThemeChange className={b('section', { inverted })}
        inverted={inverted}
      />
    </div>
  );
};

export default UserSettings;
