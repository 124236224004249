import { ITopupMethodsItem } from '@shared/store/app/types';
import AppModal from '@shared/components/app-modal/app-modal';
import TopupForm from '../topup-form/topup-form';
import { useTranslation } from 'react-i18next';


type TopUpModalProps = {
  method: ITopupMethodsItem | null;
  isOpen: boolean;
  onClose?: () => void;
}

const TopUpModal = ({ method, isOpen, onClose }: TopUpModalProps) => {
  const { t } = useTranslation();
  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('topups.modal.title', 'Пополнить')}
    >
      {method && (
        <TopupForm method={method} />
      )}
    </AppModal>
  );
};

export default TopUpModal;
