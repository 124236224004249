import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { TransactionItem } from '../../store/types';
import TransactionCard from '../transaction-item/transaction-item';
import Loader from '@/shared/components/loader/loader';
import './transactions-list.scss';

const b = bemCn('transactions-list');

type TransactionsListProps = {
  className?: string;
  transactions: TransactionItem[];
  inverted?: boolean;
  loading?: boolean;
}

const TransactionsList = ({
  className,
  transactions,
  inverted,
  loading,
}: TransactionsListProps) => (
  <div className={b({ loading }, className)}>
    {loading && <Loader className={b('spiner')} />}
    {transactions.map((transaction) => (
      <TransactionCard
        className={b('item')}
        key={transaction.id}
        transaction={transaction}
        inverted={inverted}
      />
    ))}
  </div>
);

export default TransactionsList;
