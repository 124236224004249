import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


import type {
  GetBetapiCountriesParams,
  GetBetapiCountriesResponse,
  GetBetapiEventParams,
  GetBetapiEventResponse,
  GetBetapiEventsByTourneyResponse,
  GetBetapiEventsListParams,
  GetBetapiEventsListResponse,
  GetBetapiSportsParams,
  GetBetapiSportsResponse,
  GetBetapiTournamensParams,
  GetBetapiTournamentsResponse,
} from './types';
import { adapreDetailedEvent, adapteCountry, adapteEventItem, adapteSport, adapteTournament, adapteTournamentsEvents } from './adapter';
import { ICountryItem, IEventItem, ISportItem, ITournamentItem, TEventDetailed, TTournamentEvents } from './line';
import { getLineLang } from '../utils/line-lang';
import { DEFAULT_LANG } from '@/app-config';


export const betapi = createApi({
  reducerPath: 'betapi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/line',
  }),
  keepUnusedDataFor: 4,
  endpoints: (build) => ({

    getBetapiSports: build.query<ISportItem[], GetBetapiSportsParams>({
      query: ({
        dataType,
        dataLang = getLineLang(DEFAULT_LANG)
      }) =>
        `/sports/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: (res: GetBetapiSportsResponse, _, { dataType }) =>
        adapteSport(res.body, dataType),
      keepUnusedDataFor: 60,
    }),

    getBetapiCountries: build.query<ICountryItem[], GetBetapiCountriesParams>({
      query: ({
        sportId = 0,
        dataType,
        dataLang = getLineLang(DEFAULT_LANG)
      }) => `/countries/${sportId}/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: (res: GetBetapiCountriesResponse, _, { dataType }) => adapteCountry(res.body, dataType),
      keepUnusedDataFor: 60,
    }),

    getBetapiTournaments: build.query<ITournamentItem[], GetBetapiTournamensParams>({
      query: ({
        sportId = 0,
        countryId = 0,
        dataType,
        dataLang = getLineLang(DEFAULT_LANG)
      }) => `/tournaments/${sportId}/${countryId}/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: (res: GetBetapiTournamentsResponse, _, { dataType }) =>
        adapteTournament(res.body, dataType),
      keepUnusedDataFor: 60,
    }),

    getBetapiEvent: build.query<TEventDetailed, GetBetapiEventParams>({
      query: ({
        gameId,
        listType = 'sub',
        dataType,
        dataLang = getLineLang(DEFAULT_LANG)
      }) => `/event/${gameId}/${listType}/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: (res: GetBetapiEventResponse, _, { dataType }) =>
        adapreDetailedEvent(res.body, dataType),
      keepUnusedDataFor: 4,
    }),

    getBetapiEventsList: build.query<IEventItem[], GetBetapiEventsListParams>({
      query: ({
        sportId = 0,
        tournamentId = 0,
        pageLength = 1000,
        dataType,
        dataLang = getLineLang(DEFAULT_LANG)
      }) => `/events/${sportId}/${tournamentId}/list/${pageLength}/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: (res: GetBetapiEventsListResponse, _, { dataType }) =>
        res.body.map((item) => adapteEventItem(item, dataType)),
      keepUnusedDataFor: 5,
    }),

    getBetapiEventsByTourney: build.query<TTournamentEvents[], GetBetapiEventsListParams>({
      query: ({
        sportId = 0,
        tournamentId = 0,
        pageLength = 1000,
        dataType,
        dataLang = getLineLang(DEFAULT_LANG)
      }) => `/events/${sportId}/${tournamentId}/sub/${pageLength}/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: (res: GetBetapiEventsByTourneyResponse, _, { dataType }) =>
        adapteTournamentsEvents(res.body, dataType),
      keepUnusedDataFor: 2,
    }),
  }),
});


export const {
  useGetBetapiSportsQuery,
  useGetBetapiCountriesQuery,
  useGetBetapiTournamentsQuery,
  useGetBetapiEventQuery,
  useGetBetapiEventsListQuery,
  useGetBetapiEventsByTourneyQuery,
} = betapi;
