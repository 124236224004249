import { useTranslation } from 'react-i18next';
import { PropsWithCN } from '@/shared/types/common';
import { PartnerPromocode } from '../../../model/types';
import PPTable from '../../core/pp-table/pp-table';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import PromocodeStatsBtn from '../promocode-stats-btn/promocode-stats-btn';
import ManagerUserPromocodeStatsBtn from '../manager-user-promocode-stats-btn/manager-user-promocode-stats-btn';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './partner-promocodes-table.scss';


type PartnerPromocodesTableProps = PropsWithCN & {
  promocodes: PartnerPromocode[];
  role?: 'partner' | 'manager';
}

const b = bemCn('partner-promocodes-table');

const PartnerPromocodesTable = ({
  className,
  promocodes,
  role = 'partner',
}: PartnerPromocodesTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  const getStatsBtns = (promocode: PartnerPromocode): React.ReactNode => {
    if (role === 'partner') {
      return (
        <PromocodeStatsBtn
          className={b('controls-btn')}
          icon='pie-chart'
          variant='bordered'
          promocode={promocode}
        />
      );
    } else if (role === 'manager') {
      return (
        <ManagerUserPromocodeStatsBtn
          className={b('controls-btn')}
          icon='pie-chart'
          variant='bordered'
          promocode={promocode}
        />
      );
    }
  };

  return (
    <PPTable<PartnerPromocode>
      dataSource={promocodes}
      className={b(null, className)}
    >
      <PPTable.Column<PartnerPromocode>
        dataIndex=""
        title=""
        render={(_, promocode) => getStatsBtns(promocode)}
      />
      <PPTable.Column<PartnerPromocode>
        dataIndex="id"
        title="ID"
      />
      <PPTable.Column<PartnerPromocode>
        dataIndex="name"
        title={t('title', 'Название')}
      />
      <PPTable.Column<PartnerPromocode>
        dataIndex="scriptDescription"
        title={t('script-description', 'Описание скрипта')}
      />
      <PPTable.Column<PartnerPromocode>
        dataIndex=""
        title={t('created', 'Создан')}
        render={(_, { created }) => dayjsCustom(created).format('DD:MM:YYYY')}
      />
    </PPTable>
  );
};

export default PartnerPromocodesTable;
