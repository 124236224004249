import i18n from '@/shared/i18n/i18n';

export const currenciesTitles: Record<string, {
  symbol: string;
  title: string;
}> = {
  EUR: {
    title: i18n.t('currency-title.EUR', 'Евро (EUR)'),
    symbol: '€',
  },
  USD: {
    title: i18n.t('currency-title.USD', 'Доллар США (USD)'),
    symbol: '$',
  },
  RUB: {
    title: i18n.t('currency-title.RUB', 'Российский рубль (RUB)'),
    symbol: '₽',
  },
  KZT: {
    title: i18n.t('currency-title.KZT', 'Казахстанский тенге (KZT)'),
    symbol: '₸',
  },
  UAH: {
    title: i18n.t('currency-title.UAH', 'Украинская гривна (UAH)'),
    symbol: '₴',
  },
  TJS: {
    title: i18n.t('currency-title.TJS', 'Таджикский сомони (TJS)'),
    symbol: 'с',
  },
  BTC: {
    title: i18n.t('currency-title.BTC', 'Биткойн (BTC)'),
    symbol: '₿',
  },
  UZS: {
    title: i18n.t('currency-title.UZS', 'Узбекский сум (UZS)'),
    symbol: 'UZS',
  },
  KGS: {
    title: i18n.t('currency-title.KGS', 'Киргизский сом (KGS)'),
    symbol: 'с',
  },
  TMT: {
    title: i18n.t('currency-title.TMT', 'Туркменский манат (TMT)'),
    symbol: 'm',
  },
  AZN: {
    title: i18n.t('currency-title.AZN', 'Азербайджанский манат (AZN)'),
    symbol: '₼ ',
  },
  AMD: {
    title: i18n.t('currency-title.AMD', 'Армянский драм (AMD)'),
    symbol: '֏',
  },
  BYN: {
    title: i18n.t('currency-title.BYN', 'Белорусский рубль (BYN)'),
    symbol: 'Br',
  },
  MDL: {
    title: i18n.t('currency-title.MDL', 'Молдавский лей (MDL)'),
    symbol: 'L',
  },
  USDT: {
    title: i18n.t('currency-title.USDT', 'Tether (USDT)'),
    symbol: '₮',
  },
  BUSD: {
    title: i18n.t('currency-title.BUSD', 'Binance USD (BUSD)'),
    symbol: '₿',
  },
  BNB: {
    title: i18n.t('currency-title.BNB', 'Binance Coin (BNB)'),
    symbol: 'BNB',
  },
  ETH: {
    title: i18n.t('currency-title.ETH', 'Ethereum (ETH)'),
    symbol: 'Ξ',
  },
  GEL: {
    title: i18n.t('currency-title.GEL', 'Грузинский лари (GEL)'),
    symbol: '₾',
  }
};

export const currencyFlag: Record<string, string> = {
  RUB: 'russia',
  EUR: 'european-union',
  USD: 'united-states',
  UAH: 'ukraine',
  KZT: 'kazakhstan',
  TJS: 'tajikistan',
  UZS: 'uzbekistan',
  KGS: 'kyrgyzstan',
  TMT: 'turkmenistan',
  AZN: 'azerbaijan',
  AMD: 'armenia',
  BYN: 'belarus',
  MDL: 'moldova',
  GEL: 'georgia',
  USDT: 'tether',
  BTC: 'bitcoin',
  BNB: 'bnb',
  ETH: 'ethereum',
};
