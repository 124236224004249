import { TEventsFilter } from './types';
import { createContext, useContext } from 'react';

interface IFilterContext {
  filter: TEventsFilter;
  onFilteChange: (newFilter: Partial<TEventsFilter>) => void;
}

const FilterContext = createContext<IFilterContext | undefined>(undefined);

const useFilterContext = () => {
  const filterContext = useContext(FilterContext);

  if (!filterContext) {
    throw new Error('FilterContext not defined');
  }

  return filterContext;
};

export { useFilterContext };

export default FilterContext;
