import { useParams } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import { CouponCardDetailed, useGetCouponQuery } from '@/modules/coupons';
import Container from '@shared/components/container/container';
import { useEffect } from 'react';
import { scrollToTop } from '@shared/utils/helpers/common';
import { skipToken } from '@reduxjs/toolkit/query';
import './coupon-details-page.scss';
import { useTranslation } from 'react-i18next';

const b = bemCn('coupon-details-page');

const CouponDetailsPage = () => {
  const { t } = useTranslation();
  const { couponId = '' } = useParams();
  const { data, isFetching } = useGetCouponQuery(couponId || skipToken);

  useEffect(() => {
    scrollToTop(true);
  }, []);

  return (
    <div className={b()}>
      <Container>
        <Breadcrumbs />
        <h1 className="visually-hidden">
          {t('common.сoupon', 'Купон')} № {couponId}
        </h1>
        <CouponCardDetailed coupon={data} loading={isFetching} />
        {!couponId && <p>Coupon not found</p>}
      </Container>
    </div>
  );
};

export { CouponDetailsPage };
