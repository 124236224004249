export type RefCode = string | null | undefined;

export const saveRefCode = (refCode: RefCode) => {
  if (refCode) {
    localStorage.setItem('refCode', refCode);
  }
};
export const getRefCode = (): RefCode =>
  localStorage.getItem('refCode') ?? undefined;

export const dropRefCode = () =>
  localStorage.removeItem('refCode');
