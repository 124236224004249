import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveClickId } from '../utils/clickid';
import { saveWebId } from '../utils/webid';
import { useAppSelector, useDispatchedActions } from '@/shared/hooks';
import { getIsAuth } from '../model/selectors';

export const useClickId = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { changeAuthModal } = useDispatchedActions();
  const isAuth = useAppSelector(getIsAuth);

  // "/click_id=:clickId&web_id=:webId"
  // "/click_id=:clickId"
  const clickIdMatch = location.pathname.match(/click_id=(\w+)(?:&web_id=(\w+))?/);

  const clickId = clickIdMatch ? clickIdMatch[1] : null;
  const webId = clickIdMatch && clickIdMatch[2] ? clickIdMatch[2] : null;

  useEffect(() => {
    if (clickId || webId) {
      saveClickId(clickId);
      saveWebId(webId);
      navigate('/', { replace: true });
      if (!isAuth) {
        changeAuthModal('registration');
      }
    }
  }, [clickId, webId, isAuth, changeAuthModal, navigate]);

  return {
    clickId,
    webId
  };
};
