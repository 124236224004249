
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '../../../../shared/components/icon-svg/icon-svg';
import { TTournamentEvents } from '../../model/line';
import TournamentEventsList from '../tournament-events-list/tournament-events-list';
import { useTournamentEventsToggler } from '../../model/use-tournament-events-toggler';
import { useTranslation } from 'react-i18next';
import './sport-events.scss';

const b = bemCn('sport-events');

type SportEventsProps = {
  className?: string;
  sportName: string;
  eventsByTournament: TTournamentEvents[];
}

const SportEvents = ({
  className,
  eventsByTournament,
  sportName
}: SportEventsProps) => {
  const { t } = useTranslation();
  const itemsIds = eventsByTournament.map((item) => item.id);
  const {
    closedItems,
    isAllClosed,
    handleToggleItem,
    handleToggleAll,
  } = useTournamentEventsToggler(itemsIds);


  return (
    <div className={b(null, className)}>
      <div className={b('head')}>
        <h2 className={b('title')}>{sportName}</h2>
        <button className={b('toggle-button', { active: isAllClosed })} onClick={handleToggleAll}>
          {isAllClosed ? t('event.expand-all', 'Открыть все') : t('event.collapse-all', 'Свернуть все')}
          <IconSvg className={b('toggle-icon')} name="chevron-down" />
        </button>
      </div>
      <TournamentEventsList className={b('list')}
        events={eventsByTournament}
        closedItems={closedItems}
        onToggle={handleToggleItem}
      />
    </div>
  );
};

export default SportEvents;
