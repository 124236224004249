import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NameSpace } from '@/shared/utils/constants/common';
import userApi from './user-api';
import { UserState, UserModal } from './types';

const initialState: UserState = {
  activeModal: null,
};

const userSlice = createSlice({
  name: NameSpace.User,
  initialState,
  reducers: {
    changeUserModal: (state, action: PayloadAction<UserModal>) => {
      state.activeModal = action.payload;
    },
    closeUserModal: (state) => {
      state.activeModal = null;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        userApi.endpoints.initWithdrawal.matchFulfilled,
        (state) => {
          state.activeModal = 'withdrawal-success';
        })
      .addMatcher(
        userApi.endpoints.initPayment.matchFulfilled,
        (state, action) => {
          if (!action.payload) {
            return;
          }
          const paymentUrl = action.payload;
          state.activeModal = null;
          if (paymentUrl) {
            window.open(paymentUrl, '_self');
          }
        });
  },
});

export default userSlice;
