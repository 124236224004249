import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { PartnerLink } from '@/modules/partner-program/model/types';
import './partner-links-list.scss';
import PartnerLinkCard from '../partner-link-card/partner-link-card';

const b = bemCn('partner-links-list');

type PartnerLinksListProps = {
  links: PartnerLink[];
  role?: 'partner' | 'manager';
}

const PartnerLinksList = ({ links, role = 'partner' }: PartnerLinksListProps) => (
  <div className={b()}>
    {links.map((link) => (
      <PartnerLinkCard key={link.refCode} link={link} role={role} />
    ))}
  </div>
);

export default PartnerLinksList;
