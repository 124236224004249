import ResultModal from '@/shared/components/result-modal/result-modal';
import { useAppSelector } from '@/shared/hooks';
import { getBasketPlaceError } from '../../models/selectors';
import { useTranslation } from 'react-i18next';


type BetErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const BetErrorModal = ({ isOpen, onClose }: BetErrorModalProps) => {
  const { t } = useTranslation();
  const errorMessage = useAppSelector(getBasketPlaceError);

  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      status='error'
      title={errorMessage ?? t('result-modal.basket.error.title', 'Ошибка постановки ставки')}
    />
  );
};

export default BetErrorModal;
