import i18n from '@/shared/i18n/i18n';

export const phoneRegExp = /^\+?\d{9,15}$/;

export const credentialsInputTitle: Record<string, string> = {
  'card': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_byn': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_azn': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'uzcard': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_uzs': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_kzt': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_uah': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_uah_1000': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'cardmir': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'mir': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'mobile': i18n.t('withdrawals.modal.phone-number', 'Номер телефона'),
  'qiwi': i18n.t('withdrawals.modal.wallet-number', 'Номер кошелька'),
  'sbp': i18n.t('withdrawals.modal.phone-number', 'Номер телефона'),
};

export const getCredentialsInputTitle = (method: string) =>
  credentialsInputTitle[method.toLocaleLowerCase()]
  ?? i18n.t('withdrawals.modal.account-number', 'Номер счета');
