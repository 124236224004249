import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useGetManagerLinkQuery } from '../../api/managerApi';
import LoaderCenter from '@/shared/components/loader-center/loader-center';

import './pp-manager-link.scss';

const b = bemCn('pp-manager-link');

type Props = {
  className?: string;
  title?: string;
};

const PPManagerLink = (props: Props) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const {
    className,
    title = t('your-ref-link', 'Ваша реферальная ссылка'),
  } = props;

  const { data: managerLink, isLoading } = useGetManagerLinkQuery();

  return (
    <div className={b(null, className)}>
      <p className={b('title')}>{title}</p>
      {isLoading && <LoaderCenter />}
      <p className={b('link')}>{managerLink}</p>
    </div>
  );
};

export { PPManagerLink };
