import { useTranslation } from 'react-i18next';
import { PartnerProject } from '@/modules/partner-program/model/types';
import PPTable from '../../core/pp-table/pp-table';
import ProjectStatsBtn from '../project-stats-btn/project-stats-btn';
import ProjectCreateLinkBtn from '../project-create-link-btn/project-create-link-btn';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './partner-projects-table.scss';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';

const b = bemCn('partner-projects-table');

type ParnterProjectsTableProps = {
  projects: PartnerProject[];
}

const ParnterProjectsTable = ({
  projects,
}: ParnterProjectsTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  return (
    <PPTable<PartnerProject>
      className={b()}
      dataSource={projects}
    >
      <PPTable.Column<PartnerProject>
        dataIndex=""
        title=""
        render={(_, project) => (
          <PPTable.Actions>
            <ProjectStatsBtn
              className={b('controls-btn')}
              project={project}
            />
            <ProjectCreateLinkBtn
              className={b('controls-btn')}
              project={project}
            />
          </PPTable.Actions>
        )}
      />
      <PPTable.Column<PartnerProject>
        dataIndex="id"
        title="ID"
      />
      <PPTable.Column<PartnerProject>
        dataIndex="name"
        title={t('title', 'Название')}
      />
      <PPTable.Column<PartnerProject>
        dataIndex="created"
        title={t('created', 'Создан')}
        render={(_, { created }) => dayjsCustom(created).format('DD:MM:YYYY')}
      />
    </PPTable>
  );
};

export default ParnterProjectsTable;
