import { bemCn } from '@shared/utils/helpers/bem-cn';
import { getAmountString, roundNumber } from '@shared/utils/helpers/common';
import IconStatus from '../../icon-status/icon-status';
import GradientIcon from '@shared/components/gradient-icon/gradient-icon';
import { Link } from 'react-router-dom';
import { Coupon } from '../../../model/types';

import { couponIconColorByTheme, couponTypeLabel } from '../../../helpers/const';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '@/modules/theme/model/theme-context';
import { useAppSelector } from '@/shared/hooks';
import { getCouponLogotype } from '@/shared/store/app/selectors';
import './coupon-card-mobile.scss';

type CouponCardMobileProps = {
  className?: string;
  coupon: Coupon;
}

const b = bemCn('coupon-card-mobile');

const CouponCardMobile = ({ className, coupon }: CouponCardMobileProps) => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const logotype = useAppSelector(getCouponLogotype);
  const {
    id,
    created,
    rate,
    betAmount,
    winAmount,
    currency,
    bets,
    status,
    isLive,
    type,
  } = coupon;

  const iconColor = couponIconColorByTheme[theme] ?? couponIconColorByTheme.default;

  return (
    <Link to={`${id}`} className={b(null, className)}>
      <div className={b('info')}>
        <div className={b('labels')}>
          {/* {label && <p className={b('label')}>{label}</p>} */}
          <img className={b('label', { logo: true })} src={logotype} />
          <p className={b('label', { live: true, hide: !isLive })}>Live</p>
        </div>
        <div className={b('info-top')}>
          <div>
            <p className={b('type')}>{couponTypeLabel[type]}</p>
            <p className={b('number')}>№: {id}</p>
          </div>
          <p className={b('date')}>
            {dayjsCustom(created).format('DD.MM.YYYY / HH:mm')}
          </p>
          <p className={b('rate')}>{roundNumber(rate)}</p>
          <IconStatus className={b('status')} status={status} />
        </div>
        <p className={b('amount')}>
          <span className={b('amount-label')}>
            {t('bet-history.bet', 'Ставка')}:
          </span>
          <span className={b('diver')} />
          <span className={b('amount-value')}>
            {getAmountString(betAmount, currency)}
          </span>
        </p>
        <p className={b('amount', { status })}>
          <span className={b('amount-label')}>
            {status === 'refund' && t('bet-history.refund', 'Возврат')}
            {status === 'placed' && t('bet-history.possible-prize', 'Возможный выигрыш')}
            {status !== 'placed' && status !== 'refund' && t('bet-history.win', 'Выигрыш')}
          </span>
          <span className={b('diver')} />
          <span className={b('amount-value')}>
            {status === 'placed' && getAmountString(betAmount * rate, currency)}
            {status !== 'placed' && getAmountString(winAmount ?? 0, currency)}
          </span>
        </p>
      </div>
      <div className={b('list')}>
        {bets.map((bet) => (
          <div className={b('item')} key={bet.id}>
            <div className={b('item-meta')}>
              <p className={b('item-tournament')}>
                {bet.sportName} / {bet.tournamentName}
              </p>
              <p className={b('item-date')}>
                {dayjsCustom(bet.gameDate).format('DD.MM.YYYY / HH:mm')}
              </p>
            </div>
            <div className={b('item-wrapper')}>
              <GradientIcon className={b('item-icon')}
                color={iconColor}
                iconName={bet.sportIcon}
              />
              <p className={b('item-teams')}>
                <span className={b('item-team')}>{bet.team1}</span>
                <span className={b('item-team')}>{bet.team2}</span>
              </p>
              <p className={b('rate', b('item-rate'))}>
                {roundNumber(bet.rate)}
              </p>
              <IconStatus className={b('item-status')} status={bet.status} />
            </div>
          </div>
        ))}
      </div>
    </Link>
  );
};

export default CouponCardMobile;
