import { useTranslation } from 'react-i18next';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useCreatePartnerProjectMutation } from '../../../api/partnerApi';
import AppModal from '@/shared/components/app-modal/app-modal';
import Form from '@/shared/components/form/form';
import Button from '@/shared/components/button/button';
import * as yup from 'yup';
import './create-partner-project-modal.scss';
import { handleCreatePartnerProjectError } from '@/modules/partner-program/lib/error-handler';
import { useEffect, useState } from 'react';
import ResultModal from '@/shared/components/result-modal/result-modal';

const b = bemCn('create-partner-project-modal');

type FormData = {
  name: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object({
  name: yup.string().required('Введите название'),
});

type CreatePartnerProjectModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const CreatePartnerProjectModal = ({
  isOpen,
  onClose,
}: CreatePartnerProjectModalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [successModal, setSuccessModa] = useState(false);
  const [createProject, {
    error,
    isSuccess
  }] = useCreatePartnerProjectMutation();

  const handledError = handleCreatePartnerProjectError(error);

  const handleFormSubmit = (formData: FormData) => {
    createProject(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      setSuccessModa(true);
    }
  }, [isSuccess]);

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      className={b()}
      title={t('create-project', 'Создать прект')}
    >
      <Form<FormData>
        className={b('form')}
        onSubmit={handleFormSubmit}
        form={{ schema, error: handledError }}
      >
        <Form.TextField
          className={b('field')}
          name='name'
          label={t('title', 'Название')}
          placeholder={t('enter-project-title', 'Введите название проекта')}
        />
        <Button type='submit'>
          {t('add-project', 'Добавить проект')}
        </Button>
      </Form>
      <ResultModal
        isOpen={successModal}
        onClose={() => setSuccessModa(false)}
        title={t('project-create-success', 'Проект успешно создан')}
        status='success'
      />
    </AppModal>
  );
};

export default CreatePartnerProjectModal;
