import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Container from '@shared/components/container/container';
import { useScrollToTop } from '../../shared/hooks/use-scrolltotop';
import { type DataType, EventCardBigContainer } from '@/modules/line';
import './event-screen-mobile.scss';

type EventScreenProps = {
  dataType: DataType;
}

const b = bemCn('event-screen-mobile');

const EventScreenMobile = ({ dataType }: EventScreenProps) => {
  useScrollToTop();
  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs />
        <EventCardBigContainer dataType={dataType} />
      </Container>
    </div>
  );
};

export default EventScreenMobile;
