import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';


const PartnerHomePage = () => {
  const b = bemCn('partner-home-page');
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  return (
    <div className={b()}>
      <h1>{t('partner', 'Партнёр')}: {t('main-page', 'Главная страница')}</h1>
    </div>
  );
};

export { PartnerHomePage };
