import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg, { AvailableIcon } from '../icon-svg/icon-svg';
import { useTranslation } from 'react-i18next';
import i18n from '@/shared/i18n/i18n';
import './button-download-app.scss';

const PlatformName = {
  ios: i18n.t('downloadapp.For iOS', 'Для iOS'),
  android: i18n.t('downloadapp.For Android', 'Для Android')
};

const PlatformIcon: Record<string, AvailableIcon> = {
  android: 'android',
  ios: 'apple'
};


type ButtonDownloadAppProps = {
  className?: string;
  onClick?: () => void;
  link?: string;
  type: 'ios' | 'android';
}

const b = bemCn('button-download-app');

const ButtonDownloadApp = (props: ButtonDownloadAppProps) => {
  const { className, onClick, link, type } = props;
  const { t } = useTranslation();

  return (
    <a href={link} className={b(null, className)} onClick={onClick} target="_blank" rel="noreferrer">
      <IconSvg className={b('icon', { type })} name={PlatformIcon[type]} />
      <p className={b('text')}>
        <span className={b('lable')}>{t('downloadapp.Application', 'Приложение')}</span>
        <span className={b('name')}>{PlatformName[type]}</span>
      </p>
    </a>
  );
};

export default ButtonDownloadApp;
