import { bemCn } from '@shared/utils/helpers/bem-cn';
import './text-content.scss';

type TextContentProps = {
  className?: string;
  children: React.ReactNode;
}

const TextContent = ({ children, className }: TextContentProps) => {
  const b = bemCn('text-content');

  return (
    <div className={b(null, className)}>
      {children}
    </div>
  );
};

export default TextContent;
