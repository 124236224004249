import { bemCn } from '@shared/utils/helpers/bem-cn';
import { IPromocode } from '@/modules/user/store/types';
import { FC } from 'react';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './promocode-card.scss';

const b = bemCn('promocode-card');

type PromocodeCardProps = {
  className?: string;
  promocode: IPromocode;
  inverted?: boolean;
}

const PromocodeCard: FC<PromocodeCardProps> = ({
  className,
  promocode,
  inverted,
}) => {
  const { name, description, usedDate, isActivated } = promocode;
  return (
    <div className={b({ active: isActivated, inverted }, className)}>
      <div className={b('wrapper')}>
        <div className={b('main-info')}>
          <p className={b('name')}>{name}</p>
          <p className={b('desc')}>{description}</p>
        </div>
        <div className={b('meta-info')}>
          <p className={b('date')}>
            {dayjsCustom(usedDate).format('DD.MM.YYYY / HH:mm')}
          </p>
          <p className={b('status')}>
            {isActivated ? 'Активирован' : 'Не активирован'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PromocodeCard;
