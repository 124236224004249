import { useTranslation } from 'react-i18next';
import {
  PartnerPromocodesList,
  PartnerPromocodesTable,
} from '@/modules/partner-program';
import { useParams } from 'react-router-dom';
import Desktop from '@/shared/components/responsive/desktop';
import Mobile from '@/shared/components/responsive/mobile';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useState } from 'react';
import { useGetSubPartnerPromocodesQuery } from '@/modules/partner-program/api/managerApi';
import LoaderCenter from '@/shared/components/loader-center/loader-center';
import Breadcrumbs from '@/shared/components/breadcrumbs/breadcrumbs';
import Pagination from '@/shared/components/pagination/pagination';

import type { GetSubPartnerPromocodes } from '@/modules/partner-program/model/types';


const b = bemCn('partner-promocodes-page');

const initParams: Partial<GetSubPartnerPromocodes> = {
  page: 1,
  pageSize: 20,
};

const ManagerUserPromocodesPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const { partnerId } = useParams();
  const [params, setParams] = useState<GetSubPartnerPromocodes>({ ...initParams, partnerId: Number(partnerId) });

  const {
    data: dataPromocodes,
    isLoading: isPromocodesLoading
  } = useGetSubPartnerPromocodesQuery(params);

  const handleChangeParams = (newParams: Partial<GetSubPartnerPromocodes>) => {
    setParams({ ...params, ...newParams });
  };

  const promocodes = dataPromocodes?.items ?? [];
  const itemsCount = dataPromocodes?.count ?? 0;
  const isEmpty = itemsCount === 0;

  return (
    <div className={b()}>
      <Breadcrumbs isGoBack />

      <div className={b('head')}>
        <h1 className={b('title')}>{`${t('user-promocodes-page', 'Страница промокодов пользователя')} ID ${partnerId}`}</h1>
      </div>

      <Mobile>
        <PartnerPromocodesList
          className={b('list')}
          promocodes={promocodes}
          role="manager"
        />
      </Mobile>
      <Desktop>
        <PartnerPromocodesTable
          className={b('list')}
          promocodes={promocodes}
          role="manager"
        />
      </Desktop>

      {isEmpty && <p>{t('empty', 'Список пуст')}</p>}
      {isPromocodesLoading && <LoaderCenter />}
      <Pagination
        className={b('pagination')}
        page={params.page}
        pageSize={params.pageSize}
        totalItems={itemsCount}
        onChange={(page) => handleChangeParams({ page })}
      />
    </div>
  );
};

export { ManagerUserPromocodesPage };
