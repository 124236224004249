import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '../../../../shared/components/icon-svg/icon-svg';
import { TTournamentEvents } from '../../model/line';
import EventsList from '../events-list/events-list';
import './tournament-events.scss';

const b = bemCn('tournament-events');

type TournamentEventsProps = {
  className?: string;
  closed?: boolean;
  tournament: TTournamentEvents;
  onCloseToggle?: () => void;
}

const TournamentEvents = (props: TournamentEventsProps) => {
  const { className, closed = false, tournament, onCloseToggle } = props;
  const { name, sportIcon, events } = tournament;

  return (
    <div className={b({ closed }, className)}>
      <button className={b('head')}
        type='button'
        onClick={onCloseToggle}
      >
        <IconSvg className={b('icon')} name={sportIcon} />
        <span className={b('name')}>{name}</span>
        <IconSvg className={b('toggle-icon')} name="chevron-down" />
      </button>
      {!closed && (
        <EventsList className={b('list')} events={events} />
      )}
    </div>
  );
};

export default TournamentEvents;
