import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './pp-card.scss';

const b = bemCn('pp-card');

type Item = {
  label: string;
  value: string | number | null;
}

type PPCardProps = {
  className?: string;
  items: Item[];
  contorls?: React.ReactNode;
}

const PPCard = ({ items, contorls, className }: PPCardProps) => (
  <div className={b(null, className)}>
    <div className={b('items')}>
      {items.map(({ label, value }) => (
        <div className={b('item')} key={label + value}>
          <div className={b('item-label')}>{label}</div>
          <div className={b('item-value')}>{value}</div>
        </div>
      ))}
    </div>
    {contorls && (
      <div className={b('controls')}>
        {contorls}
      </div>
    )}
  </div>
);

export default PPCard;
