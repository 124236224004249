import ButtonIcon from '@/shared/components/button-icon/button-icon';

import type { Subpartner } from '@/modules/partner-program/model/types';

type Props = {
  className?: string;
  subpartner: Subpartner;
};

const SubpartnerPromocodesBtn = ({ className, subpartner }: Props) => {
  const link = `subpartner-promocodes/${subpartner.id}`;

  return (
    <ButtonIcon
      className={className}
      icon='coupon'
      variant='bordered'
      to={link}
    />
  );
};

export default SubpartnerPromocodesBtn;
