import { useEffect } from 'react';
import { useDebounceValue } from 'usehooks-ts';

export const useDebounce = <T>(initValue: T, timout: number) => {
  const [debouncedInputValue, setDeboucetValue] = useDebounceValue(initValue, timout);
  useEffect(() => {
    if (initValue !== debouncedInputValue) {
      setDeboucetValue(initValue);
    }
  }, [initValue]);

  return debouncedInputValue;
};
