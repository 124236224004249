import { Navigate, useParams } from 'react-router-dom';
import { useVerifyEmailQuery } from '@/modules/auth';
import { skipToken } from '@reduxjs/toolkit/query';

const VerifyEmailPage = () => {
  const { id } = useParams();
  useVerifyEmailQuery(id ?? skipToken);

  return <Navigate to="/" />;
};

export default VerifyEmailPage;
