import { bemCn } from '@shared/utils/helpers/bem-cn';
import CasinoFilterSortMobileDropdown from '../casino-filter-sort-mobile-dropdown/casino-filter-sort-mobile-dropdown';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { casinoFilterSortTitles, casinoSortList } from '@/modules/casino/model/const';

import './casino-filter-sort-mobile.scss';

import type { CasinoSortType } from '@/modules/casino/model/types';

const b = bemCn('casino-filter-sort-mobile');

type Props = {
  className?: string;
  onSort?: (sort: CasinoSortType) => void;
  sort?: CasinoSortType;
};

const CasinoFilterSortMobile = ({ className, onSort, sort }: Props) => (
  <CasinoFilterSortMobileDropdown
    className={b(null)}
    classNameContent={b('content')}
    renderTriger={({ toggel }) => (
      <ButtonIcon className={b('btn', className)}
        variant="secondary"
        onClick={toggel}
      />
    )}
    renderContent={({ close }) => (
      <ul className={b('list')}>
        <div className={b('list-header')}>
          <span onClick={() => close()} className={b('list-header-btn')}></span>
        </div>

        {casinoSortList.map((item) => (
          <li className={b('item')} key={item}>
            <button type='button' className={b('link', { active: sort === item })}
              onClick={() => {
                onSort?.(item);
                close();
              }}
            >
              <span>{casinoFilterSortTitles[item]}</span>
              <span className={b('icon-wrapper', { active: sort === item })}>
                <IconSvg
                  className={b('icon')}
                  name="check"
                  fill="#191246"
                />
              </span>
            </button>
          </li>
        ))}

      </ul>
    )}
  />
);

export default CasinoFilterSortMobile;
