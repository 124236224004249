import { bemCn } from '@/shared/utils/helpers/bem-cn';
import AppModal from '@/shared/components/app-modal/app-modal';
import UserSettings from '../user-settings/user-settings';
import { useTranslation } from 'react-i18next';
import './user-settings-modal.scss';

type UserSettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const b = bemCn('user-settings-modal');

const UserSettingsModal = ({ isOpen, onClose }: UserSettingsModalProps) => {
  const { t } = useTranslation();

  return (
    <AppModal className={b()}
      isOpen={isOpen}
      onClose={onClose}
      title={t('settings.title', 'Настройки')}
    >
      <UserSettings />
    </AppModal>
  );
};

export default UserSettingsModal;
