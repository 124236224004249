import { bemCn } from '@shared/utils/helpers/bem-cn';
import Container from '@shared/components/container/container';
import { Link } from 'react-router-dom';
import { PromocodeActivateFormConnected } from '@/modules/promocodes';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import './promocode-activate-screen.scss';
import { useThemeContext } from '@/modules/theme/model/theme-context';

const b = bemCn('promocode-activate-screen');

const PromocodeActivateScreen = () => {
  useScrollToTop();
  const { t } = useTranslation();
  const { isDark } = useThemeContext();
  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        <div className={b('header')}>
          <h1 className={b('title')}>
            {t('promocodes.activate.title', 'Активация промокода')}
          </h1>
          <Link className={b('link')} to="/profile/promocodes">
            {t('profile.nav.promo-codes', 'Промокоды')}
          </Link>
        </div>
        <PromocodeActivateFormConnected
          className={b('form')}
          inverted={isDark}
        />
      </Container>
    </div>
  );
};

export default PromocodeActivateScreen;
