import { bemCn } from '@/shared/utils/helpers/bem-cn';
import React, { PropsWithChildren, ReactNode } from 'react';
import { getKey } from '@/shared/utils/helpers/common';
import { PropsWithCN } from '@/shared/types/common';
import './pp-table.scss';

const b = bemCn('pp-table');

export interface IColumnType<T extends object> {
  dataIndex: keyof T | string;
  title: string;
  width?: number;
  render?: (column: IColumnType<T>, item: T) => ReactNode;
}

// interface TableHeaderProps<T> {
//   columns: IColumnType<T>[];
// }

// const TableHeader = <T,>({ columns }: TableHeaderProps<T>) => (
//   <tr className={b('head-row')}>
//     {columns.map((column) => (
//       <th className={b('head-cell')} key={`table-head-cell-${column.dataIndex}`}>
//         {column.title}
//       </th>
//     ))}
//   </tr>
// );

// interface TableRowProps<T> {
//   data: T[];
//   columns: IColumnType<T>[];
// }

// const TableRow = <T,>({ data, columns }: TableRowProps<T>) => (
//   <>
//     {data.map((item, i) => {
//       const rowKey = `table-row-${i}`;

//       return (
//         <tr className={b('row')} key={rowKey}>
//           {columns.map((column, iC) => {
//             const colKey = `table-cell-${rowKey}-${iC}`;
//             const value = (item as Record<string, unknown>)[column.dataIndex] as ReactNode;
//             return (
//               <td className={b('cell')} key={colKey}>
//                 {column.render ? column.render(column, item) : value}
//               </td>
//             );
//           })}
//         </tr>
//       );
//     })}
//   </>
// );

const reduceColumsFromChildrens = <T extends object>(childrens: TableElement<T>[]) => {
  const cols = childrens.reduce((acc, child) => {
    const { props } = child;
    return [...acc, props];
  }, [] as IColumnType<T>[]);

  return cols;
};

type TableElement<T extends object> = React.ReactElement<IColumnType<T>>

type PPTableProps<T extends object> = PropsWithCN & {
  children?: TableElement<T> | TableElement<T>[];
  dataSource?: T[];
  columns?: IColumnType<T>[];
}

const PPTable = <T extends object>({
  dataSource = [],
  columns,
  children,
  className,
}: PPTableProps<T>) => {

  if (!columns) {
    const childrens = (React.Children.toArray(children) as TableElement<T>[])
      .filter((item) => item.props.dataIndex !== undefined);
    columns = reduceColumsFromChildrens(childrens);
  }

  return (
    <div className={b(null, className)}>
      <table className={b('table')}>
        <thead className={b('head')}>
          <tr className={b('head-row')}>
            {columns?.map((column, i) => (
              <th className={b('head-cell')}
                key={getKey('table-head-cell', column.dataIndex as string, i)}
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={b('body')}>
          {dataSource.map((item, i) => (
            <tr className={b('row')} key={getKey('table-row', i)}>
              {columns?.map((column, j) => (
                <td className={b('cell')} key={getKey('table-cell', i, j)}>
                  {column.render
                    ? column.render(column, item)
                    : item[column.dataIndex as keyof T] as ReactNode}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

type JustFN = <T extends object>(props: IColumnType<T>) => ReactNode;

const PPTableColumn: JustFN = () => null;

const PPTableActions = ({ children }: PropsWithChildren) => (
  <div className={b('actions-list')}>
    {children}
  </div>
);

PPTable.Column = PPTableColumn;
PPTable.Actions = PPTableActions;

export default PPTable;
