import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './descriptions.scss';
import { PropsWithCN } from '@/shared/types/common';

const b = bemCn('descriptions');

type DescriptionsItemProps = PropsWithCN & {
  label: string;
  children: React.ReactNode;
}

const DescriptionsItem = ({ className, label, children }: DescriptionsItemProps) => (
  <div className={b('item', className)}>
    <div className={b('item-label')}>{label}</div>
    <div className={b('item-value')}>{children}</div>
  </div>
);

type DescriptionsProps = PropsWithCN & {
  title?: string;
  children?: React.ReactNode;
}

const Descriptions = ({ className, title, children }: DescriptionsProps) => (
  <section className={b(null, className)}>
    {title && (<h2 className={b('title')}>{title}</h2>)}
    <div className={b('list')}>{children}</div>
  </section>
);

Descriptions.Item = DescriptionsItem;

export default Descriptions;
