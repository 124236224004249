import { TBanner } from './types';


export const saveBanners = (banners: TBanner[]) => {
  localStorage.setItem('banners', JSON.stringify(banners));
};

export const getStoredBanners = () => {
  const banners = localStorage.getItem('banners');
  return banners ? JSON.parse(banners) as TBanner[] : [];
};

export const dropStoredBanners = () => {
  localStorage.removeItem('banners');
};
