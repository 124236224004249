import { baseApi } from '../../../shared/store/api';
import { getClickId } from '../utils/clickid';
import { getRefCode } from '../utils/ref-code';
import { getWebId } from '../utils/webid';
import { dropToken, saveToken } from '../utils/token';
import type {
  ChangePasswordParams,
  LoginParams,
  LoginResponse,
  RegisterByEmailParams,
  RegisterByPhoneParams,
  RegisterByTelegramParams,
  RegisterByTelegramRes,
  ResetPasswordParams,
} from './types';

const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<unknown, LoginParams>({
      query: (body) => ({
        url: 'v1/auth/login',
        method: 'POST',
        body: {
          ...body,
          login: body.login.trim().replace(/^\+/, ''),
          password: body.password,
        },
      }),
      transformResponse: (res: LoginResponse) => {
        const token = res.access_token ?? '';
        saveToken(token);
        return res;
      },
      transformErrorResponse: (res: unknown) => {
        dropToken();
        return res;
      },
      invalidatesTags: ['Auth'],
    }),

    logout: build.mutation<unknown, void>({
      query: () => ({
        url: 'v1/auth/logout',
        method: 'POST'
      }),
    }),

    registerByEmail: build.mutation<unknown, RegisterByEmailParams>({
      query: (body) => ({
        url: 'v1/auth/register/email',
        method: 'POST',
        body: {
          'password': body.password,
          'currency': body.currency,
          'name': body.name,
          'promocode': body.promocode,
          'ref_code': getRefCode(),
          'click_id': getClickId(),
          'web_id': getWebId(),
          'email': body.email,
        }
      })
    }),

    registerByPhone: build.mutation<unknown, RegisterByPhoneParams>({
      query: (body) => ({
        url: 'v1/auth/register/phone',
        method: 'POST',
        body: {
          'password': body.password,
          'currency': body.currency,
          'name': body.name,
          'promocode': body.promocode,
          'ref_code': getRefCode(),
          'click_id': getClickId(),
          'web_id': getWebId(),
          'phone': body.phone,
          'phone_country': body.phoneCountry,
        }
      })
    }),

    registerByTelegram: build.mutation<string, RegisterByTelegramParams>({
      query: (body) => ({
        url: 'v1/auth/register/telegram',
        method: 'POST',
        body: {
          'password': body.password,
          'currency': body.currency,
          'name': body.name,
          'promocode': body.promocode,
          'ref_code': body.refCode,
          'click_id': body.clickId,
          'web_id': body.webId,
          'telegram_nickname': body.telegram,
        }
      }),
      transformResponse: (res: RegisterByTelegramRes) =>
        res.telegram_bot_url ?? ''
    }),

    verifyEmail: build.query({
      query: (verificationId: string) => ({
        url: `v1/auth/login/email/verify/${verificationId}`,
        method: 'POST'
      })
    }),

    verifyPhone: build.mutation({
      query: (smsCode: string | number) => ({
        url: `v1/auth/login/phone/verify/${smsCode}`,
        method: 'POST',
      })
    }),

    repeatSms: build.mutation({
      query: (phone: string | number) => ({
        url: 'v1/auth/login/phone/verify/repeat_sms',
        method: 'POST',
        params: {
          phone: phone
        }
      })
    }),

    changePassword: build.mutation<unknown, ChangePasswordParams>({
      query: ({ oldPassword, newPassword }) => ({
        url: 'v1/auth/login/password/change',
        method: 'POST',
        body: {
          'old_password': oldPassword,
          'new_password': newPassword
        }
      })
    }),

    initPasswordReset: build.mutation({
      query: (email: string) => ({
        url: 'v1/auth/login/password/reset',
        params: {
          email: email,
        }
      })
    }),

    initPasswordResetTelegram: build.mutation({
      query: (telegram: string) => ({
        url: 'v1/auth/login/password/reset_telegram',
        params: {
          'telegram_nickname': telegram,
        }
      })
    }),

    initPasswordResetPhone: build.mutation({
      query: (phone: string | number) => ({
        url: 'v1/auth/login/password/reset_phone',
        params: {
          phone,
        }
      })
    }),

    resetPassword: build.mutation<unknown, ResetPasswordParams>({
      query: ({ passwordResetId, newPassword }) => ({
        url: `v1/auth/login/password/reset/${passwordResetId}`,
        method: 'POST',
        params: {
          'new_password': newPassword
        }
      })
    }),

    clickRefCode: build.query<unknown, string>({
      query: (refCode) => ({
        url: `v1/partner/ref_code/${refCode}`,
        method: 'POST',
      })
    })

  }),
  overrideExisting: true,
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterByEmailMutation,
  useRegisterByTelegramMutation,
  useVerifyEmailQuery,
  useChangePasswordMutation,
  useClickRefCodeQuery,
  useInitPasswordResetMutation,
  useResetPasswordMutation,
} = authApi;

export default authApi;
